import { isDevMode } from '@angular/core';
import { ErrorService } from 'src/app/services/error.service';
import { AuthErrorCode, NotificationErrorCode, FirestoreErrorCode, MessageErrorCode, ApplicationErrorCode, CurrentUserErrorCode, StorageErrorCode } from '../error-handler/global-error-codes';
import { GlobalConstants } from '../Global';

export {AuthError, ApplicationError, CurrentUserError, NotificationError, StubError, FirestoreError, MessageError, StorageError}; 

/**
 * Base class for errors: All custom errors must be extended off this class!
 */
class ApplicationError extends Error { 
  //All custom properties for a DailyBids Error. 
  // handled?:boolean = false; 
  // date?:Date; 
  constructor(message:string, code:ApplicationErrorCode, name?:string) {
    super(message);
    this.name = (name ? name : ApplicationError.name) + ` ${code}`; 
    Object.setPrototypeOf(this, ApplicationError.prototype); 
  }

  public handle(_error:ErrorService){
    let message = this.message;
    let split = "Messaging: ";
    message = (this.message.includes(split)) ? this.message.split(split)[1] : this.message;
    console.warn(`${this.name}:\n${message}`)
  }

}

class AuthError extends ApplicationError { 
  public alert:boolean;
  public code:string;
  //Auth Error Reference: https://firebase.google.com/docs/auth/admin/errors

  constructor(error:any, code:AuthErrorCode, alert=true) {
    super(error.message, code, AuthError.name);
    this.code = error.code;
    this.alert = alert; 
    Object.setPrototypeOf(this, AuthError.prototype); 
  }


  public handle(_error:ErrorService){
    // Make a list of the autherror codes to check to handle them.
    let AUTH_ERRORS_TO_HANDLE = Object.keys(GlobalConstants.AUTH_ERRORS).map(k => GlobalConstants.AUTH_ERRORS[k]);
    _error.clearAuthMessage();
    isDevMode() && console.error(this.code)
    if(this.code == GlobalConstants.AUTH_ERRORS.RECENT_LOGIN){
      _error.alertError("Oops something went wrong! Please logout and log back in fix it!");
    }else if(this.code == GlobalConstants.AUTH_ERRORS.TOO_MANY_REQUESTS){
      _error.alertError('Seems like the email was sent very recently, try again in a few minutes.');
    }else if(AUTH_ERRORS_TO_HANDLE.includes(this.code)){
      _error.setAuthMessage(this.message);
      if(this.alert) _error.alertError(this.message); 
    }else{
      //Lesser known error that we don't want to show the error. 
      super.handle(_error); 
    }
  }
}

//Error to throw to test reauth code
class StubError extends ApplicationError {
  constructor() {
    super("Test", null, StubError.name);
    this.name = GlobalConstants.AUTH_ERRORS.RECENT_LOGIN; 
  }
}

class NotificationError extends ApplicationError {
  constructor(error:Error|string, code:NotificationErrorCode) {
    error = (error instanceof Error) ? error.message : error;
    super(error, code, NotificationError.name);
    // Object.setPrototypeOf(this, NotificationError.prototype);  //So the error-handler.ts file knows this is a NotificationError and to use this handler. 
  }
}

class CurrentUserError extends ApplicationError {
  constructor(message:string, code:CurrentUserErrorCode) {
    super(message, code, CurrentUserError.name);
    // Object.setPrototypeOf(this, NotificationError.prototype);  //So the error-handler.ts file knows this is a NotificationError and to use this handler. 
  }
}

class MessageError extends ApplicationError {
  code:number;
  conversationId:string;
  constructor(conversationId:string, code:MessageErrorCode) {
    super(`Error processing with conversation=${conversationId}`, code, MessageError.name);
    this.code = code
    this.conversationId = conversationId;
  }
}

class FirestoreError extends ApplicationError {
  constructor(error:string|Error, code:FirestoreErrorCode) {
    let message = (error instanceof Error) ? error.message : error;
    super(message, code, FirestoreError.name)
  }
}

class StorageError extends ApplicationError {
  constructor(error:string|Error, code:StorageErrorCode) {
    let message = (error instanceof Error) ? error.message : error;
    super(message, code, StorageError.name)
  }
}
