<h1 mat-dialog-title>Quick Guidelines</h1>
<div mat-dialog-content>
<p>The highest bidder wins after 24 hours!</p>
<p><strong>How to sell:</strong></p>
<ul>
  <li>Click Sell in the top-right menu</li>
  <li>Fill out the form and post your item</li>
  <li>Other people can now bid on your item for 24 hours</li>
  <li>After 24 hours, you should contact the winner to set up the exchange</li>
</ul>

<p><strong>How to bid:</strong></p>
<ul>
  <li>Click Bid in the top-right menu to view all items for sale in your city</li>
  <li>Click on an item to view it and place bids</li>
  <li>After bidding has finished, if your bid is the winning bid, contact the seller to set up the exchange</li>
</ul>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()">Close</button>
  <a mat-raised-button color="primary" [routerLink]="" (click)="goToGuidelines()">Learn More</a>
</mat-dialog-actions>