<h1 mat-dialog-title>Update Profile Picture</h1>
<div
  mat-dialog-content
  dragAndDrop
  (dropped)="onDropImage($event)"
>
  <div
    class="contact-photo-upload-body"
    [class.hide-content]="loadingNewContactPhoto"
  >
    <p *ngIf="!cropperIsReady && !loadingNewContactPhoto">
      Drop image here or select your next contact photo
    </p>
    <p *ngIf="imageUploadFailed">Image upload failed, please try again.</p>
    <input
      #inputImageFile
      type="file"
      name="pic"
      accept="image/*"
      (change)="fileChangeEvent($event)"
    />
    <p *ngIf="cropperIsReady" style="margin: 10px">
      Drag to adjust image or rotate:
      <loading-icon
        style="display: inline-flex; margin-left: 5px"
        size="15"
        *ngIf="rotatingImage"
      ></loading-icon>
      <mat-icon
        *ngIf="!rotatingImage"
        (click)="rotateRight()"
        style="cursor: pointer"
        [inline]="true"
        >rotate_right</mat-icon
      >
    </p>
    <image-cropper
      class="image-cropper"
      [imageChangedEvent]="imageChangedEvent"
      [imageFile]="imageFile"
      [containWithinAspectRatio]="true"
      [roundCropper]="true"
      [resizeToWidth]="resizeTo"
      format="png"
      alignImage="left"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
    >
    </image-cropper>
    <!-- If we want to display what the picuture will look like.. -->
    <!-- <h2 *ngIf="cropperIsReady">Result: </h2>
                <div class="cropped-image-result" id="cropped-avatar-result">
                    <img [src]="croppedImage" style="border-radius: 100%" />
                </div> -->
    <div *ngIf="loadingNewContactPhoto">
      <loading-icon class="loading"></loading-icon>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" [class.hide-content]="loadingNewContactPhoto">
  <button type="button" mat-raised-button (click)="closePicModal()">
    Cancel
  </button>
  <button
    *ngIf="hasProfilePic() && !imageIsLoaded"
    type="button"
    mat-raised-button
    color="warn"
    (click)="showDeleteConfirmation()"
  >
    Delete
  </button>
  <button
    *ngIf="imageIsLoaded"
    type="submit"
    mat-raised-button
    color="primary"
    [disabled]="!cropperIsReady"
    (click)="this.changePhoto()"
  >
    Update
  </button>
</mat-dialog-actions>
