import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { ApplicationError } from './customErrors';
import { ErrorService } from 'src/app/services/error.service';
import { AdService } from '../services/ad.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(public _error: ErrorService, private adService: AdService, private analytics: AngularFireAnalytics) {
    super();
  }

  handleError(error: any) {

    if (error.message.includes("remote script failed") && error.message.includes("amazon")) {
      this.adService.setAdsAreBlocked();
    }

    if (error instanceof ApplicationError) {
      this.analytics.logEvent('application_error', { 'error_name': error.name })
      error.handle(this._error);
    } else {
      //This could be an error rejected in a promise or a general error.
      let rejectedError = error.rejection;
      if (rejectedError && rejectedError instanceof ApplicationError) rejectedError.handle(this._error);
      else this.generalErrorHandler(error); //This is a regular Error and not anything we threw.
    }
  }

  /**
   * Called when the error that was thrown was not by use but by the processor at runtime. 
   * @param error general Error like TypeError. 
   */
  private generalErrorHandler(error: Error) {
    console.log(`GeneralError: ${error}`);
    if (isDevMode() && error.stack) console.error(error.stack)
  }

}
