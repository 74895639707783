import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private router: Router) { }

  /**
   * Use this when adding a link to an Angular Material Dialog..
   * This will ensure the scroll position will go set to 0 properly.
   * 
   * Ex: [routerLink]="" (click)="goTo('/guidelines')"
   * 
   * @param path Path to go to
   * @param dialog Current dialog open
   */
  dialogNavigate(path: string, dialog: MatDialogRef<any>) {
    dialog.afterClosed().pipe(take(1)).subscribe(() => {
      this.router.navigate([path]);
    })
    dialog.close();
  }
}
