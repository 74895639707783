import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() message: string;
  @Input() hasAction = false;
  @Input() actionButtonText = "Ok";
  @Input() alignContentCenter = true;

  @Output() actionClicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  clicked() {
    this.actionClicked.emit(true);
  }

}
