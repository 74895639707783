import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Product } from "shared/interfaces/product";
import { take } from "rxjs/operators";
import { DisplayProductsComponent } from "./display-products/display-products.component";
import { ProductProvider } from "src/app/services/productProviders/productProvider";
import { ProductStoreService } from "src/app/services/productProviders/product-store.service";
import { ScreenSizeService } from "src/app/services/screen-size.service";
import { UserService } from "src/app/services/core/user.service";
import { Subscription } from "rxjs";

@Component({
  selector: "products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})

// Componenent to display all products
export class ProductsComponent implements OnInit, OnDestroy {


  @HostListener("window:scroll") scrolled(): void {
    this.scrollPos = window.pageYOffset;
  }

  @ViewChild(DisplayProductsComponent) productsComponent: DisplayProductsComponent;

  @Input() displayTitle: String;
  @Input() pageKey: string;
  @Input() productProvider: ProductProvider;
  @Input() showAds: boolean = false;
  @Input() adSearchTerm: string;
  @Input() noResultsText: string = "No results found.";
  @Input() useRelevantTimestamp: boolean = true; // if false, will use upload Time

  // how many products come before amazon ads
  adLocation: number;
  scrollPos = 0;
  products: Product[] = [];
  loadingFirst: boolean;
  loadingMore: boolean;
  nothingFound: boolean = false;
  endOfResults: boolean = false;
  showRefreshButton: boolean = false;
  subs: Subscription[] = [];

  constructor(private store: ProductStoreService, private screenSize: ScreenSizeService, private user: UserService) {}

  ngOnInit() {
    this.getProducts();

    if (this.screenSize.isSmallScreen(890)) {
      this.adLocation = 2;
    } else {
      this.adLocation = 6;
    }
  }

  // want always to be scrollable so next prods can be loaded
  getMinHeight(el: HTMLElement) {
    if (this.products.length === 0) return "0";
    return document.body.offsetHeight - el.offsetTop + 4 + "px"
  }

  // if true, forces products to come from firebase
  getProducts(refresh = false) {
    if(this.store.hasPage(this.pageKey) && !refresh) {
      this.products = this.store.restorePage(this.pageKey);
      this.showRefreshButton = true;
      setTimeout(() => {
        window.scrollTo(0, this.store.getScrollLocation(this.pageKey));
        if (this.showAds) this.productsComponent.setAdsToVisible()
      })
      return;
    }

    this.showRefreshButton = false;
    this.nothingFound = false;
    this.endOfResults = false;
    // Get only one copy of products, not listening to changes
    this.loadingFirst = true;
    this.productProvider
      .getInitialProducts()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.tryShowAds();
          if (res.length < 1) {
            this.nothingFound = true;
          }
          this.products = res;
          this.loadingFirst = false;
        },
        (error) => {
          this.loadingFirst = false;
          this.nothingFound = true;
          // Send error to firebase?
        }
      );
  }

  tryShowAds() {
    this.subs.push(this.user.firestoreIsInitialized$().subscribe(res => {
      if (res && this.showAds) this.productsComponent.setAdsToVisible();
    }))
  }

  onScroll() {
    if (!this.endOfResults && !this.nothingFound) this.getNextProducts();
  }

  // When a user scrolls, we should display more products on the screen
  getNextProducts() {
    if (this.loadingMore || this.loadingFirst) return;
    this.loadingMore = true;
    this.productProvider
      .getNextProducts(this.getLastTimestamp())
      .pipe(take(1))
      .subscribe(
        (prods) => {
          if (prods.length == 0) {
            this.endOfResults = !this.nothingFound; // Don't want both to be true
            this.loadingMore = false;
            return;
          }
          this.products = this.products.concat(prods);
          // Set last time stamp so we know where to start for getting next products
          this.loadingMore = false;
        },
        (error) => {
          this.loadingMore = false;
          this.endOfResults = !this.nothingFound; // Don't want both to be true
          // Send error to firebase?
        }
      );
  }

  getLastTimestamp() {
    const lastProduct = this.products[this.products.length - 1];
    if (this.useRelevantTimestamp) {
      return lastProduct.relevantTime;
    } else {
      return lastProduct.uploadTime;
    }
  }

  ngOnDestroy() {
    this.store.savePage(this.pageKey, this.products, this.scrollPos);
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
