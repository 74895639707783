import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'social-auth-list',
  templateUrl: './social-auth-list.component.html',
  styleUrls: ['./social-auth-list.component.scss']
})
export class SocialAuthListComponent implements OnInit {

  @Input() showGoogle = true;
  @Input() showFacebook = true;

  @Output() googlePress = new EventEmitter<MouseEvent>();
  @Output() facebookPress = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void {
  }

  getCols() {
    let cols = 0;
    if (this.showGoogle) cols++;
    if (this.showFacebook) cols++;
    return cols;
  }

}
