import { ValidatorFn, FormControl } from "@angular/forms";

export class PasswordMatchValidator {

/**
 * Checks and validates that two FormControl values are the same.
 * This is to be added to the parent or the FormGroup with the expectation 
 * @param passwordName name of the password FormControl. 
 * @param confirmPasswordName name of confirm password FormControl. 
 */
static passwordMatchValidator(passwordName: string, confirmPasswordName:string): ValidatorFn {
    return (control: FormControl) => {
      if (!control) return null;

      let pw = control.get(passwordName);
      let pwc = control.get(confirmPasswordName);
      if(!pw || !pwc) return null;
      let error = pw.value !== pwc.value;
      if(error) pwc.setErrors({mismatch: true});
      else pwc.setErrors(null)
      return (error) ? { mismatch: true} : null; 
    }
  }
}