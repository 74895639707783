<h1 mat-dialog-title>Delete product</h1>
<div mat-dialog-content>
  <alert *ngIf="bidCount > 0" 
    message="Products with bids should not be deleted. This goes against our guidelines."
    [hasAction]="true"
    [alignContentCenter]="false"
    actionButtonText="Acknowledge"
    (actionClicked)="deleteAcknowledged = true"
  ></alert>

  <p style="margin-top: 10px">
    Are you sure you would like to delete this product?
    <span
      *ngIf="!deleteShowMoreInfo"
      style="color: blue; cursor: pointer;"
      (click)="deleteShowMoreInfo = true"
    >
      More info
    </span>
  </p>
  <ul *ngIf="deleteShowMoreInfo">
    <li>Deleting a product cannot be undone</li>
    <li>All products will automatically be deleted after one month.</li>
  </ul>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button
    mat-raised-button
    color="warn"
    (click)="deleteProduct()"
    [disabled]="bidCount > 0 && !deleteAcknowledged"
  >
    Delete
  </button>
</mat-dialog-actions>
