import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UserHandler } from './userHandler';
import { PublicFireStoreUser, PrivateFireStoreUser } from 'shared/interfaces/fireStoreUserI';
import { CurrentUserError, FirestoreError } from '../error-handler/customErrors';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { isDevMode } from '@angular/core';
import { catchError, take } from 'rxjs/operators';
export class FireStoreUser {

  publicData:PublicFireStoreUser = {
    bidsPlaced:0,
    bidsWon:0,
    itemsSold:0, 
    displayName:'',
    hasProfilePic:false,
  }

  privateData:PrivateFireStoreUser = {
    contactEmail:'', 
    contactPhoneNumber:'',
    location:null,
    language:'',
    bids:[],
    pickUpLocation:'',
    mapPickUpLocation:null,
    notificationTokens:[],
    premiumDaysLeft: 0,
    referId:'',
  }

  private PRIVATE = "privateUser";
  private USER = "user";
  
  publicData$:Observable<PublicFireStoreUser>; //valuechanges Observable of the firestore user doc. 
  privateData$:Observable<PrivateFireStoreUser>; //valuechanges Observable of the firestore user doc. 
  publicUserRef:AngularFirestoreDocument<PublicFireStoreUser>;
  privateUserRef:AngularFirestoreDocument<PrivateFireStoreUser>;
  publicSub:Subscription; 
  privateSub:Subscription; 
  // use once to know when document has been downloaded and display name is ready
  private userInitializedSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public initialized:Observable<boolean> = this.userInitializedSubject.asObservable();

  private afs:AngularFirestore;

  /**
   * Create the firestoreuser class object, once the firestore document has been successfully found,
   * the displayName is optionally updated and the initialized observable emits to the parent that it has initialized. 
   * @param uid uid of new user. FireAuth uid and Firestore doc uid
   * @param afs the service for accessing firestore
   * @param displayName the displayName, this is optional since, it only is used when user rigisters with EmailPassword for the first time.
   */
  constructor(uid:string, afs:AngularFirestore, displayName?:string, referId?:string){
    this.afs = afs; 
    this.publicUserRef = this.afs.doc(`users/${uid}`); 
    this.privateUserRef = this.publicUserRef.collection(this.PRIVATE).doc<PrivateFireStoreUser>(this.USER); 

    //Everytime the user changes (logs out and log in again) this object is created 
    this.publicData$ = this.publicUserRef.valueChanges(); 
    this.privateData$ = this.privateUserRef.valueChanges(); 

    //This keeps the 'non-observable' or 'non-listening' returned properties in sync with db. 
    //If the user has just registered an account, then the firebase function will create this document, so this might return null until the docuemnt is created.
    this.publicSub = this.publicData$.pipe(catchError(err => { throw new FirestoreError(`Failed to subscribe to publicData: ${err}`, 2025) }))
    .subscribe(user => {
      if(user != null){
        let hash = {};
        //This is slightly non-intuitive code: since the user firestore doc gets created with a firebase function, 
        // if the user registers with email/password registration then the firebase function doesn't have access to the display name 
        // and it should be updated here on the first time this runs after the firestore user doc is created. 
        if(displayName){ 
          hash['displayName'] = displayName;
          displayName = null;
          this.update(hash)
        }else {
          this.userInitializedSubject.pipe(take(1)).subscribe(res => {
            if (!res) this.userInitializedSubject.next(true);
          })
        }
        for(let prop in this.publicData)
          this.publicData[prop] = user[prop]; 
      } else {
      }
    });

    this.privateSub = this.privateData$.pipe(catchError(err => { throw new FirestoreError(`Failed to subscribe to privateData: ${err}`, 2024) }))
    .subscribe(user => {
      if(user != null){
        let hash = {};
        let currentReferId = user['referId'];
        //The referId is similar to the displayName above, but since the firebase function will replace the referId with an empty string once it is used, 
        // We need to make sure we are not updating it everytime a user uses the link, even if they already have an account, 
        // that is why we check to see if the referId is undefined (since referId is an optional parameter and NOT set upon authuser creation) as as to identify
        // when the referId needs to be set for the first time.   
        if(referId && currentReferId == undefined){ 
          hash['referId'] = referId;
          referId = null;
          this.update(hash)
        }
        for(let prop in this.privateData)
          this.privateData[prop] = user[prop]; 
      } else {
      }
    });

    return new Proxy(this, UserHandler.handler);
  }

  /**
   * Call this method to unsubscribe from all subscriptions 
   * upon dereferencing the instance of this class.
   */
  public destroy(){
    if(this.privateSub) this.privateSub.unsubscribe();
    if(this.publicSub) this.publicSub.unsubscribe();
  }

  /**
   * Update multiple values at once. 
   * @param hash key:value object with key = property name, value = value of property
   */
  update(hash:object):Promise<any>{
    let props = Object.getOwnPropertyNames(hash); 
    let pubUpdate:any = {};
    let privateUpdate:any = {};
    let updatePub = false;
    let updatePrivate = false;
    props.forEach(key => {
      if(key in this.publicData){
        pubUpdate[key] = hash[key];
        updatePub = true;
      }
      else if(key in this.privateData){
        privateUpdate[key] = hash[key];
        updatePrivate = true;
      }
      else throw new CurrentUserError(`Tried to update with properties not in a firestore user: ${key}`, 6020);
    })
    let batch = this.afs.firestore.batch(); 
    if(updatePub){
      if(isDevMode()) console.log("Setting public document.")
      batch.set(this.publicUserRef.ref, pubUpdate, {merge: true})
    }
    if(updatePrivate){
      if(isDevMode()) console.log("Setting private document.")
      batch.set(this.privateUserRef.ref, privateUpdate, {merge: true})
    }
    return batch.commit()
    .catch(err => { throw new FirestoreError(`Could not commit batch: ${err}`, 2026) });
  }

  //Methods used in the Proxy handler to get the private of public version of useful classes
  getUserRef(prop:string):AngularFirestoreDocument<PublicFireStoreUser|PrivateFireStoreUser>{
    if(prop in this.publicData)
        return this.publicUserRef;
    if(prop in this.privateData)
        return this.privateUserRef;
    return null; 
  }
  getObservable(prop:string):Observable<PublicFireStoreUser|PrivateFireStoreUser>{
    if(prop in this.publicData)
        return this.publicData$;
    if(prop in this.privateData)
        return this.privateData$;
    return null;
  }
  getValue(prop:string):any{
    if(prop in this.publicData)
        return this.publicData[prop];
    if(prop in this.privateData)
        return this.privateData[prop];
    return null; 
  }


}
