import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product } from 'shared/interfaces/product';
import { ApplicationError } from 'src/app/error-handler/customErrors';
import { GlobalConstants } from 'src/app/Global';
import { UserService } from 'src/app/services/core/user.service';
import { ProductService } from 'src/app/services/product.service';
import { DeleteProductDialog } from '../products/display-products/product-details/product-details.component';
import { ReportFormDialog } from '../report-form/report-form.component';

@Component({
  selector: 'per-item-menu',
  templateUrl: './per-item-menu.component.html',
  styleUrls: ['./per-item-menu.component.scss']
})
export class PerItemMenuComponent implements OnInit, OnDestroy {

  // Only supply if this is a product menu
  @Input() product: Product = null;

  // does not gurantee share button will be visible
  @Input() allowShare = false;
  @Input() allowReportUser = false;
  @Input() allowReportProduct = false;
  @Input() allowEdit = false;
  @Input() allowDelete = false;
  // @Input() allowBump = false; not sure if I want to allow this here.. I'm lazy
  // @Input() allowWatchlistToggle = false

  @Input() productIdToReport = "";
  @Input() userIdToReport = "";
  @Input() shareId = "";

  // defaults to sharing products
  @Input() shareURL = "";
  @Input() shareTitle = GlobalConstants.NAME;

  @Output() deleteEvent = new EventEmitter<MouseEvent>();
  @Output() editEvent = new EventEmitter<MouseEvent>();

  nav: any = window.navigator

  subs: Subscription[] = [];


  constructor(private productService: ProductService, private router: Router,
              private dialog: MatDialog, private user: UserService) { }

  ngOnInit(): void {
    if (this.allowReportProduct && !this.productIdToReport) throw new ApplicationError("Product ID required to report", 5106);
    if (this.allowReportUser && !this.userIdToReport) throw new ApplicationError("User ID required to report", 5107);
    if (this.allowShare && !this.shareId && !this.shareURL) throw new ApplicationError("Share ID or url required to share", 5108);

    if (!this.shareURL && this.shareId) {
      this.shareURL = "https://dailybids.ca/products/" + this.shareId;
    }
  }

  showShareButton() {
    return this.nav && this.nav.share && this.allowShare;
  }

  // trigger delete event and follow through with delete if product is provided
  triggerDelete(event: MouseEvent) {
    if (this.product) this.openDeleteModal(event);
    else this.deleteEvent.emit(event);
  }

  openDeleteModal(event: MouseEvent) {
    const dialogRef = this.dialog.open(DeleteProductDialog, {
      width: '620px',
      data: this.product.bidCount
    });

    this.subs.push(
      dialogRef.afterClosed().subscribe(res => {
        if (res && res.delete) {
          this.productService.delete(this.product);
          this.deleteEvent.emit(event)
        }
      })
    );
  }


  triggerEdit(event: MouseEvent) {
    this.editEvent.emit(event);
    if (this.product) {
      this.router.navigate(["/edit",
        {
          productId: this.product.id
        }
      ]);
    }
  }

  openReportDialog(type: "Product" | "User") {
    const id = type === "Product" ? this.productIdToReport : this.userIdToReport;
    const reporterId = this.user.uid();
    const locId = this.user.getLocation().id;
    this.dialog.open(ReportFormDialog, {
      width: '500px', 
      data: { type: type, id: id, reporterId: reporterId, locId: locId }
    });
  }

  share() {
    let message = null;
    if (this.product)
      message = this.product.title;
    if (this.showShareButton()) {
      this.nav.share({
        title: this.shareTitle,
        url: this.shareURL,
        text: message
      })
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
