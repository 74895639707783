<div style="display: inline-block;">
  <span class="wrap">
    <user-signpost
      [includeProfilePic]="true"
      [name]="_winningBid.username"
      [userId]="_winningBid.userId"
      [openBelow]="false"
      [uid]="_winningBid.id + '_winnging_signpost'"
      [showMessageButton]="showMessageButton"
      [messageIds]="messageIds"
      [userHasProfilePic]="_winningBid.hasProfilePic"
    ></user-signpost>
    <span style="margin-left: 4px">${{ _winningBid.amount }} ({{ finalized ? "winner" : "in the lead"}})</span>
  </span>
</div>
