<div class="contact-wrapper" #signpostWrap>
  <span class="contact-container" (click)="tryOpen()" [style.cursor]="!disabled ? 'pointer' : ''">
    <profile-pic
      *ngIf="includeProfilePic"
      [userId]="userId"
      [defaultImage]="defaultThumb"
      [useThumbnail]="true"
      [size]="size"
      [uid]="uid + '_prof-pic'"
      [userHasProfilePic]="userHasProfilePic"
    ></profile-pic>
    <span
      class="bidder-name contact-text"
    >
      <!-- <mat-icon
      [inline]="true"
      *ngIf="hasContactInfo()"
    >contact_phone</mat-icon> -->
      {{ name }}
      </span>
  </span>

  <div *ngIf="isOpen" 
    [ngClass]="openBelow ? 'sign-overlay-below' : 'sign-overlay-top'" 
    class="sign-overlay">
    <div class="signpost-main-container">
      <div class="row">
        <div class="col-xs-4">
          <profile-pic
            class="photo"
            [userId]="userId"
            [editable]="false"
            [defaultImage]="default"
            [useThumbnail]="false"
            size="75"
            [uid]="'signPost-' + uid"
            [userHasProfilePic]="userHasProfilePic"
          ></profile-pic>
        </div>
        <div class="col-xs-8">
          <h3 class="big-name">{{ name }}</h3>
          <mat-chip-list [disabled]="!signPostBelongsToCurrentUser()" [class.clickable]="signPostBelongsToCurrentUser()">
            <mat-chip (click)="goToWatchlist()">{{ bidsWon }} Bids won</mat-chip>
            <mat-chip (click)="goToWatchlist()">{{ bidsPlaced }} Bids placed</mat-chip>
            <mat-chip (click)="goToPosts()">{{ itemsSold }} Items sold</mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div *ngIf="!signPostBelongsToCurrentUser()">
        <per-item-menu [allowReportUser]="true" [userIdToReport]="_userId"></per-item-menu>
      </div>
    </div>
    <div class="contact-info" *ngIf="hasContactInfo()">
      <div *ngIf="email && email.length > 1">
        <!-- <clr-icon shape="envelope" style="margin-bottom: 2px;"></clr-icon> -->
        <mat-icon>email_outlined</mat-icon>
        {{ email }}
      </div>
      <div *ngIf="phoneNumber && phoneNumber.length > 1">
        <!-- <clr-icon
          *ngIf="hasContactInfo()"
          shape="phone-handset"
          style="margin-bottom: 4px;"
        ></clr-icon> -->
        <mat-icon>call</mat-icon>
        {{ phoneNumber }}
      </div>
    </div>
 
    <div *ngIf="shouldShowMsgButton()">
      <hr/>
      <div *ngIf="showMessageInput; else showButtonOptions">
        <b>Message {{ currentUserIsSeller ? 'buyer' : 'seller' }} </b>
        <div>
          <input #messageInput type="text" (keyup.enter)="sendMessage($event.target)" placeholder="Ask a question..."
            [maxLength]="MAX_LEN" />
        </div>
        <div>
          <button [disabled]="!messageInput.value" mat-flat-button color="primary"
            (click)="sendMessage(messageInput)">Send</button>
        </div>
      </div>
      <ng-template #showButtonOptions>
        <a (click)="goToConversations()">View message in conversations</a>
      </ng-template>
    </div>
  </div>
</div>
