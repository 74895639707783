import { Component, OnInit, OnDestroy, isDevMode, HostListener, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Subscription, SubscriptionLike } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { formatDate, Location } from '@angular/common';
import { UserService } from 'src/app/services/core/user.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GoogleLocation } from 'shared/location';
import { ApplicationError } from 'src/app/error-handler/customErrors';
import { Product } from 'shared/interfaces/product';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { take } from 'rxjs/operators';
import { ImageHelperService } from 'src/app/services/image-helper.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { GlobalConstants } from 'src/app/Global';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  host: {
    '(document:keyup)': 'handleKeyboardEvent($event)'
  }
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  @Input() previewOnly: boolean = false;
  @Output() postProduct = new EventEmitter<boolean>();

  private SMALL_SCREEN_TRHESHOLD = 600;
  isSmallScreen$ = new BehaviorSubject<boolean>(
    this.screenSize.isSmallScreen(this.SMALL_SCREEN_TRHESHOLD)
  );
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isSmallScreen$.next(
      this.screenSize.isSmallScreen(this.SMALL_SCREEN_TRHESHOLD)
    );
  }
  @HostListener("window:click", ["$event"])
  onClick(event) {
    if (this.product && this.showModal && event.target.id == 'prod-modal-' + this.product.id) {
      this.closeModal()
    }
  }

  defaultImg = GlobalConstants.LOADING_IMG_URL;
  SHORT_DESCRIPTION_LENGTH = 40;
  showFullDescription = false;
  gMapsUrl: SafeResourceUrl;
  showModal = false;
  product: Product = null;
  defaultThumb: string;
  subs: Subscription[] = []; // These will be destroyed whenever a product modal is closed
  onDestrySubs: SubscriptionLike[] = []; // These will only be destroyed on ngOnDestroy
  loadingProduct: boolean = false;
  ALLOWED_BUMPS: number = 3;

  panningBottomPos = 0;

  posterNumber = "";
  posterEmail = "";

  currentImageIndex = 0;
  showFullImage = false;

  yPos = 0;

  constructor(private prodService: ProductService, private location: Location,
    public currentUser:UserService, private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router,
    private imageService: ImageHelperService, private screenSize: ScreenSizeService, 
    public dialog: MatDialog, public dialogs:DialogService) { }
    
  ngOnInit() {

    this.defaultThumb = GlobalConstants.DEFAULT_PROFILE_PICTURE_URL_THUMB;

    this.onDestrySubs.push(this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        this.yPos = window.scrollY;
      } else if (ev instanceof NavigationEnd) {
        if (window.scrollY == 0)
        window.scrollTo(0, this.yPos);
      }
    })
    );

    // necessary to open modal on component init
    this.onDestrySubs.push(this.route.params.subscribe(params => {
      const id = params['id']
      if (id && id.length > 2 && this.location.path().includes(id)) {
        if (!this.previewOnly) this.showDetails(id, false);
      } else {
        this.closeDetails();
      }
    }));

    // necessary to close modal on back arrow
    this.onDestrySubs.push(this.location.subscribe(next => {
      this.route.params.pipe(take(1)).subscribe(params => {
        const id = params['id']
        if (id && id.length > 2 && next.url && next.url.includes(id)) {
          if (!this.previewOnly) this.showDetails(id, false);
        } else {
          this.closeDetails();
        }
      })
    }));
  }

  //Controls whether to have the message button/input in the signpost or not.
  //Essentially we want anyone to be able to message the seller while the product is live (other than the seller itself)
  //But once the product has expired/finalized we want only the winning bidder to be able to message the seller 
  //from the signpost.
  get showSignpostMessageButton(){
    if(this.product.finalized)
      return this.product.winningBid ? this.currentUser.uid() == this.product.winningBid.userId : false;
    return this.product.posterId != this.currentUser.uid();
  }  

  isDevMode() {
    return isDevMode();
  }

  isCurrentImage(img: string) {
    return img == this.product.images[this.currentImageIndex];
  }

  swipeImageLeft() {
    if (this.currentImageIndex < this.product.images.length - 1) 
    this.currentImageIndex++;
  }

  swipeImageRight() {
    if (this.currentImageIndex > 0)
      this.currentImageIndex--;
  }

  getImageUrl(index=this.currentImageIndex) {
    return this.imageService.getMainImageUrl(this.product.baseURL, this.product.images[index]);
  }

  getImageMidUrl(index=this.currentImageIndex) {
    return this.imageService.getImageMidUrl(this.product.baseURL, this.product.images[index]);
  }

  getImageThumb(img: string) {
    return this.imageService.getImageThumbUrl(this.product.baseURL, img);
  }

  toggleShowFullImage() {
    this.showFullImage = !this.showFullImage;
  }

  setImage(img: string, event=null) {
    if (event) event.stopPropagation()
    this.setImageIndex(this.product.images.indexOf(img));
  }

  setImageIndex(index: number) {
    this.currentImageIndex = index;
  }

  // If we have some data for the product, use this to open modal faster
  showDetailsWithProd(prod: Product, number="", email="") {
    if (prod.deleted) return;
    this.product = prod;
    this.posterEmail = email;
    this.posterNumber = number;
    this.setGoogleMapSource(prod)
    this.showDetails(prod.id)
  }

  userSignedIn(): boolean {
    return this.currentUser.exists();
  }

  userVerified() {
    return this.currentUser.emailVerified();
  }

  userLogin() {
    // this.closeModal();
    this.dialogs.openLogin();
    this.prodService.saveIdForAfterSignin(this.product.id);
  }

  userVerify() {
    // this.closeModal();
    this.currentUser.openVerification();
  }

  /**
   * /products/ --> /products/<productID>
   * /products/?hello=world --> /products/<productID>?hello=world
   * /products;something=else/ --> /products/<productID>;something=else
   * /products;something=else/?hello=world --> /products/<productID>;something=else?hello=world
   * This next one shouldn't happen irl
   * /products/?hello=world/;something=else --> /products/<productID>;something%3Delse
   */
  setLocationOnOpen(id: string) {
    // apparently angular router params use ';'
    const paramsArray = this.location.path().split("?");
    const angularParams = this.location.path().split(";");
    const hasParams = paramsArray.length > 1;
    const hasAngularParams = angularParams.length > 1;
    // take the root of the location before any params
    const preParams = (paramsArray[0].length < angularParams[0].length) ? paramsArray[0] : angularParams[0];
    // keep params, but only keep angularParams if they exist
    let postParams = hasParams ? "?" + paramsArray[1] : "";
    postParams = (hasAngularParams ? ";" + angularParams[1] : postParams);
    this.location.go(preParams.split("/" + id)[0] + "/" + id + postParams);
  }

  showDetails(id: string, setLocation: boolean=true) {
    this.resetBools();
    if (setLocation) this.setLocationOnOpen(id);
    if ((this.product == null || id != this.product.id || true)  && !this.previewOnly) { // Remove if statement if we decide it is better to stay listening
      this.closeDetails(); // Remove any previous data
      this.loadingProduct = true;
      if (this.currentUser.hasSetLocation()) {
          this.subs.push(this.prodService.getProductFromCityOnce(id).subscribe(res => {
          if (res.exists) {
            this.getPrivateProduct(res.data())
            this.setProduct(res.data() as Product)
            this.subs.push(
              this.prodService.getProductFromCityListen(id).subscribe(res => {
                this.setProduct(res as Product)
              }));
          } else {
            this.getProductFromAnywhere(id);
          }
        },
        err => this.handleFirebaseFail(err, id)));
      } else {
        this.getProductFromAnywhere(id, true);
      }
    } else if (this.previewOnly) {
      this.setProduct(this.product);
    }
  }

  getProductFromAnywhere(id: string, setLocation = false) {
    this.subs.push(this.prodService.getProductFromAnywhereOnce(id).subscribe(res => {
      if (!res.empty) {
        const product = res.docs[0].data() as Product;
        this.getPrivateProduct(product)
        this.setProduct(product)
        this.subs.push(this.prodService.getProductFromAnywhereListen(id).subscribe(res => {
          this.setProduct(res[0] as Product);
        }))
        if (setLocation) // If there is no location set, we can set the default here
        this.currentUser.changeLocation(new GoogleLocation(this.product.locationID, this.product.location), true, true)
      } else {
        this.loadingProduct = false;
        this.toastr.error("Unable to find product, it may have been deleted.");
        this.closeModal(id);
        // Product no longer exists, or firebase sucks ass
      }
    },
    err => this.handleFirebaseFail(err, id)));
  }

  setProduct(prod: Product) {
    this.product = prod;
    this.setGoogleMapSource(prod);
    document.body.style.overflow = "hidden";
    this.showModal = true;
    this.loadingProduct = false;
  }

  getPrivateProduct(prod: Product) {
    if (this.currentUser.emailVerified())
      this.prodService.getPrivateProduct(prod).pipe(take(1)).subscribe(privateData => {
        if (!privateData) return;
        this.posterNumber = privateData.posterNumber;
        this.posterEmail = privateData.posterEmail;
      })
  }

  handleFirebaseFail(err, id: string) {
    console.log(err);
    this.loadingProduct = false;
    this.closeModal(id);
    this.toastr.error("Unable to load product, please try again or check your connection.");
  }

  closeDetails() {
    this.showModal = false;
    document.body.style.overflow = "visible";
    this.subs.forEach(s => s.unsubscribe());
    this.product = null;
    this.gMapsUrl = null;
    this.posterEmail = "";
    this.posterNumber = "";
    this.panningBottomPos = 0;
  }

  closeModal(id=this.product.id, startingBottom: number = this.panningBottomPos) {

    this.animateProductModal(startingBottom, startingBottom - 300)

    let newPath = this.location.path().replace("/" + id, "/")
    this.location.go(newPath);

    setTimeout(() => this.closeDetails(), 150);
  }

  animateProductModal(startingBottom: number, endingBottom: number, startingOpactiy = 1, endingOpactiy = 0, duration = 160) {
    this.getProductModalContentElement().animate([
      // keyframes
      {  opacity: startingOpactiy, bottom: startingBottom + "px" },
      { opacity: endingOpactiy, bottom: endingBottom +  "px"}
    ], {
      // timing options
      duration: duration,
    });
  }

  getProductModalContentElement() {
    return document.getElementById('prod-modal-' + this.product.id + '-content')
  }

  panUp(event) {
    if (this.panningBottomPos < 0) {
      this.pan(event)
    }
  }

  pan(event) {
    this.panningBottomPos = -event.deltaY;
    if (this.panningBottomPos < 0 )
    this.getProductModalContentElement().style.bottom = - event.deltaY + "px";
  }

  panEnd(event) {
    if (event.deltaY < 0) {
      this.getProductModalContentElement().style.bottom = 0 + "px";
      this.panningBottomPos = 0;
      return;
    }
    if (event.deltaY > 150) {
      this.closeModal(this.product.id, -event.deltaY);
    } else {
      this.animateProductModal(-event.deltaY, 0, 1, 1, 200);
      setTimeout(() => {this.getProductModalContentElement().style.bottom = 0 + "px";}, 150);
    }
  }

  resetBools() {
    this.showFullDescription = false;
    this.showFullImage = false;
    this.currentImageIndex = 0;
  }

  getMapWidth() {
    if (document.getElementById('smap'))
      return document.getElementById('smap').offsetWidth 
    return 300
  }

  showBigMap() {
    return window.screen.width > 600
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      if (this.showFullImage) {
        this.showFullImage = false;
      } else {
        this.closeModal();
      }
    }
  }

  shortenDescription(d: string) {
    return d.substring(0, this.SHORT_DESCRIPTION_LENGTH).trim();
  }

  // Return true if description is long and the user 
  // should be able to toggle to see it in full
  shouldToggleDescription() {
    const buffer = 50; // Buffer so that lenghts close to the cutoff don't get trimmed
    return this.product.description.length > this.SHORT_DESCRIPTION_LENGTH + buffer || 
      this.product.description.split('\n').length > 3;
  }

  getBidEndTime(time: firebase.default.firestore.Timestamp): Date {
    return new Date(time.toDate().getTime() + 86400000)
  }

  formatDateCust(date: Date) {
    return formatDate(date, 'dd MMM hh:mm:ss.SSS a', 'en-US');
  }


  setGoogleMapSource(product) {
    const url = this.prodService.getGmapUrlFromProduct(product);
    if (!this.gMapsUrl) this.gMapsUrl = url;
    // Not sure how else to compare equality, this is nice so that map doesn't reload unnecessarily
    // @ts-ignore
    else if (this.gMapsUrl.changingThisBreaksApplicationSecurity !== url.changingThisBreaksApplicationSecurity) {
    this.gMapsUrl = url;
    }
  }

  isOwner(): boolean {
    return this.currentUser.uid() === this.product.posterId;
  }

  remainingBumps(): number {
    return this.ALLOWED_BUMPS - this.product.bumpsUsed;
  }

  bumpProduct() {
    if (this.bumpAllowed()) {
      this.prodService.bumpProduct(this.product).then(res => {
        this.toastr.success("This product has been bumped to the top.")
      }).catch(err => {
        throw new ApplicationError("Bump unsuccessful: " + err, 5001)
      });
    }
  }

  bumpAllowed() {
    return this.isOwner() && this.product.bumpsUsed < this.ALLOWED_BUMPS && !this.product.finalized;
  }

  editProduct() {
    this.router.navigate(["/edit", 
      {
        productId: this.product.id
      }
    ]);
  }

  productHasBeenEdited() {
    return this.product.editedTime != null
  }
  
  productInDifferentCity() {
    return this.product.locationID != this.currentUser.getLocation().id && this.product.locationID.length > 1;
  }

  switchToCityOfProduct() {
    this.currentUser.changeLocation(
      new GoogleLocation(this.product.locationID, this.product.location),
      false, true
    )
  }
  
  deleteProduct() {
    const product = this.product;
    this.closeModal();
    this.prodService.delete(product);
  }

  get messageIds(){
    return {
      sellerId:this.product.posterId, 
      buyerId:this.currentUser.uid(), 
      productId:this.product.id, 
      locationId:this.product.locationID
    }
  }

  emitPost() {
    this.closeModal();
    this.postProduct.emit(true);
  }

  ngOnDestroy() {
    this.closeDetails();
    this.onDestrySubs.forEach(sub => sub.unsubscribe());
  }

}

@Component({
  selector: 'delete-product-dialog',
  templateUrl: 'delete-product-dialog.html'
})
export class DeleteProductDialog {

  confirmDeleteModal = false;
  deleteAcknowledged = false;
  deleteShowMoreInfo = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteProductDialog>,
    @Inject(MAT_DIALOG_DATA) public bidCount: number
    ) {}

  onNoClick(): void {
    this.confirmDeleteModal = false;
    this.deleteAcknowledged = false;
    this.deleteShowMoreInfo = false;
    this.dialogRef.close();
  }

  deleteProduct() {
    this.confirmDeleteModal = false;
    this.deleteAcknowledged = false;
    this.deleteShowMoreInfo = false;
    this.dialogRef.close({delete: true});
  }

}
