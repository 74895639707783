import { Overlay } from "@angular/cdk/overlay";
import { ElementRef, HostListener, ViewChild } from "@angular/core";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalConstants } from 'src/app/Global';
import { UserService } from 'src/app/services/core/user.service';
import { MessageService } from "src/app/services/message.service";
import { Conversation } from "src/app/objects/conversation";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "user-signpost",
  templateUrl: "./user-signpost.component.html",
  styleUrls: ["./user-signpost.component.scss"],
})
export class UserSignpostComponent implements OnInit, OnDestroy {

  @ViewChild('signpostWrap') signpost: ElementRef;

  @Input() openBelow = true;

  @HostListener('document:mouseup', ['$event'])
  onClick(event): void {
    if (this.isOpen && !this.signpost.nativeElement.contains(event.target)) {
      // allow things in per-item-menu to be clicked
      setTimeout(() => {this.isOpen = false}, 1);
    }
  }


  @Input() name: string; // Name of user
  @Input()  // user id of user
  get userId(): string { return this._userId} 
  set userId(id: string) { 
    if (this._userId != null) this.clearStats(); // clear stat if same html element is being re-used.
    this._userId = id;
   }
  @Input() userHasProfilePic?: boolean; // OPTIONAL, true if the user has a profile pic. 
                                        //Do not perform an additional read just for this value. Leave null if you don't already have it
  @Input() uid: string; // REQUIRED must be unique over all DOM.
  @Input() showContactInfo: boolean = false; // Set true if users contact info should be displayed
  @Input() phoneNumber: string;
  @Input() email: string;
  @Input() disabled: boolean = false; // if true, sign post will not pop up
  @Input() includeProfilePic: boolean = false;
  @Input() showMessageButton: boolean = false; // if true, a button will appear to message user within daily bids
  @Input() messageIds:{ sellerId, buyerId, productId, locationId };
  @Input() size = 30;

  defaultThumb = GlobalConstants.DEFAULT_PROFILE_PICTURE_URL_THUMB;
  showMessageInput:boolean = true;

  MAX_LEN: number = GlobalConstants.MAX_MSG_INPUT;

  isOpen = false;

  default: string;

  sub: Subscription;

  _userId: string;

  bidsWon: number;
  bidsPlaced: number;
  itemsSold: number;

  get currentUserIsSeller(){
    return this.messageIds.sellerId == this.currentUser.uid()
  }

  constructor(private currentUser: UserService, public overlay: Overlay, 
    private _message:MessageService, private toastr:ToastrService) {}


  ngOnInit() {
    this.default = GlobalConstants.DEFAULT_PROFILE_PICTURE_URL_THUMB;
  }

  clearStats() {
    this.bidsWon = null;
    this.bidsPlaced = null;
    this.itemsSold = null;
  }

  tryOpen() {
    if (this.disabled) return;
    this.getStats();
    this.isOpen = !this.isOpen;
  }

  getStats() {
    if (this.bidsWon == null)
      // if not null, already subscribed
      this.sub = this.currentUser.getUserStats(this.userId).subscribe((res) => {
        this.bidsWon = res.bidsWon;
        this.bidsPlaced = res.bidsPlaced;
        this.itemsSold = res.itemsSold;
        if (this.userHasProfilePic == null) {
          this.userHasProfilePic = res.hasProfilePic;
        }
      });
  }

  hasContactInfo() {
    return (
      this.showContactInfo &&
      ((this.email && this.email.length > 1) ||
        (this.phoneNumber && this.phoneNumber.length > 1))
    );
  }

  //Callback for when the message button is clicked!
  async sendMessage(inputRef){
    let message = inputRef.value;
    let convo = await this._message.createConversation(
      this.messageIds.sellerId, 
      this.messageIds.buyerId, 
      this.messageIds.productId, 
      this.messageIds.locationId
      )
    this._message.sendMessage(message, new Conversation(convo, this.currentUser.uid()))
    .then(() => {
      this.toastr.success(`Message successfully sent to ${this.name}`)
    })
    .finally(() => {
      inputRef.value = '';
      this.showMessageInput = false;
    })
  }

  goToConversations(){
    this._message.open(`${this.messageIds.sellerId}-${this.messageIds.buyerId}-${this.messageIds.productId}`)
  }

  signPostBelongsToCurrentUser() {
    return this.currentUser.uid() === this._userId;
  }

  goToWatchlist() {
    if (this.signPostBelongsToCurrentUser())
      this.currentUser.goToWatchlist();
  }

  goToPosts() {
    if (this.signPostBelongsToCurrentUser())
      this.currentUser.goToUserPosts();
  }

  shouldShowMsgButton() {
    return this.showMessageButton && this.currentUser.emailVerified();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
