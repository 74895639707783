<mat-toolbar
  id="navbar"
  *ngIf="!(isSmallScreen$ | async)"
  class="nav-bar"
>
  <div class="desktop-branding">
    <a [routerLink]="['/']" class="nav-link">
      <span class="nav-link-child">{{ name }}</span>
    </a>
  </div>

  <mat-divider></mat-divider>

  <div *ngIf="locIDIsSet(); else locationNotSet">
    <div
      class="nav-link location-field location-border"
      (click)="resetLocation()"
      [title]="
        getDisplayCity() + ' - Current city for bidding (click to change)'
      "
    >
      <div class="nav-link-child">
        <div class="display-city">{{ getDisplayCity() }}</div>
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </div>
  </div>

  <ng-template #locationNotSet>
    <div class="location-field">
      <form>
        <mat-form-field floatLabel="never">
          <mat-placeholder>Select a city</mat-placeholder>
          <input
            googleAutocomplete
            [formControl]="cityInputControl"
            [startAutocomplete]="switchToGoogleAutocomplete"
            #placesRef="custom-places"
            name="location of bidding"
            type="text"
            [options]="options"
            (onAddressChange)="handleAddressChange($event)"
            id="location-input"
            matInput
            [matAutocomplete]="autoCity"
          />
          <mat-icon
            matSuffix
            class="small-icon"
            matTooltip="Begin typing and select a city from the drop-down"
            >info</mat-icon
          >
          <mat-autocomplete
            (optionSelected)="handleCustomSelect($event)"
            #autoCity="matAutocomplete"
          >
            <mat-option
              *ngFor="let option of filteredAutocompleteOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
  </ng-template>

  <form
    *ngIf="hasLocationSet()"
    class="search"
    (submit)="searchFor(searchInput)"
    autocomplete="off"
  >
    <mat-form-field floatLabel="never" style="width: 300px">
      <mat-placeholder>Search for products...</mat-placeholder>
      <input
        matInput
        type="search"
        id="search_input"
        [(ngModel)]="searchInput"
        [ngModelOptions]="{ standalone: true }"
        [maxLength]="MAX"
      />
      <mat-icon matSuffix class="small-icon" (click)="searchFor(searchInput)"
        >search</mat-icon
      >
    </mat-form-field>
  </form>

  <span class="fill-space-toolbar" (click)="scrollToTop()"></span>
  <a routerLink="/products">
    <button
      *ngIf="hasLocationSet()"
      mat-raised-button
      color="primary"
      class="action-btn"
      (click)="showToastrIfAtProducts()"
    >
      Bid
    </button>
  </a>
  <a routerLink="/sell">
    <button
      *ngIf="hasLocationSet()"
      mat-raised-button
      color="primary"
      class="action-btn"
    >
      Sell
    </button>
  </a>
  <!-- 
  <a routerLink="/conversations"
  ><button mat-mini-fab class="action-button" color="warn">
    <span
      class="action-icon"
      [matBadge]="getConversationBadge()"
      matBadgeColor="warn"
      >Bid</span
    >
  </button></a
>

  <a routerLink="/conversations"
  ><button mat-mini-fab class="action-button">
    <mat-icon
      class="action-icon"
      [matBadge]="getConversationBadge()"
      matBadgeColor="warn"
      >message</mat-icon
    >
  </button></a
>
  <a routerLink="/notifications"
    ><button mat-mini-fab class="action-button">
      <mat-icon
        class="action-icon"
        [matBadge]="getNotificationBadge()"
        matBadgeColor="warn"
        >notifications</mat-icon
      >
    </button></a
  > -->

  <div *ngIf="this.currentUser.exists()" class="header-actions">
    <div class="nav-link profile-menu-wrap" [matMenuTriggerFor]="desktopMenu">
      <profile-pic
        class="nav-link-child"
        [userId]="this.currentUser.uid()"
        [editable]="false"
        [defaultImage]="defaultThumb"
        size="35"
        uid="nav-bar-profile_pic"
        [useThumbnail]="true"
        [userHasProfilePic]="this.currentUser.firestore.hasProfilePic"
      ></profile-pic>
      <mat-icon
        class="settings-icon-button"
        [matBadge]="getBadge(newMessagesCount + newNotificationCount)"
        matBadgeColor="warn"
        matBadgeOverlap="true"
        >arrow_drop_down</mat-icon
      >
    </div>

    <mat-menu #desktopMenu="matMenu">
      <a mat-menu-item routerLink="/profile"
        ><mat-icon>person</mat-icon
        >{{ this.currentUser.firestore.displayName$ | async }}</a
      >
      <a routerLink="/messenger" mat-menu-item>
        <mat-icon
          class="action-icon"
          [matBadge]="getConversationBadge()"
          matBadgeColor="warn"
          >message</mat-icon
        >
        Conversations
      </a>
      <a routerLink="/notifications" mat-menu-item>
        <mat-icon
          class="action-icon"
          [matBadge]="getNotificationBadge()"
          matBadgeColor="warn"
          >notifications</mat-icon
        >
        Notifications
      </a>
      <a routerLink="/premium" mat-menu-item>
        <mat-icon
          class="action-icon"
          >workspace_premium</mat-icon
        >
        Premium
      </a>
      <button mat-menu-item [matMenuTriggerFor]="moreLinks">More links</button>
      <button (click)="this.currentUser.signOut()" mat-menu-item>
        Log out
      </button>
    </mat-menu>
    <mat-menu #moreLinks="matMenu">
      <a mat-menu-item routerLink="terms-and-conditions">Terms & Conditions</a>
      <a mat-menu-item routerLink="privacy-policy">Privacy Policy</a>
      <a mat-menu-item routerLink="guidelines">Daily Bids Guidelines</a>
      <!-- <button mat-menu-item (click)="openContact()">Contact Daily Bids</button> -->
    </mat-menu>
  </div>
  <div *ngIf="!this.currentUser.exists()" class="header-actions">
    <div class="nav-link nav-icon-text" (click)="openLogin()" id="nav-login">
      <!-- <mat-icon class="nav-link-child" style="padding-right: 5px" [inline]="true">person</mat-icon> -->
      <span class="nav-text nav-link-child">login</span>
    </div>
  </div>
</mat-toolbar>

<div id="navbar" *ngIf="isSmallScreen$ | async" class="nav-bar">
  <mat-toolbar>
    <mat-toolbar-row class="mob-row-1">
      <div class="mobile-branding">
        <a [routerLink]="['/']" class="nav-link">
          <span class="nav-link-child">{{ name }}</span>
        </a>
      </div>
      <span class="fill-space-toolbar" (click)="scrollToTop()"></span>
      <div class="mob-menu-bar-wrap">
        <div
          (click)="toggleMenuMobile()"
          class="nav-link nav-icon mob-menu-trigger"
          aria-label="settings"
        >
          <mat-icon
            style="color: white"
            [matBadge]="getBadge(newMessagesCount + newNotificationCount)"
            matBadgeColor="warn"
            matBadgeSize="small"
            >{{ mobileMenu ? "close" : "menu" }}</mat-icon
          >
        </div>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="mob-row-2">
      <div *ngIf="locIDIsSet(); else locationNotSetMobile" class="mobile-loc">
        <div
          (click)="resetLocation()"
          title="Current city for bidding (click to change)"
        >
          <div class="mobile-loc-name">{{ getDisplayCity() }}</div>
          <!-- <clr-icon shape="angle" dir="down"
                      class="mob-down-icon"></clr-icon> -->
        </div>
      </div>

      <ng-template #locationNotSetMobile>
        <form class="mobile-loc mob-loc-input">
          <input
            googleAutocomplete
            [formControl]="cityInputControl"
            [startAutocomplete]="switchToGoogleAutocomplete"
            name="location of sale"
            [options]="options"
            (onAddressChange)="handleAddressChange($event)"
            required
            style="color: gray; width: 100%"
            id="mobile-location-input"
            type="text"
            [matAutocomplete]="autoCity"
            placeholder="Select a city"
          />
          <mat-autocomplete
            (optionSelected)="handleCustomSelect($event)"
            #autoCity="matAutocomplete"
          >
            <mat-option
              *ngFor="let option of filteredAutocompleteOptions | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </form>
      </ng-template>
      <span class="fill-space-toolbar"></span>
      <form
        *ngIf="hasLocationSet()"
        class="search mobile-search"
        (submit)="searchFor(searchInput)"
        autocomplete="off"
      >
        <label for="search_input_mobile">
          <input
            style="width: 100%"
            id="search_input_mobile"
            type="text"
            placeholder="Search for products..."
            [(ngModel)]="searchInput"
            [ngModelOptions]="{ standalone: true }"
            [maxLength]="MAX"
          />
        </label>
      </form>
    </mat-toolbar-row>
  </mat-toolbar>

  <div *ngIf="mobileMenu && (isSmallScreen$ | async)" class="mob-menu">
    <div *ngIf="this.currentUser.exists()">
      <ul class="mobile-list">
        <!-- <li (click)="resetLocation()">Change location</li> -->
        <li *ngIf="hasLocationSet()" (click)="mobileBid()">Bid</li>
        <li *ngIf="hasLocationSet()" (click)="mobileSell()">Sell</li>
        <li (click)="goToProfile()">
          <mat-icon [inline]="true">person</mat-icon>
          {{ this.currentUser.firestore.displayName$ | async }}
        </li>

        <li (click)="mobileConversations()">
          <mat-icon
            [inline]="true"
            class="action-icon"
            [matBadge]="getConversationBadge()"
            matBadgeColor="warn"
            >message</mat-icon
          >
          Conversations
        </li>
        <!-- <li *ngIf="hasLocationSet()" (click)="mobileMessage()">Conversations</li> -->
        <li (click)="mobileNotifications()">
          <mat-icon
            [inline]="true"
            class="action-icon"
            [matBadge]="getNotificationBadge()"
            matBadgeColor="warn"
            matBadgeSize="small"
            >notifications</mat-icon
          >
          Notifications
        </li>
        <li (click)="mobilePremium()">
          <mat-icon
            [inline]="true"
            class="action-icon"
            >workspace_premium</mat-icon
          >
          Premium
        </li>
        <li (click)="openMoreLinks()">More Links</li>
        <li (click)="mobileLogout()">Log out</li>
      </ul>
    </div>
    <div *ngIf="!this.currentUser.exists()">
      <ul class="mobile-list">
        <!-- <li (click)="resetLocation()">Change location</li> -->
        <li *ngIf="hasLocationSet()" (click)="mobileBid()">Bid</li>
        <li *ngIf="hasLocationSet()" (click)="mobileSell()">Sell</li>
        <li (click)="openMoreLinks()">More Links</li>
        <li (click)="mobileLogin()">
          <!-- <clr-icon shape="user"></clr-icon> -->
          <span class="nav-text">Login</span>
        </li>
      </ul>
    </div>
  </div>
</div>

