import { Injectable, isDevMode} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';
import { GlobalConstants } from 'src/app/Global';
import { Subject, Observable } from 'rxjs';
import { UserService } from 'src/app/services/core/user.service';
import { Product } from 'shared/interfaces/product';
import { SharedConstants } from 'shared/constants';
import { ScreenSizeService } from '../../screen-size.service';
import { ProductProvider } from '../productProvider';
import { FirestoreError } from 'src/app/error-handler/customErrors';
import { ProductStoreService } from '../product-store.service';

@Injectable({
  providedIn: 'root'
})
export abstract class ByIdProviderService implements ProductProvider {

  protected idTimeStampDivider = SharedConstants.ID_TIME_DIVIDER;

  protected orderedIDs: string[] = [] // Product ids, used to send products incrementally as search results
  protected currentIndex: number = 0; // The most recent index or the last productID returned to compnonent

  protected MAXSEARCHRESULTS: number = 500; // Max number or products that will be returned from search results

  constructor(protected afs: AngularFirestore, protected currentUser: UserService, 
    protected screenSize: ScreenSizeService, protected store: ProductStoreService) { }

  /**
   * Get the first batch of products. 
   * This method does the initial ground work to also store product 
   * ids for future batches of products.
   * 
   * @returns first batch of products to display to user.
   */
  abstract getInitialProducts(): Observable<Product[]>

  /**
   * Get the next products to display to the user based on the recent search
   * 
   * @returns an observable of an array of products, size based on global constant
   */
  getNextProducts(lastTimeStamp): Observable<Product[]> {
    let nextProducts: Subject<Product[]> = new Subject();
    this.getNextProductBatch().then(prods => nextProducts.next(prods))
      .catch(e => {
        nextProducts.error(new Error(e));
        throw new FirestoreError(e, 2106);
      });
    return nextProducts;
  }

  protected resetGlobals() {
    this.orderedIDs = [];
    this.currentIndex = 0;
  }

  /**
   * Based on the global current index, and the global ids to return for this search hit, return the 
   * next batch of products
   * 
   * @returns an array for the next batch of products to display to the user
   */
  protected async getNextProductBatch(desktopBatchSize = GlobalConstants.PROD_BATCH_SIZE): Promise<Product[]> {
    const batchSize = (this.screenSize.isSmallScreen(GlobalConstants.MOBILE_SCREEN_THRESHOLD)) ?
      GlobalConstants.MOBILE_BATCH_SIZE : desktopBatchSize;
    const nextIndex = Math.min(this.currentIndex + batchSize, this.orderedIDs.length);
    const nextIDs = this.orderedIDs.slice(this.currentIndex, nextIndex);
    this.currentIndex = nextIndex;
    let products: Product[] = [];
    let deletedPromises = []
    const promises = nextIDs.map(async (id, index) => {
      if (this.store.hasProduct(id)) {
        products[index] = this.store.getProduct(id);
      } else {
      await this.afs.collection('cities').doc(this.getCity()).collection('products').doc<Product>(id)
        .valueChanges().pipe(
          tap(p => {
            if (p) {
              if(isDevMode()) console.log(p.id)
              products[index] = p;
            } else {
              deletedPromises.push(this.afs.collection('cities').doc(this.getCity())
                .collection('deletedProducts').doc<Product>(id).valueChanges().pipe(
                  tap(p => {
                    if (p) {
                      p['deleted'] = true
                    }
                    products[index] = p;
                  }),
                  take(1)
                ).toPromise())
            }
          }),
          take(1)
        ).toPromise().catch(err => {
          console.log("Couldn't find product")
          throw new FirestoreError(err, 2107);
        })
      }
    });

    try {
      await Promise.all(promises);
      await Promise.all(deletedPromises)
    } catch (e) {
      throw new FirestoreError(e, 2108);
    }
    // Return all products that are not null
    return products.filter(p => p);
  }

  // Should return the users current selected city
  protected getCity(): string {
    return this.currentUser.getLocation().id;
  }
}
