<div *ngIf="product">

  <ng-template #closeAndProductMenu>
    <div class="custom-modal-close" style="mix-blend-mode: difference;">
      <per-item-menu 
        *ngIf="!previewOnly && userSignedIn()"
        [allowShare]="true"
        [shareId]="product.id" 
        [product]="product" 
        [allowReportUser]="!isOwner()"
        [userIdToReport]="product.posterId"
        [allowReportProduct]="!isOwner()"
        [productIdToReport]="product.id" 
        [allowEdit]="isOwner()" 
        [allowDelete]="isOwner()" 
        (deleteEvent)="closeModal()"
      ></per-item-menu>
    <button mat-icon-button (click)="closeModal()" style="background-color: white;">
      <mat-icon>close</mat-icon>
    </button>
    </div>

  </ng-template>

  <div [id]="'prod-modal-' + product.id" class="cust-modal" *ngIf="showModal">
    <div *ngIf="(isSmallScreen$ | async)" class="cust-modal-close">
      <ng-template [ngTemplateOutlet]="closeAndProductMenu"></ng-template>
    </div>
    <div class="cust-modal-content" [id]="'prod-modal-' + product.id + '-content'">
      <div *ngIf="!(isSmallScreen$ | async)" class="cust-modal-close">
        <ng-template [ngTemplateOutlet]="closeAndProductMenu"></ng-template>
      </div>
      <div class="atn-btn-container" *ngIf="isOwner()" [class.atn-btn-container-small]="(isSmallScreen$ | async)">
        <div class="btn-group owner-atn-btns" *ngIf="previewOnly">
          <button
            mat-raised-button
            (click)="closeModal()"
          >
          <mat-icon>keyboard_backspace</mat-icon>
            Go back
          </button>
          <button mat-raised-button color="primary" (click)="emitPost()">
            <mat-icon>check</mat-icon>
            Post
          </button>
        </div>
        <div class="owner-atn-btns" *ngIf="!previewOnly">
          <button
            mat-raised-button
            color="primary"
            title="
              Bump this post and bring it to the top. Each posting is also brought to the top when someone bids on it.
            "
            (click)="bumpProduct()"
            [disabled]="!bumpAllowed()"
          >
            <mat-icon>keyboard_arrow_up</mat-icon>
            Bump ({{ remainingBumps() }})
          </button>
          <button mat-raised-button (click)="editProduct()">
            <mat-icon>create</mat-icon>
            Edit
          </button>
        </div>
      </div>
      <div class="row product">
        <div class="col-xs-12 col-md-6">
          <!-- Mobile images -->
          <div *ngIf="isSmallScreen$ | async; else desktopImageViewer">
            <!-- Full screen mobile images -->
            <div *ngIf="showFullImage" class="full-img-wrap">
              <div
                class="full-screen-img-invisible"
                (swipeLeft)="swipeImageLeft()"
                (swipeRight)="swipeImageRight()"
                (click)="toggleShowFullImage()"
              ></div>
              <img [src]="getImageUrl()" class="full-image-display" />
              <div class="img-dot-or-thumb-wrapper">
                <div
                  *ngFor="let img of product.images"
                  class="img-indicator-dot"
                  [class.active-img-dot]="isCurrentImage(img)"
                ></div>
              </div>
            </div>
            <!-- Normal mobile images -->
            <div
              (swipeLeft)="swipeImageLeft()"
              (swipeRight)="swipeImageRight()"
              (panup)="panUp($event)"
              (pandown)="pan($event)"
              (panend)="panEnd($event)"
              (swipeDown)="closeModal()"
              class="mobile-image-wrapper"
            >
              <!-- Preload images -->
              <img
                *ngIf="this.currentImageIndex < product.images.length - 1"
                [src]="getImageUrl(this.currentImageIndex + 1)"
                height="1"
                width="1"
                style="position:absolute"
              />
              <img
                *ngIf="this.currentImageIndex > 0"
                [src]="getImageUrl(this.currentImageIndex - 1)"
                height="1"
                width="1"
                style="position:absolute"
              />
              <img
                [src]="getImageUrl()"
                class="mobile-image-display"
                (click)="toggleShowFullImage()"
              />
              <div class="img-dot-or-thumb-wrapper">
                <div
                  *ngFor="let img of product.images"
                  class="img-indicator-dot"
                  [class.active-img-dot]="isCurrentImage(img)"
                ></div>
              </div>
            </div>
          </div>
          <!-- Desktop image viewer -->
          <ng-template #desktopImageViewer>
            <!-- Full screen desktop image viewer -->
            <div *ngIf="showFullImage" class="full-img-wrap" (click)="toggleShowFullImage()">
              <img
                [src]="getImageUrl()"
                class="full-image-display full-image-desktop"
                (swipeLeft)="swipeImageLeft()"
                (swipeRight)="swipeImageRight()"
              />
              <div class="img-dot-or-thumb-wrapper">
                <div
                  *ngFor="let img of product.images"
                  style="cursor: pointer;"
                >
                  <img
                    [lazyLoad]="getImageThumb(img)"
                    [errorImage]="getImageUrl(product.images.indexOf(img))"
                    [alt]="product.title"
                    class="bottom-full-thumb"
                    [class.selected-thumb]="isCurrentImage(img)"
                    (click)="setImage(img, $event)"
                    (mouseenter)="setImage(img)"
                  />
                </div>
                <div
                  class="full-screen-desktop-close"
                  (click)="toggleShowFullImage()"
                >
                  X
                </div>
              </div>
            </div>
            <!-- Normal desktop image viewer -->
            <div class="row image-container">
              <div class="col-xs-2">
                <div
                  *ngFor="let img of product.images"
                  class="thumb-wrapper"
                  style="cursor: pointer;"
                >
                  <img
                    [lazyLoad]="getImageThumb(img)"
                    [defaultImage]="defaultImg"
                    [errorImage]="getImageUrl(product.images.indexOf(img))"
                    [alt]="product.title"
                    class="side-thumb"
                    [class.selected-thumb]="isCurrentImage(img)"
                    (click)="setImage(img)"
                    (mouseenter)="setImage(img)"
                  />
                </div>
              </div>

              <div
                class="col-xs-10 primary-img-wrapper"
                style="cursor: pointer; text-align: center;"
                (click)="toggleShowFullImage()"
              >
                <!-- This is useful to put img in middle vertically <span class="primary-img-helper"></span> -->
                <img
                  [src]="getImageUrl()"
                  [alt]="product.title"
                  class="product-image"
                />
              </div>
            </div>
          </ng-template>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="row product-description">
            <div class="col-xs-12 col-sm-6 col-md-12">
              <h2 style="margin-bottom: 8px;">{{ product.title }}</h2>
                <user-signpost
                  [name]="product.posterName"
                  [userId]="product.posterId"
                  uid="owner"
                  [disabled]="!userSignedIn()"
                  [showContactInfo]="userVerified() && product.displayContactInfo"
                  [email]="product.displayContactInfo ? posterEmail : ''"
                  [phoneNumber]="product.displayContactInfo ? posterNumber : ''"
                  [showMessageButton]="showSignpostMessageButton"
                  [messageIds]="messageIds"
                  [userHasProfilePic]="product.hasProfilePic"
                  [includeProfilePic]="true"
                ></user-signpost>
              <div style="margin-top: 5px;">
                <mat-icon
                [inline]="true"
                  title="pick-up location"
                >place</mat-icon>
                {{ product.pickUpLocation }}
              </div>
              <div
                *ngIf="productInDifferentCity()"
                class="link-like"
                [title]="
                  'This product was posted in ' +
                  product.location +
                  '. Click to switch to this city.'
                "
                (click)="switchToCityOfProduct()"
              >
                {{ product.location }} 
                <mat-icon [inline]="true">refresh</mat-icon>
              </div>
              <div
                [matTooltip]="'Bidding ends ' + formatDateCust(getBidEndTime(product.uploadTime))"
                aria-haspopup="true"
              >
                <mat-icon [inline]="true">access_time</mat-icon>
                {{ product.uploadTime.toDate() | date: "dd MMM hh:mm:ss a" }}
              </div>
              <div *ngIf="productHasBeenEdited()">(edited on {{ product.editedTime.toDate() | date: "dd MMM hh:mm:ss a" }})</div>
              <div>Starting bid: ${{ product.startingBid }}</div>
              <div *ngIf="shouldToggleDescription(); else noToggleDescrip">
                <div *ngIf="showFullDescription; else showShortDescrip">
                  <p style="white-space: pre-wrap;">
                    {{ product.description }}
                    <span (click)="showFullDescription = false" class="link-like">show less</span>
                  </p>
                </div>
                <ng-template #showShortDescrip>
                  <p>
                    {{ shortenDescription(product.description) }}...
                    <span (click)="showFullDescription = true" class="link-like">show more</span>
                  </p>
                </ng-template>
              </div>
              <ng-template #noToggleDescrip>
                <p style="white-space: pre-wrap;">{{ product.description }}</p></ng-template
              >
            </div>
            <div
              *ngIf="gMapsUrl"
              style="margin-top: 10px;"
              class="col-xs-12 col-sm-6 col-md-12"
            >
              <div class="l-gmap" *ngIf="!(isSmallScreen$ | async); else smallMap">
                <iframe
                  sandbox="allow-same-origin allow-scripts allow-popups"
                  width="300"
                  height="150"
                  frameborder="0"
                  style="border: 0;"
                  [src]="gMapsUrl"
                  allowfullscreen
                >
                </iframe>
              </div>
              <ng-template #smallMap>
                <div class="s-gmap" id="smap" style="float: right;">
                  <iframe
                    sandbox="allow-same-origin allow-scripts allow-popups"
                    [width]="getMapWidth()"
                    height="125"
                    frameborder="0"
                    style="border: 0;"
                    [src]="gMapsUrl"
                    allowfullscreen
                  >
                  </iframe>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!previewOnly">
        <hr />
        <div class="bidding-wrapper">
          <bidding
          *ngIf="userSignedIn() && userVerified(); else promptSignInOrVerify"
          [product]="product"
          (suggestClose)="this.closeModal()"
        ></bidding>
        </div>
        <ng-template #promptSignInOrVerify>
          <div *ngIf="!userSignedIn()">
          Please sign in or register to bid
          <button (click)="userLogin()" mat-raised-button color="primary">Login to bid</button>
          </div>
          <div *ngIf="userSignedIn() && !userVerified()">
            Last step, please verify your email address
            <button (click)="userVerify()" mat-raised-button color="primary">Verify</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<loading *ngIf="loadingProduct"></loading>
