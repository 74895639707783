import { AfterViewInit, Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit, AfterViewInit {
  id = null;
  @Input() size: number = 200;

  constructor() {}

  getId() {
    if (!this.id) this.id = "logo-" + Math.round(Math.random() * 10000);
    return this.id;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    var hands = [];
    hands.push(document.getElementById("secondhand-" + this.getId()).firstChild);
    hands.push(document.getElementById("minutehand-" + this.getId()).firstChild);
    hands.push(document.getElementById("hourhand-" + this.getId()).firstChild);

    var cx = 100;
    var cy = 100;

    function shifter(val) {
      return [val, cx, cy].join(" ");
    }

    var date = new Date();
    var hoursAngle = (360 * date.getHours()) / 12 + date.getMinutes() / 2;
    var minuteAngle = (360 * date.getMinutes()) / 60;
    var secAngle = (360 * date.getSeconds()) / 60;

    // set the time
    hands[0].setAttribute("from", shifter(secAngle));
    hands[0].setAttribute("to", shifter(secAngle + 360));
    hands[1].setAttribute("from", shifter(minuteAngle));
    hands[1].setAttribute("to", shifter(minuteAngle + 360));
    hands[2].setAttribute("from", shifter(hoursAngle));
    hands[2].setAttribute("to", shifter(hoursAngle + 360));

    const handwrap = document.getElementById("hand-wrapper-" + this.getId());
    handwrap.classList.remove("hidden");
    handwrap.classList.add("fade-in");

    
    // add the little ticks
    for (var i = 0; i < 12; i++) {
      var el = document.createElementNS("http://www.w3.org/2000/svg", "line");
      el.setAttribute("x1", "100");
      el.setAttribute("y1", "40");
      el.setAttribute("x2", "100");
      el.setAttribute("y2", "50");
      el.setAttribute("transform", "rotate(" + (i * 360) / 12 + " 100 100)");
      el.setAttribute("style", "stroke: #184395;");
      el.setAttribute("class", "fade-in");
      document.getElementById(this.getId()).appendChild(el);
    }
  }
}
