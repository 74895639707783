import { AfterViewInit, Directive, Input, Output, EventEmitter, ElementRef, NgZone } from '@angular/core';

declare let google: any;

@Directive({
  selector: '[googleAutocomplete]',
  exportAs: 'custom-places'
})
export class GoogleAutocompleteDirective implements AfterViewInit {
  @Input('options') options: CustomCompleteOptions;
  @Output() onAddressChange: EventEmitter<Address> = new EventEmitter();
  @Input() set startAutocomplete(start: boolean) {
    if (start && !this.started) {
      this.initialize();
      this.started = true;
    }
  }
  private started = false;
  private autocomplete: any;
  private eventListener: any;
  public place: Address;

  constructor(private el: ElementRef, private ngZone: NgZone) {
  }

  ngAfterViewInit(): void {
      if (!this.options)
          this.options = new CustomCompleteOptions();

      // this.initialize();
  }

  private isGoogleLibExists(): boolean {
      return !(!google || !google.maps || !google.maps.places);
  }

  private initialize(): void {
      if (!this.isGoogleLibExists())
          throw new Error("Google maps library can not be found");

      this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);

      if (!this.autocomplete)
          throw new Error("Autocomplete is not initialized");

      if (!this.autocomplete.addListener != null) { // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
          this.eventListener = this.autocomplete.addListener('place_changed', () => {
              this.handleChangeEvent()
          });
      }

      this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
          if(!event.key) {
              return;
          }

          let key = event.key.toLowerCase();

          if (key == 'enter' && event.target === this.el.nativeElement) {
              event.preventDefault();
              event.stopPropagation();
          }
      });

      // according to https://gist.github.com/schoenobates/ef578a02ac8ab6726487
      if (window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
          setTimeout(() => {
              let containers = document.getElementsByClassName('pac-container');

              if (containers) {
                  let arr = Array.from(containers);

                  if (arr) {
                      for (let container of arr) {
                          if (!container)
                              continue;

                          container.addEventListener('touchend', (e) => {
                              e.stopImmediatePropagation();
                          });
                      }

                  }
              }
          }, 500);
      }
  }

  public reset(): void {
      this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);
      this.autocomplete.setTypes(this.options.types);
  }

  private handleChangeEvent(): void {
      this.ngZone.run(() => {
          this.place = this.autocomplete.getPlace();

          if (this.place) {
              this.onAddressChange.emit(this.place);
          }
      });
  }
}


export class ComponentRestrictions {
  public country: string;

  constructor(obj?: Partial<ComponentRestrictions>) {
      if (!obj)
          return;

      Object.assign(this, obj);
  }
}

export class CustomCompleteOptions {
  public bounds: LatLngBounds;
  public componentRestrictions: ComponentRestrictions;
  public types: string[];
  public fields: string[];
  public strictBounds: boolean;
  public origin: LatLng;
  public constructor(opt?: Partial<CustomCompleteOptions>) {
      if (!opt)
          return;

      Object.assign(this, opt);
  }
}

export class Address {
  address_components: AddressComponent[];
  adr_address: string;
  formatted_address: string;
  formatted_phone_number: string;
  html_attributions: string[];
  icon: string;
  id: string;
  international_phone_number: string;
  name: string;
  permanently_closed: boolean;
  place_id: string;
  price_level: number;
  rating: number;
  types: string[];
  url: string;
  utc_offset: number;
  vicinity: string;
  website: string;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface LatLng {
  /** Comparison function. */
  equals(other: LatLng): boolean;
  /** Returns the latitude in degrees. */
  lat(): number;
  /** Returns the longitude in degrees. */
  lng(): number;
  /** Converts to string representation. */
  toString(): string;
  /** Returns a string of the form "lat,lng". We round the lat/lng values to 6 decimal places by default. */
  toUrlValue(precision?: number): string;
  /** Converts to JSON representation. This function is intended to be used via JSON.stringify. */
  toJSON(): LatLngLiteral;
}
export type LatLngLiteral = { lat: number; lng: number }
export type LatLngBoundsLiteral = { east: number; north: number; south: number; west: number }

  
/**
 * A LatLngBounds instance represents a rectangle in geographical coordinates, including one
 * that crosses the 180 degrees longitudinal meridian.
 */

export interface LatLngBounds {
    /** Returns true if the given lat/lng is in this bounds. */
    contains(latLng: LatLng | LatLngLiteral): boolean;

    /** Returns true if this bounds approximately equals the given bounds. */
    equals(other: LatLngBounds | LatLngBoundsLiteral): boolean;

    /** Extends this bounds to contain the given point. */
    extend(point: LatLng | LatLngLiteral): LatLngBounds;

    /** Computes the center of this LatLngBounds */
    getCenter(): LatLng;

    /** Returns the north-east corner of this bounds. */
    getNorthEast(): LatLng;

    /** Returns the south-west corner of this bounds. */
    getSouthWest(): LatLng;

    /** Returns true if this bounds shares any points with the other bounds. */
    intersects(other: LatLngBounds | LatLngBoundsLiteral): boolean;

    /** Returns if the bounds are empty. */
    isEmpty(): boolean;

    /** Converts to JSON representation. This function is intended to be used via JSON.stringify. */
    toJSON(): LatLngBoundsLiteral;

    /** Converts the given map bounds to a lat/lng span. */
    toSpan(): LatLng;

    /** Converts to string. */
    toString(): string;

    /**
     * Returns a string of the form "lat_lo,lng_lo,lat_hi,lng_hi" for this bounds, where "lo" corresponds to the
     * southwest corner of the bounding box, while "hi" corresponds to the northeast corner of that box.
     */
    toUrlValue(precision?: number): string;

    /** Extends this bounds to contain the union of this and the given bounds. */
    union(other: LatLngBounds | LatLngBoundsLiteral): LatLngBounds;
}