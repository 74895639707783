import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app'
import { CurrentUserError, ApplicationError, FirestoreError } from '../error-handler/customErrors';
import { FireStoreUser } from './fireStoreUser';


/**
 * This is the user handler that acts as an intermediate wrapper around the FireStoreUser object. 
 * This is where a lot of the `metaprogramming` happens. Whenever a property is called on the FireStoerUser object
 * the UserHandler's get function is called to determine if the property should exist and how to deal with it. 
 * So far the following methods are available for all properties defined in FireStoreUserI: 
 *      update`PropertyName`
 *      clear`PropertyName`
 *      increment`PropertyName` (only for number properties, throws an error if called on non-number property)
 *      push`PropertyName`  (only for list/array properties, throws an error if called on non-array/non-list property)
 *      removeItemFrom`PropertyName`  (only for list/array properties, throws an error if called on non-array/non-list property)
 *      `propertyName$`
 */
export class UserHandler{

    static handler = {

        /* This function is called (and intercepts with FireStoreUser object) when a property is called on a FireStoreUser 
            For example; if code calls FireStoreUser.age then the parameter name will equal 'age'. 
            On the fly (metaprogramming) we can define different properties and method for the FireStoreUser object.
        */
        get: function(user:any, name) {
            if(user.hasOwnProperty(name)){
                return user[name];
            }
            if(user.publicData.hasOwnProperty(name)){
                return user.publicData[name];
            }
            if(user.privateData.hasOwnProperty(name)){
                return user.privateData[name];
            }
            if(name == 'update'){
                return function(userhash){
                    return user.update(userhash);
                }
            }
            if(name == 'destroy'){
                return function(){
                    return user.destroy();
                }
            }

            //Observable!
            if(name[name.length - 1] == '$'){
                let prop = name.substring(0,name.length - 1);
                let observable = user.getObservable(prop);
                if(observable){
                    //Define a property so that next time it is called it is memoized and will be returned by above code as `user.hasOwnProperty(name)` will evaluate true.  
                    Object.defineProperty(user, name, {
                        value: observable.pipe(map(user => { 
                            return user[prop];
                        })), 
                        writable: true,
                        enumerable: true, //So only user properties are listed when enumerating with for loop.
                    })
                    return user[name]; 
                }
            }

            let functionNames = ['update','increment','push', 'removeItemFrom']; //Defined property method calls. 
            for (let [idx, funcname] of functionNames.entries()) { 
                let namesplit = name.split(funcname);
                if(namesplit.length > 1){
                    let callProp = namesplit[1].substr(0, 1).toLowerCase() + namesplit[1].substr(1); 
                    if(callProp in user.publicData || callProp in user.privateData){
                        let docRef = user.getUserRef(callProp);
                        let propValue = user.getValue(callProp);
                        //TODO add conditional to allow creation of nonexistant user properties like the arrap pusha dn removeItemFrom
                        switch(idx){
                            case 0: { //Update
                                return function(newVal) {
                                    if((typeof propValue != typeof newVal)) throw new CurrentUserError(`Inconsistent types: Tried to update user property->${callProp}:${typeof propValue} to val->${newVal}:${typeof newVal}!`, 6000); 
                                    const data: { [k: string]: any} = {};
                                    data[callProp] = newVal; 
                                    return docRef.set(data, { merge: true })
                                    .catch(error => { 
                                        throw new FirestoreError(`FireStore Couldn't Update ${callProp} In Userhandler: ${error.message}`, 2011);
                                    }); 
                                }
                            }
                            case 1: { //Increment
                                return function() {
                                    if(typeof propValue != 'number') throw new CurrentUserError("Tried to Increment a non-number property!", 6001);
                                    const data: { [k: string]: firebase.default.firestore.FieldValue } = {}; 
                                    data[callProp] = firebase.default.firestore.FieldValue.increment(1); 
                                    return docRef.set(data, { merge: true })
                                    .catch(error => { throw new FirestoreError(`FireStore Couldn't Increment To ${callProp} Number in UserHandler: ${error.message}`, 2012)}); 
                                }
                            }
                            case 2: { //Push
                                return function(newVal) {
                                    if(propValue && !(propValue instanceof Array)) throw new CurrentUserError("Tried to push to a non-array property!", 6002); 
                                    const data: { [k: string]: firebase.default.firestore.FieldValue } = {}; 
                                    data[callProp] = firebase.default.firestore.FieldValue.arrayUnion(newVal); 
                                    return docRef.set(data, { merge: true })
                                    .catch(error => { throw new FirestoreError(`FireStore Couldn't Push To ${callProp} Array In UserHandler: ${error.message}`, 2013)}); 
                                }
                            }
                            case 3: { //removeItemFrom
                                return function(removeVal) {
                                    if(propValue && !(propValue instanceof Array)) throw new CurrentUserError("Tried to remove item from a non-array property!", 6003); 
                                    const data: { [k: string]: firebase.default.firestore.FieldValue } = {}; 
                                    data[callProp] = firebase.default.firestore.FieldValue.arrayRemove(removeVal); 
                                    return docRef.set(data, { merge: true })
                                    .catch(error => { throw new FirestoreError(`FireStore Couldn't remove item from ${callProp} Array In UserHandler: ${error.message}`, 2014)}); 
                                }
                            }
                        }
                        
                    }
                }
            }

            throw new CurrentUserError(`Tried to access a user property that doesn't exist: ${name}`, 6004);
        
        },

        /* Fail fast if you try to set a property; see error message  */ 
        set: function(user:any, name, val) {
            throw new CurrentUserError("Can't set directly to firestore user data, use: update'PropertyName' instead", 6005)
        }
    }
  }

