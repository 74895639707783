import { Component, OnInit, ViewChild } from "@angular/core";
import { SearchProviderService } from "src/app/services/productProviders/byIdProviders/search-provider.service";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { GlobalConstants } from "src/app/Global";
import { UserService } from "src/app/services/core/user.service";
import { ProductsComponent } from "../../../products/products.component";

@Component({
  selector: "search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
})

// Component to display the search results
export class SearchResultsComponent implements OnInit {
  @ViewChild(ProductsComponent) products: ProductsComponent;

  searchTerm: string;
  ready = false;

  constructor(
    public provider: SearchProviderService,
    private route: ActivatedRoute,
    private currentUser: UserService,
    private title: Title
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => this.setSearchTerm(params));
  }

  setSearchTerm(params) {
    this.ready = false;
    const srch = params["search"];
    if (srch && srch.length > 2) this.searchTerm = srch;
    this.title.setTitle(srch + " - " + GlobalConstants.NAME);
    this.provider.setSearchWords(srch);

    setTimeout(() => (this.ready = true), 1);
  }

  isPremium(){
    return this.currentUser.isPremium();
  }

  getCity() {
    return this.currentUser.getCityName();
  }
}
