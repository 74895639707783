export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAmI75fgWtLScLsXxn6sBtr7qGlDssTG3c",
    authDomain: "daily-bids.firebaseapp.com",
    projectId: "daily-bids",
    storageBucket: "daily-bids.appspot.com",
    messagingSenderId: "739177877246",
    appId: "1:739177877246:web:5875a8ba5443d0cb305faf",
    measurementId: "G-G0VL1SKZ0T",
  },
  gcpKey: "AIzaSyAmI75fgWtLScLsXxn6sBtr7qGlDssTG3c"
};
