import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { LoadingSpinnerService } from "src/app/services/loading-spinner.service";

@Component({
  selector: "loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit, OnDestroy {
  active: boolean = false;
  private id: number;
  sub: Subscription;

  // set to false if you don't want loader to fade in
  @Input() fadeIn = true;

  constructor(private service: LoadingSpinnerService) {}

  ngOnInit() {
    let info = this.service.addSpinner();
    this.id = info[0];
    this.sub = info[1].subscribe((res) => {
      this.active = res;
      setTimeout(() => {
        if (this.active) {
          this.active = false;
          this.ngOnDestroy();
        }
      }, 4000);
    });
  }

  ngOnDestroy() {
    this.service.removeSpinner(this.id);
    if (this.sub) this.sub.unsubscribe();
  }
}
