import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedConstants } from 'shared/constants';
import { GoogleLocation } from 'shared/location';
import { UserService } from '../services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class LocationGuard implements CanActivate {

  constructor(private currentUser: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.currentUser.hasSetLocation()) return true;

      const location: string = next.queryParams.location;

      // if location is embedded in url, set city and allow access
      if (location && location.includes(SharedConstants.LOCATION_ID_SPLITTER)) {
        const id = location.split(SharedConstants.LOCATION_ID_SPLITTER)[1];
        const name = location.split(SharedConstants.LOCATION_ID_SPLITTER)[0];
        this.currentUser.changeLocation(
          new GoogleLocation(id, name),
          true,
          true
        )
        return true;
      };

      // this should be a product id
      const id = next.params.id;

      if (id && id.length > 1) {
        // still show product if users first time and the go to direct link
        this.router.navigate(['home/' + id]);
      } else {
        this.router.navigate(['home/']);
      }

      return false;
  }
  
}
