<mat-grid-list [cols]="getCols()" rowHeight="60" class="social-btn-list">
    <mat-grid-tile *ngIf="showGoogle">
      <button
        mat-mini-fab
        aria-label="google sign-in button"
        color="white"
        (click)="googlePress.emit($event)"
      >
        <mat-icon svgIcon="Google" style="margin-bottom: 3px"></mat-icon>
      </button>
    </mat-grid-tile>

    <mat-grid-tile *ngIf="showFacebook">
      <button
        mat-mini-fab
        aria-label="facebook sign-in button"
        (click)="facebookPress.emit($event)"
        color="blue"
      >
        <mat-icon class="social-button-resize" svgIcon="Facebook" style="margin-bottom: 3px;"></mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>