import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[appPassword]",
})
export class AppPasswordDirective {
  private show: boolean = false;
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.setup();
  }

  setup() {
    let parent = this.el.nativeElement.parentNode;
    let div = document.createElement("div");
    let input = this.el.nativeElement;
    div.innerHTML =
      "<mat-icon id='eye'  role='img' style='cursor: pointer; position: absolute; top: -4px; right: 0px; padding: 7px; 10px' " +
      "class='mat-icon notranslate material-icons mat-icon-no-color mat-icon-inline'>visibility</mat-icon>" +
      "<mat-icon matSuffix id='eyeHide' role='img' style='cursor: pointer; position: absolute; top: -7px; right: -2px; padding: 10px;'" +
      " class='mat-icon-inline mat-icon notranslate material-icons mat-icon-no-color'>visibility_off</mat-icon>";
    div.style.position = "relative";

    let eye = div.firstChild;
    let eyeHide = div.children[1];
    parent.insertBefore(div, input);
    div.removeChild(eyeHide);
    parent.removeChild(input);
    div.insertBefore(input, eye);

    [eye, eyeHide].forEach((element) => {
      element.addEventListener("click", (event) => {
        let icon: any = event.target;
        let id = icon.id;
        div.removeChild(icon);
        if (id == "eye") div.appendChild(eyeHide);
        else div.appendChild(eye);
        this.toggle(input);
      });
    });
  }

  toggle(input) {
    this.show = !this.show;
    input.type = this.show ? "text" : "password";
  }
}
