import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatExpansionModule } from '@angular/material/expansion';

import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { AppPasswordDirective } from './directives/app-password.directive';
import { GoogleAutocompleteDirective } from './directives/google-autocomplete.directive';

import { VerifyEmailComponent, VerifyEmailDialog } from './components/auth-dialogs/verify-email/verify-email.component';
import { UserSignpostComponent } from './components/user-signpost/user-signpost.component';
import { DeleteProfilePicDialog, EditProfilePicDialog, ProfilePicComponent } from './components/profile-pic/profile-pic.component';
import { ReferralLinkComponent } from './components/referral-link/referral-link.component';
import { PremiumDialog } from './components/premium-dialog/premium-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AlertComponent } from './components/alert/alert.component';
import { BiddingComponent } from './components/products/bidding/bidding.component';
import { TimerBlockComponent } from './components/products/display-products/timer/timer-block.component';
import { ProductCardComponent } from './components/products/display-products/product-card/product-card.component';
import { DisplayProductsComponent } from './components/products/display-products/display-products.component';
import { SearchResultsComponent } from './components/products/results/search-results/search-results.component';
import { DeleteProductDialog, ProductDetailsComponent } from './components/products/display-products/product-details/product-details.component';
import { UserBidsResultsComponent } from './components/products/results/user-bids-results/user-bids-results.component';
import { UserPostResultsComponent } from './components/products/results/user-post-results/user-post-results.component';
import { ProductsComponent } from './components/products/products.component';
import { LogoComponent } from './components/logo/logo.component';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { RouterModule } from '@angular/router';
import { ReportFormDialog } from './components/report-form/report-form.component';
import { MatMenuModule } from '@angular/material/menu';
import { PerItemMenuComponent } from './components/per-item-menu/per-item-menu.component';
import { SocialAuthListComponent } from './components/social-auth-list/social-auth-list.component';
import { LoginDialog } from './components/auth-dialogs/login/login-dialog.component';
import { RegisterDialog } from './components/auth-dialogs/login/register-dialog.component';
import { LoadingIconComponent } from './components/loading/loading-icon/loading-icon.component';
import { WinningBidComponent } from './components/products/bidding/winning-bid/winning-bid.component';


@NgModule({
  declarations: [
    DragAndDropDirective,
    AppPasswordDirective,
    GoogleAutocompleteDirective,
    LoginDialog,
    RegisterDialog,
    VerifyEmailComponent,
    VerifyEmailDialog,
    UserSignpostComponent,
    DeleteProfilePicDialog,
    EditProfilePicDialog,
    ProfilePicComponent,
    ReferralLinkComponent,
    PremiumDialog,
    LoadingComponent,
    AlertComponent,
    BiddingComponent,
    TimerBlockComponent,
    ProductCardComponent,
    ProductDetailsComponent,
    DisplayProductsComponent,
    SearchResultsComponent,
    DeleteProductDialog,
    UserBidsResultsComponent,
    UserPostResultsComponent,
    ProductsComponent,
    LogoComponent,
    WithLoadingPipe,
    ReportFormDialog,
    PerItemMenuComponent,
    SocialAuthListComponent,
    LoadingIconComponent,
    WinningBidComponent
  ],
  exports: [
    DragAndDropDirective,
    AppPasswordDirective,
    GoogleAutocompleteDirective,
    LoginDialog,
    RegisterDialog,
    VerifyEmailComponent,
    VerifyEmailDialog,
    UserSignpostComponent,
    DeleteProfilePicDialog,
    EditProfilePicDialog,
    ProfilePicComponent,
    ReferralLinkComponent,
    PremiumDialog,
    LoadingComponent,
    AlertComponent,
    BiddingComponent,
    TimerBlockComponent,
    ProductCardComponent,
    ProductDetailsComponent,
    DisplayProductsComponent,
    SearchResultsComponent,
    DeleteProductDialog,
    UserBidsResultsComponent,
    UserPostResultsComponent,
    ProductsComponent,
    LogoComponent,
    WithLoadingPipe,
    PerItemMenuComponent,
    SocialAuthListComponent,
    LoadingIconComponent
  ],
  imports: [
    CommonModule,
    MatGridListModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    LazyLoadImageModule,
    ImageCropperModule,
    ClipboardModule,
    MatCardModule,
    MatTooltipModule,
    MatExpansionModule,
    InfiniteScrollModule,
    RouterModule, // needed for routerLink to work in shared components
    MatMenuModule
  ]
})
export class ReusableModule { }
