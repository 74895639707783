import { Injectable, isDevMode } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take, catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GoogleLocation } from 'shared/location';
import * as firebase from 'firebase/app'
import { GlobalConstants } from 'src/app/Global';
import { AngularFireStorage } from '@angular/fire/storage';
import { PublicFireStoreUser } from 'shared/interfaces/fireStoreUserI';
import { AuthError, FirestoreError, CurrentUserError, ApplicationError } from 'src/app/error-handler/customErrors';
import { ErrorService } from '../error.service';
import { FireStoreUser } from 'src/app/objects/fireStoreUser';
import { Conversation } from 'src/app/objects/conversation';
import { Product } from 'shared/interfaces/product';
import { ConversationFirestore  } from 'shared/interfaces/conversation';
import { SharedConstants } from 'shared/constants';
import { NotificationService } from '../notification.service';
import { LocationService } from './location.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { DialogService } from '../dialog.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Note the general consistency of this class relies on asynchronous functions that return a Promise<boolean>.
 * The unofficial contract with the client of this code is that the client will display to the frontend the successes.
 * And the errors are caught and dealt with in this code. 
 */
export class UserService {

  GOOGLEID:string = 'google.com';
  PASSWORDID:string = 'password';
  CITY_MAX_LEN = 20;

  public refer_id:string; //locally cache the refferer's id in this service  

  private currentAuthUser:firebase.default.User; //This is the User that is updated via the Observable -> AngularFireAuthuser. 
  private displayName_from_email_registration:string; //The DisplayName of the user saved when the user registers with 'EmailPassword' registration.
  private send_email_from_email_registration:boolean = false; //Determined and set in registerEmailPassword

  private userSubs: Subscription[] = [];

  private tabCacheKey = GlobalConstants.CACHE_PREFIX + "profile_active_tab_";

  private setLocationSubject = new BehaviorSubject<boolean>(false);
  public setLocation$ = this.setLocationSubject.asObservable();
  private hasCurrentLocation: boolean = false;
  private cityCacheKey = GlobalConstants.CACHE_PREFIX + "current_city_";
  private location: GoogleLocation = this.getInitialCity();
  
  private errorMessageSubject = new BehaviorSubject<string>("");
  public errorMessage$ = this.errorMessageSubject.asObservable();

  private openVerificationSubject = new BehaviorSubject<boolean>(false);
  public verificationToggle$ = this.openVerificationSubject.asObservable();

  private verificationMessageSubject = new BehaviorSubject<string>("");
  public verificationMessage$ = this.verificationMessageSubject.asObservable();

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router,
    private _error: ErrorService, private toastr: ToastrService, private notificationService: NotificationService,
    private storage: AngularFireStorage, private locationServ: LocationService, private analytics: AngularFireAnalytics,
    private serializer: UrlSerializer, private dialogs:DialogService) { 
    this.setDefaultLocation();
    this.afAuth.user.subscribe(authUser => {
      this.currentAuthUser = authUser; 
      //Only set the user info upon login not logout.
      if(authUser != null && this.firestore == null){
            this.onSignIn(authUser.uid);
            this.userInitializedSubject.next(true);
      } else if (authUser != null) {
        isDevMode() && console.log("Re-Auth");
      } else{
        this.userInitializedSubject.next(true);
        if(this.refer_id && this.refer_id.length > 0){
          let toast;
          if(this.refer_id === GlobalConstants.TAMMY_CODE){
            const id = "ChIJWUiIUPEzhFQR45YePnaDRAA";
            const name = "Mission, BC, Canada";
            this.changeLocation(new GoogleLocation(id, name), true, true, true);
            toast = toastr.info("Looks like you are coming from Facebook's Mission Bidding Wars page. We hope you love Daily Bids!", "Welcome to Daily Bids!", { timeOut: 9500 })
          }else{
            toast = toastr.info("Create an account and sell an item to get Daily Bids Premium!", "**Referral Code Detected**", { timeOut: 9500 });
          }
          toast.onTap.pipe(take(1)).subscribe(() => this.dialogs.openRegister());
        }
        if(isDevMode()) console.log("AuthStateChanged: LoggedOut" );
      }
    }); 
  }

  /**
   * This is where setup tasks go when the user signs and the firestore user document exists. 
   * @param uid uid of signed in user. 
   */
  private onSignIn(uid){
    // wait for user doc from firestore
    this.firestoreInitializedSubject.next(false);
    this.firestore = new FireStoreUser(uid, this.afs, this.displayName_from_email_registration, this.refer_id);

    this.userSubs.push(this.firestore.initialized.subscribe(init => {
      // once public doc and display name are set, this init should be true
      if (init) {
        // set timeout to make sure display name has time to be set
        setTimeout(() => {
          this.firestoreInitializedSubject.next(true)
          if(!this.emailVerified()){
            if(this.send_email_from_email_registration) this.sendVerificationLink();
            this.send_email_from_email_registration = false;
            this.openVerification(); 
          }
          //Request notifications
          this.notificationService.requestNotificationPermission(this.firestore);
    
          this.googleEmailSubject.next(this.accountEmail(this.GOOGLEID)); 
          this.passwordEmailSubject.next(this.accountEmail(this.PASSWORDID));
        }, 1)
      }
    }))

    this.displayName_from_email_registration = null;
    this.notificationService.subscribe(uid); //TODO change this so that we don't have to subscribe to notifications here. -bm
    // TODO The next 4 lines should be exist in the message service with uid as parameters
    // but this causes cyclical dependency injection since product search services inject currentUser and auth service and message service injects search product service. 
    // in my opinion, the product, message, notification service (and other services like that) should not inject auth or currentUser service, but require the client to pass in that info. -bm
    this.userSubs.push(this.afs.collectionGroup<ConversationFirestore>('conversations', (ref) => ref.where('participants', 'array-contains', uid).orderBy("lastMessageSent", "desc")).valueChanges()
    .pipe(map(convoList => { return convoList.map(c => new Conversation(c, uid)); }), catchError(e => {throw new FirestoreError(e, 2021)})).subscribe(convos => {
      this.conversationsSubject.next(convos);
    }));
  }

  /* FIRESTORE USER PROPERTY OBJECT */
  public firestore:any = null; //This is the proxy object that will describe all properties and actions for firestore data
  
  // Use only when user is first discovered
  private userInitializedSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userInitialized$:Observable<boolean> = this.userInitializedSubject.asObservable();
  private userIsInitialized:boolean = false;

  private firestoreInitializedSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private firestoreInitialized$:Observable<boolean> = this.firestoreInitializedSubject.asObservable();

  private googleEmailSubject:BehaviorSubject<string> = new BehaviorSubject<string>("");
  private googleEmail$:Observable<string> = this.googleEmailSubject.asObservable();

  private passwordEmailSubject:BehaviorSubject<string> = new BehaviorSubject<string>("");
  private passwordEmail$:Observable<string> = this.passwordEmailSubject.asObservable();

  private conversationsSubject:BehaviorSubject<Conversation[]> = new BehaviorSubject<Conversation[]>([]);
  private convos$:Observable<Conversation[]> = this.conversationsSubject.asObservable();

  /**
  * Get the most recent message of conversation and keep listening. 
  * @param convoId conversation id. 
  */
  public totalUnreadConversationCount$():Observable<number>{
    return this.convos$.pipe(map(convoArr => {
      if(convoArr.length==0) return 0; 
      return convoArr.map(c => c.unReadMessages).reduce((unread1,unread2) => unread1 + unread2); 
    }), catchError(e => {throw new CurrentUserError("Error reducing Convos: " + e, 6006)}));
  }


  public creationTime():string|null{
    try{
      if(this.exists())
        return this.currentAuthUser.metadata.creationTime;
      else return null; 
    }catch(e){
      throw new CurrentUserError("Error getting creationTime: " + e, 6007)
    }
  }

  /**
   * Create a referral link based on the current user.
   * By default the refer parameter is removed is the user is not signed in
   * 
   * @param domain optional parameter to force link domain (ex: "https://dailybids.ca")
   */
  public createReferralLink(domain=window.location.host): string {
    let prefix = "";
    if (!domain.includes("http") && !domain.includes("localhost")) {
      prefix = "https://"
    }
    let userId = this.uid();
    let current_location = this.getLocation();
    let location = current_location.name+SharedConstants.LOCATION_ID_SPLITTER+current_location.id;
    const tree = this.router.createUrlTree([''], { queryParams: { refer: userId, location: location },});
    let url = prefix + domain + this.serializer.serialize(tree); 
    return url;
  }

  /**
   * EmailVerified tells you whether the currentUser has verified their email. This is always true for google auth signin method,
   * but if there is also an *unverified* email/password signin method linked then this will return false. 
   */
  public emailVerified():boolean|null{
    try{
      if(this.exists())
        return this.currentAuthUser.emailVerified; 
      else return null; 
    }catch(e){
      throw new CurrentUserError("Error getting emailVerified: " + e, 6008)
    }
  }
  
  public uid():string|null{
    try{
      if(this.exists())
        return this.currentAuthUser.uid; 
      else{ 
        return null; 
      }
    }catch(e){
      throw new CurrentUserError("Error getting uid: " + e, 6009)
    }
  }

  public conversations$():Observable<Conversation[]>{
    return this.convos$; 
  }

  public conversation$(convoId:string):Observable<Conversation>{
    try{
      return this.convos$.pipe(map(convos => { 
        let filteredConvos = convos.filter(convo => convo.id === convoId);
        return filteredConvos.length > 0 ? filteredConvos[0] : null;
      } ), take(1))
    }catch(e){
      throw new CurrentUserError(`Error getting conversationID : ${convoId} :`  + e, 6010)
    }
  }

  public uid$():Observable<string>{
    try{
      return this.afAuth.authState.pipe(map(user => user ? user.uid : null)); 
    }catch(e){
      throw new CurrentUserError(`Error getting uid observable: ${e}`, 6011)
    }
  }

  public hasGoogleSignIn():boolean{
    return this.accountEmail(this.GOOGLEID) != ''; 
  }

  public hasPasswordSignIn():boolean{
    return this.accountEmail(this.PASSWORDID) != ''; 
  }

  public userIsInitialized$(): Observable<boolean> {
    return this.userInitialized$;
  }

  public firestoreIsInitialized$(): Observable<boolean> {
    return this.firestoreInitialized$;
  }

  public googleAccountEmail$():Observable<string>{
    return this.googleEmail$; 
  }

  public passwordAccountEmail$():Observable<string>{
    return this.passwordEmail$; 
  }

  /**
   * Helper method to get the email of a specific account 
   * @param account the current signin methods we support
   */
  private accountEmail(account:string):string{
    try{
      if(this.exists()){
        let pd = this.currentAuthUser.providerData;
        if(pd[0].providerId == account) return pd[0].email; 
        if(pd.length > 1) return pd[1].email; 
        return "";
      }
      return "";
    }catch(e){
      throw new CurrentUserError(`Error getting accountEmail: ${e}`, 6012)
    }
  }

  //Helper method for update email and update password
  //Reauthenticates the user with only the password
  public reAuthWithPassword(password:string):Promise<firebase.default.auth.UserCredential>{
    let authCred = firebase.default.auth.EmailAuthProvider.credential(this.accountEmail(this.PASSWORDID), password);
    return this.currentAuthUser.reauthenticateWithCredential(authCred)
    .catch(error => {
      throw new AuthError(error, 4020);
    });
  }

  //Reauthenticates the user with google
  public reAuthWithGoogle():Promise<firebase.default.auth.UserCredential>{
    return this.currentAuthUser.reauthenticateWithPopup(new firebase.default.auth.GoogleAuthProvider())
    .catch(error => {
      if(error.code == GlobalConstants.AUTH_ERRORS.MISMATCH)
        this.toastr.error(error.message);
      throw new AuthError(error, 4019);
    });
  }
  
  async updatePassword(newPassword:string, oldPassword?:string):Promise<any>{
    if(this.exists()){
      if(oldPassword){
        return this.reAuthWithPassword(oldPassword).then(userCred => {
            return userCred.user.updatePassword(newPassword)
            .catch(error => { throw new AuthError(error, 4018) })
          })
          .catch(error => { throw new AuthError(error, 4017) }); 
        }else{
          return this.currentAuthUser.updatePassword(newPassword)
          .catch(error => { throw new AuthError(error, 4016) })
        }
    }
  }

  async updateEmail(newemail:string, password?:string):Promise<any>{
    if(this.exists()){
      if(password){
        return this.reAuthWithPassword(password).then(userCred => {
          return userCred.user.updateEmail(newemail).then(() => {
              this.passwordEmailSubject.next(this.accountEmail('password'));
            }, error => {throw new AuthError(error, 4015)});
        })
      }else{
        return this.currentAuthUser.updateEmail(newemail).then(() => {
          this.passwordEmailSubject.next(this.accountEmail('password'));
        }, error => {throw new AuthError(error, 4014)});
      }
    }
  }

  async confirmVerified():Promise<boolean>{
    await firebase.default.auth().currentUser.getIdToken(true); // this refreshed auth token so that user can immediately download
    // firestore documents that are blocked with security (emailVerified())
    return firebase.default.auth().currentUser.reload().then(() => {
      if(this.currentAuthUser.emailVerified){
        this.toastr.success("Love, Daily Bids Team","Thanks for verifying!")
        return true; 
      }else{
        this.toastr.error("Love, Daily Bids Team","Oops! Looks like you still need to verify your email! Please try again.") 
        return false; 
      }
    })
    .catch(e => {throw new FirestoreError(e, 2022)})
  }

  async reload():Promise<any>{
    return this.currentAuthUser.reload()
    .catch(error=> {
      throw new AuthError(error, 4013); 
    })
  }

  /**
   * Helper method to know if currentUser is currently using the premium version or not. 
   * @returns boolean if the user is premium or free version
   */
  public isPremium(){
    return this.exists() && this.firestore.premiumDaysLeft > 0; 
  }

  /**
   * Signout from firebase auth and remove notification token.
   */
  async signOut():Promise<boolean>{
    try{
      let token = await this.notificationService.getToken();
      if(token && this.firestore && this.firestore.notificationTokens)
        await this.firestore.removeItemFromNotificationTokens(token)
    }catch(error){
      // Need to catch this error early, as this prevents users from being able to logout.  
      if(error instanceof ApplicationError && isDevMode()) console.log(error);
    }
    return this.afAuth.signOut().then(() => {
      this.clear();
      this.router.navigate(['home/']);
      this._error.clearAuthMessage();
      return true; 
    })
    .catch(e => {
      throw new AuthError(e, 4021, false)
    });
  }
  
  public exists():boolean{
    return this.currentAuthUser && this.firestore && this.userIsInitialized && this.firestore.displayName !== "";
  }

  async addProductIdToBids(product: Product):Promise<boolean>{
    try{
      if (this.exists()) {
        let idAndTime = product.id + SharedConstants.ID_TIME_DIVIDER + product.uploadTime.seconds;
        return this.firestore.pushBids(idAndTime); 
      }
    }catch(e){
      throw new CurrentUserError(`Couldnt addProductIdToBids: ${e}`, 6013);
    }
  }

  async linkPasswordAccount(email?:string, password?:string):Promise<any>{
    let cred = firebase.default.auth.EmailAuthProvider.credential(email,password); //TODO check if this throws an error -bryson

    return this.currentAuthUser.linkWithCredential(cred).then(usercred => {
      if(!usercred.user.emailVerified)
        usercred.user.sendEmailVerification().then(() => this.toastr.success('Love, ' + GlobalConstants.NAME + ' Team','Email Verification Sent'));
      this.passwordEmailSubject.next(this.accountEmail('password'));
      this.toastr.success("Love, The Daily Bids Team","Linked Successfully");
    }, error => {
      throw new AuthError(error, 4012); 
    }); 
  }

  async unlinkPasswordAccount():Promise<any>{
    return this.currentAuthUser.unlink(this.PASSWORDID).then(user => {
      return user.updateEmail(this.accountEmail(this.GOOGLEID)).then(() => {
        this.passwordEmailSubject.next(''); 
        this.toastr.success("Love, The Daily Bids Team","Unlinked Successfully");
      }).catch(error => { throw new AuthError(error, 4011) }); 
    }).catch(error => { throw new AuthError(error, 4010) });
  }

  async linkGoogleAccount():Promise<any>{
    return this.currentAuthUser.linkWithPopup(new firebase.default.auth.GoogleAuthProvider()).then(usercred => {
      this.googleEmailSubject.next(this.accountEmail(this.GOOGLEID));
      this.toastr.success("Love, The Daily Bids Team",`${this.accountEmail('google.com')} linked Successfully`);
    }, error => { throw new AuthError(error, 4009) }); 
  }

  async unlinkGoogleAccount():Promise<any>{
    console.log("Unlink google account");
    return this.currentAuthUser.unlink(this.GOOGLEID).then(() => {
      if(!this.emailVerified()) throw new CurrentUserError('Unlinked google when email/password was unverified', 6021);
      this.googleEmailSubject.next('');
      this.toastr.success("Love, The Daily Bids Team","Unlinked Successfully");
    }, error => { throw new AuthError(error, 4008) }); 
  }

  async sendVerificationLink():Promise<any>{
    return this.currentAuthUser.sendEmailVerification()
    .then(() => { this.toastr.success('Verification Email Sent!'); })
    .catch(error => { throw new AuthError(error, 4007) }); 
  }

  async deleteAccount(){
    this.currentAuthUser.delete().then(() => {
      this.router.navigate(['/']);
    }).catch(error => { throw new AuthError(error, 4006); }); 
  }

  // Solved strange bug here which made code return early from function. The issue was with 
  // the `toPromise` method at the end of the pipe. The fix was to add the pipe operator `take(1)` which makes the toPromise
  // resolve. https://stackoverflow.com/questions/51677405/what-should-i-use-instead-of-topromise-when-using-await-on-an-observable
  // NOTE: https://medium.com/javascript-in-plain-english/rxjs-7-in-depth-32cc7bf3e5c
  // which states that in RXJS 7, toPromise will be depricated and replaced. 
  public getDisplayNameFromId(userId:string):Promise<string>{
    return this.afs.collection<PublicFireStoreUser>('users', (ref) => ref.where(firebase.default.firestore.FieldPath.documentId(), '==', userId)).valueChanges()
    .pipe(
      map(d => { return (d.length == 1) ? d[0].displayName : null }), 
      catchError(err => {throw new FirestoreError(err, 2001)}), 
      take(1)
    ).toPromise()
  }


/**********************AUTHSERVICE STUFF*************************************************/

  /**
   * Signin using the specified signin provider. 
   * Creates the google signin popup. 
   * NOTE: When a email/password signin method already exists and is of type 'whatever@gmail.com' and
   * someone logs in through the google provider with that same email ('whatever@gmail.com'), one of two things happen:
   *  1) if the email/password signin method with 'whatever@gmail.com' has been emailVerified, then google account is linked to the account with email/password signin but displayname, photoURL, phonenumber, and other authData is overridden by google. 
   *  2) if the email/password account is not emailVerified, then google account totally overrides the account with email/password signin and the user will only be able to signin through google. - This needs to be avoided! 
   * @param: provider - the provider for signin, (google, facebook ...etc)
   */
  private oAuthLogin(provider):Promise<any>{
    return this.afAuth.signInWithPopup(provider)
    .catch(error => {
      throw new AuthError(error, 4005); 
    })
  }

  /**
   * Signin with google account. 
   */
  public googleLogin():Promise<any>{
    try{
    const provider = new firebase.default.auth.GoogleAuthProvider()
    return this.oAuthLogin(provider);
    }catch(e){ throw new AuthError(e, 4023, false)}
  }

    /**
   * Signin with facebook account. 
   */
     public facebookLogin():Promise<any>{
      try{
      const provider = new firebase.default.auth.FacebookAuthProvider()
      return this.oAuthLogin(provider);
      }catch(e){ throw new AuthError(e, 4025, false)}
    }

  /**
   * Attempt to login with email and password combination. 
   * Return true on success. userCred
   * Return false on failure and updates error observables. 
   */
  public async loginEmailAndPassword(email: string, password: string):Promise<any>{
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .catch(error => { 
      throw new AuthError(error, 4004); 
    });
  }

  /**
   * Method to register an account. 
   * Place the new registered user in the database upon success. Automatically signed the user out.
   */
  public async registerEmailandPassword(email: string, password: string, displayName: string){
    this.displayName_from_email_registration = displayName; 
    this.send_email_from_email_registration = true; 
    return this.afAuth.createUserWithEmailAndPassword(email, password)
    .catch(error => {
      throw new AuthError(error, 4003); 
    })
  }

  /**
   * Route to home and popup the verify email modal if user is unverified.
   * Used for restricting access to certain pages. 
   */
  public verifiedAccess(){
     try{
      if(!this.emailVerified() && this.currentAuthUser.providerData.length == 1){ 
        this.router.navigate(['/']);
        this.openVerification();
      }
    }catch(e){ throw new CurrentUserError(`Can't VerifyAccess: ${e}`, 6014)}
  }

  /**
   * @return the users current location
   */
  public getLocation(): GoogleLocation {
    return this.location;
  }

  public getUserDownloadUrl(id: string, thumbnail: boolean = false): string {
    let base = GlobalConstants.CONTACT_BASE_URL;
    base += id;
    base += "%2Favatar";
    if (thumbnail) {
      base += "_32x32.png"
    } else {
      base += ".png"
    }
    base += "?alt=media";
    return base;
  }

  public getContactPhotoPath(id: string, thumbnail: boolean = false): string {
    if (thumbnail && id.includes('.')) {
      throwError("User ID must not contain decimal. Cannot find thumbnail.");
    }
    let path = "contact_image/" + id + "/avatar.png";
    if (thumbnail) {
      path = path.split(".")[0] + "_32x32.png";
    }
    return path;
  }

  public deleteProfilePic(id: string) {
    if (this.uid() != id) {
      throwError("User cannot delete this image");
    }

    const ref = this.storage.storage.ref("contact_image/" + id);
   return ref.list().then(dir => {
      dir.items.forEach(pic => {
        pic.delete();
      });
    });
  }

  public getUserStats(id: string) {
    return this.afs.collection('users').doc<PublicFireStoreUser>(id).valueChanges()
    .pipe(catchError(e => { throw new FirestoreError(e, 2023)}))
  }

  /**
   * Add City to Firebase. (This is necessary for firebase functions
   * to remove indices of old products)
   * 
   * @param id Id of city which user navigates to
   * @param name Name of city
   */
  private async setCityName(id: string, name: string) {
    if (id) // if id is empty, whole app won't load
    try {
      const doc = await this.afs.collection('cities').doc(id).get().toPromise();
      if (!doc.exists) {
        await this.afs.collection('cities').doc(id).set({
          name: name
      });
    }
    } catch (e) {
     throw new CurrentUserError(`Failed to setCityName: ${e}`, 6019)
    }
  }

  /**
   * change the users current location
   * 
   * @param location location to change to
   * @param changeDefault If true, will affect the local storage for this 
   *                      browser to load this as the initial city
   * @param setLocationObservable If true, the locationObservable will be set to false
   *                              This is useful to force re-initialize all compononents within
   *                              router outlet.
   */
  changeLocation(location: GoogleLocation, changeDefault = false, setLocationObservable = false, showWarning = false): boolean {
    const id = location.id;
    const name = location.name;
    if (!id || id.length < 3 || !name || name.length < 2) {
      if (showWarning) this.toastr.warning("Please use the dropdown to select location", "Invalid location");
      return false;
    }
    try{
      if (setLocationObservable) this.setLocationSubject.next(false);
      if (changeDefault) {
        localStorage.setItem((this.cityCacheKey), JSON.stringify(location));
      }
      this.hasCurrentLocation = true;
      this.location = location;
      this.locationServ.addUserCity(location);
      setTimeout(() => {
        this.setLocationSubject.next(true);
        this.analytics.logEvent("set_city", {"city_name": location.name})
        // Allow location data to be updated
      }, 10)
      this.setCityName(location.id, location.name).catch(e => {
        isDevMode() && console.error(e);
      })
      return true;
    }catch(e){ throw new CurrentUserError(`Failed to changeLocation: ${e}`, 6018)}
  }

  hasSetLocation(): boolean {
    return this.hasCurrentLocation;
  }

  /**
   * Returns a string of the location name as (city, area, country (most of the time))
   * Trims the name length to CITY_MAX_LEN
   * 
   * @param full if true, will not trim the name
   */
  getLocationName(full = false): string {
    try{
      if (full) {
        return this.location.name;
      }
      if (this.location.name.length > this.CITY_MAX_LEN) {
        return this.location.name.substring(0, this.CITY_MAX_LEN - 3) + "...";
      } else {
        return this.location.name;
      }
    }catch(e){ throw new CurrentUserError(`Error getLocationName ${e}`, 6017)}
  }

    /**
   * Returns a string of the city. Requires the city to be the first word
   * before a comment.
   * 
   * Trims the name length to CITY_MAX_LEN
   * 
   * @param full if true, will not trim the name
   */
  getCityName(full = false): string {
    try{
      let arr = this.location.name.split(',')
      let city = arr[0];

      if (full) return this.location.name;

      if (city.length > this.CITY_MAX_LEN) {
        return city.substring(0, this.CITY_MAX_LEN - 3) + "...";
      } else {
        return city;
      }
    }catch(e){ throw new CurrentUserError(`Can't getCityName: ${e}`, 6016)}
  }

  // Get initial city, returns default Vancouver
  private getInitialCity(): GoogleLocation {
    try{
      let pastCity = JSON.parse(localStorage.getItem(this.cityCacheKey));

      if (pastCity) {
        this.setCityName(pastCity.id, pastCity.name);
        this.hasCurrentLocation = true;
        this.setLocationSubject.next(true);
        return new GoogleLocation(pastCity.id, pastCity.name);
      } else {
        this.setLocationSubject.next(true);
        this.hasCurrentLocation = false;
        return new GoogleLocation("", "") // Default city
      }
    }catch(e){ throw new CurrentUserError(`Can't getInitialCity: ${e}`, 6015) }
  }

  /**
   * Sends an reset email to the email provided. 
   * Expects client to catch and deal with errors.
   * @param email email submitted by user to get reset email. 
   */
  public sendResetPasswordEmail(email):Promise<any>{
    return this.afAuth.sendPasswordResetEmail(email)
    .catch(e => { throw new AuthError(e, 4024, false)})
  }

  /* Control modals for signingin and verification    */
  openVerification(message?: string){
    // this.openVerificationSubject.next(false);
    // had an error once where this wouldn't open even when pressed the button
    // lets see if this happens again because it was causing a problem on auth where 
    // the openVerification() gets called twice and then ends up canceling both..

    this.openVerificationSubject.next(true);

    if (message) {
      this.verificationMessageSubject.next(message);
    }
  }

  closeVerification(){
    this.openVerificationSubject.next(false);
    this.verificationMessageSubject.next("");
  }

  fetchEmailMethods(email:string){
   return this.afAuth.fetchSignInMethodsForEmail(email)
   .catch(error => {
    throw new AuthError(error, 4002); 
   }); 
  }

  goToWatchlist() {
    sessionStorage.setItem(this.tabCacheKey, 'bids');
    this.router.navigate(["/profile"]);
  }

  goToUserPosts() {
    sessionStorage.setItem(this.tabCacheKey, 'posts');
    this.router.navigate(["/profile"]);
  }

  // If location is not set, we should set it to user's default location when they sign in
  private setDefaultLocation() {
    this.userIsInitialized$().subscribe(bool => {
      this.userIsInitialized = bool;
      if (bool && this.exists()) {
        this.firestore.location$.pipe(take(1)).subscribe((loc) => {
          if (loc != null && loc.id != null && loc.id.length > 1 && !this.hasCurrentLocation) {
            this.changeLocation(loc, true, true)
            this.setCityName(loc.id, loc.name);
            this.hasCurrentLocation = true;
            return new GoogleLocation(loc.id, loc.name);
          }
        }, error => { throw new FirestoreError(`Fail to subscribe to location: ${error}`, 2027) }
        );
      }
    });
  }

  // put everything here that should not persist after logout
  private clear() {
    this.userSubs.forEach(sub => sub.unsubscribe());
    this.userSubs = [];
    this.notificationService.clear();
    if(this.firestore) this.firestore.destroy();
    this.firestore = null;
    this.currentAuthUser = null;
    this.displayName_from_email_registration = null;
    this.send_email_from_email_registration = false;
    this.conversationsSubject.next([]);
    this.refer_id = null;
  }
}
