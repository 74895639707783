<div>
  <nav-bar></nav-bar>
  <div style="padding-top: 64px;">
    <div>
      <verify-email
        title="Verify your email!"
        (onCancel)="this.cancelVerify()"
        [(openModal)]="this.openVerifyModal"
        >please verify your email to use Daily Bids!</verify-email
      >
      <div *ngIf="initialized && hasLocation; else initializing">
        <loading *ngIf="loadingNextRoute || loadingUser" [fadeIn]="!loadingUser"></loading>
        <router-outlet></router-outlet>
        <!-- <desktop-messenger *ngIf='currentUser.exists()'></desktop-messenger> -->
      </div>
      <terms-link></terms-link>
      <ng-template #initializing>
        <loading></loading>
      </ng-template>
    </div>
  </div>
</div>

<adblock></adblock>
