<h1 mat-dialog-title>Please consider this</h1>
<div mat-dialog-content>
  <strong>If you use Amazon,</strong> we will try to show you relevant Amazon
  products. We hope these products will help you make a good decision before
  placing a bid. <br /><br />
  Also,
  <strong>this website isn't free!</strong><br />
  <!-- Daily Bids has to pay money to keep this website running. Please
  <strong
    >consider disabling ad blocker and other blocking software for this
    website.</strong> -->
  <br />
  The best ways to support Daily Bids are to <strong>Go Premium</strong> or 
  <strong>disable your ad blocking software.</strong>
  <!-- That way, we can show you relevant Amazon products to help you with your
  purchasing decisions! (While also helping us pay for and improve this
  website). -->
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()">No Thanks</button>
  <button mat-raised-button [routerLink]="" (click)="goPremium()" color="primary" cdkFocusInitial>Go Premium</button>
</mat-dialog-actions>
