import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TimerService } from "src/app/services/timer.service";
import { Product } from "shared/interfaces/product";
import { Subscription, timer } from "rxjs";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "timer-block",
  templateUrl: "./timer-block.component.html",
  styleUrls: ["./timer-block.component.scss"],
})

export class TimerBlockComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Input() initializeOnInit: boolean = false;

  hours: number;
  minutes: number;
  seconds: number;
  
  timerSub: Subscription;
  running = false;

  constructor(private biddingTimerService: TimerService) {}

  ngOnInit() {
    if (this.initializeOnInit) this.startTimer();
  }

  /**
   *  Called on init to update the remaining time every 1000 ms
   */
  startTimer() {
    this.running = true;
    let timeLeftInMillis = this.biddingTimerService.getRemainingTimeMillis(this.product);
    this.calculateHMS(timeLeftInMillis);
    if (timeLeftInMillis <= 0) return;
    
    // first count removes millisecond level error
    const source = timer(timeLeftInMillis % 1000, 1000);

    this.timerSub = source.pipe(
      takeWhile(() => timeLeftInMillis > 0)
    ).subscribe(() => {
      timeLeftInMillis = timeLeftInMillis - 1000
      this.calculateHMS(Math.max(timeLeftInMillis, 0));
    });
  }

  /**
   *  Called when timer is no longer needed to stop running it on the background
   */
  clearTimer() {
    if (this.timerSub) this.timerSub.unsubscribe();
    this.running = false;
  }

  /**
   *  @argument remaining time in seconds 
   *  Calcuates the remaining time in hours minutes and seconds and updates the fields
   */
  calculateHMS(timeInMillis: number) {
    const timeInSecs = timeInMillis/1000;
    this.hours = Math.floor(timeInSecs / 3600);
    this.minutes = Math.floor((timeInSecs % 3600) / 60);
    this.seconds = Math.floor(timeInSecs % 60);
  }

  ngOnDestroy() {
    this.clearTimer();
  }
}
