import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ErrorService } from "src/app/services/error.service";
import { UserService } from "src/app/services/core/user.service";
import { PasswordMatchValidator } from "src/app/objects/Validators/passwordMatch.validator";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GlobalConstants } from "src/app/Global";
import { LoginDialog } from "./login-dialog.component";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "register-dialog",
  templateUrl: "./register-dialog.component.html",
  styleUrls: ["./login.component.scss"],
})
export class RegisterDialog {

  // full name divided by 2 minus 1 for space
  MAX_NAME = GlobalConstants.MAX_DISPLAY_NAME/2 - 1;

  constructor(
    public currentUser: UserService,
    public toaster: ToastrService,
    public _error: ErrorService,
    public dialogRef: MatDialogRef<LoginDialog>,
    private utils: UtilService,
    @Inject(MAT_DIALOG_DATA) public dialogData:any, 
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isOpen: boolean; //Boolean used to only toast when logins are open
  loginMessage: string;
  subs: Subscription[] = [];

  registerForm = new FormGroup(
    {
      referralCode: new FormControl("Hello buddy", []),
      displayFirstName: new FormControl("", Validators.required),
      displayLastName: new FormControl("", Validators.required),
      regEmail: new FormControl("", [Validators.required, Validators.email]),
      regPassword: new FormControl("", [
        Validators.minLength(6),
        Validators.required,
      ]),
      confirmPassword: new FormControl("", [Validators.required]),
    },
    PasswordMatchValidator.passwordMatchValidator(
      "regPassword",
      "confirmPassword"
    )
  );

  ngOnInit() {
    this.subs.push(
      this._error.authMessage$.subscribe(
        (res) =>
          this.isOpen && res && res.length > 1 && this.toaster.error(res)
      )
    );
    this.subs.push(
      this.dialogRef.afterOpened().subscribe(() => {
        this.isOpen = true;
        this.loginMessage = this.dialogData?.message;
      })
    );
    this.subs.push(
      this.dialogRef.afterClosed().subscribe(() => this.isOpen = false)
    );
    this.registerForm.get("referralCode").setValue(this.currentUser.refer_id);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  toggleSignin() {
    this._error.clearAuthMessage();
    this.clearFields();
    this.dialogRef.close("login");
  }

  closeModal() {
    this.clearFields();
    this._error.clearAuthMessage();
    this.loginMessage = null;
    this.dialogRef.close();
  }

  googlePress() {
    this.closeModal();
    this.currentUser.googleLogin().catch((error) => {
      error.alert = false;
      throw error;
    });
  }

  async registerEmailPassword() {
    if (this.registerForm.valid) {
      this.currentUser
        .registerEmailandPassword(
          this.registerEmail.value,
          this.registerPassword.value,
          this.displayName
        )
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          error.alert = false;
          throw error;
        });
    } else this.registerForm.markAllAsTouched();
  }

  goTo(path: string) {
    this.utils.dialogNavigate(path, this.dialogRef);
  }

  /**
   * Call this function upon changed state of the login observable.
   */
  clearFields() {
    this.registerForm.reset();
  }

  /*
    Get all the contents of the user inputs from the two forms; loginForm and registerFrom. 
  */
  get registerEmail() {
    return this.registerForm.get("regEmail");
  }
  get registerPassword() {
    return this.registerForm.get("regPassword");
  }
  get first() {
    return this.registerForm.get("displayFirstName");
  }
  get last() {
    return this.registerForm.get("displayLastName");
  }
  get displayName() {
    return this.first.value.trim() + " " + this.last.value.trim();
  }
  get confirmPassword() {
    return this.registerForm.get("confirmPassword");
  }
}