import { SharedConstants } from "shared/constants";

const storageBaseUrl = "https://firebasestorage.googleapis.com/v0/b/daily-bids.appspot.com/o/"

export const GlobalConstants = Object.freeze({
    MOBILE_SCREEN_THRESHOLD: 500, // a default option, not always used.
    MOBILE_BATCH_SIZE: 3,
    INITIAL_PROD_BATCH_SIZE_WHEN_ADS_PRESENT: 9, // set higher so doesn't look weird on premium
    PROD_BATCH_SIZE: 9,
    NOTIFICATION_BATCH_SIZE: 10,
    MESSAGE_PAGE_SIZE: 25,
    MAX_INPUT: 100, // a general length for max input used in most places
    MAX_CONTACT_EMAIL: 360,
    MAX_CONTACT_NUMBER: 35,
    MAX_DISPLAY_NAME: 80,
    MAX_MSG_INPUT: 5000,
    CACHE_PREFIX: "_dailybids_cache_",
    LOADING_URL: "/assets/loading.svg",
    LOADING_IMG_URL: "/assets/loading-img.svg",
    CONTACT_BASE_URL: storageBaseUrl + "contact_image%2F",
    CIRCLED_X_SVG: storageBaseUrl + "_siteImages%2Ferror.svg?alt=media",
    PICK_UP_LOCATION_MAX_LEN: 40,
    DESCRIPTION: 'description',
    NAME: "Daily Bids",
    TAMMY_CODE: SharedConstants.TAMMY_CODE,
    DEFAULT_PROFILE_PICTURE_URL: storageBaseUrl + "_siteImages%2Fdefault_128x128.jpg?alt=media",
    DEFAULT_PROFILE_PICTURE_URL_THUMB: storageBaseUrl + "_siteImages%2Fdefault_32x32.jpg?alt=media",
    EDITABLE_PRODUCT_FIELDS: 
    [
        'startingBid', 'mapLocationName', 'mapLocationId', 'pickUpLocation', 
        'description', 'displayContactInfo', 'images'
    ],
    EDITABLE_PRIVATE_FIELDS: 
    [
        'posterEmail', 'posterNumber'
    ],
    AUTH_ERRORS:{
        RECENT_LOGIN: "auth/requires-recent-login",
        IN_USE_CREDENTIAL: "auth/credential-already-in-use",
        NOT_FOUND: "auth/user-not-found",
        INVALID_EMAIL: "auth/invalid-email",
        MISMATCH: "auth/user-mismatch",
        TOO_MANY_REQUESTS: "auth/too-many-requests",
        WRONG_PASSWORD: 'auth/wrong-password' ,
        NETWORK_FAILURE: 'auth/network-request-failed',
        EMAIL_ALREADY_IN_USE: "auth/email-already-in-use"
    }
});
