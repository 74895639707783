<div class="wrap" role="alert">
    <mat-card>
        <mat-card-content [style.text-align]="alignContentCenter ? 'center' : ''">
            <mat-icon [inline]="true" class="alert-icon">warning</mat-icon>
            <span class="alert-text">
                {{ message }}
            </span>
        </mat-card-content>
        <mat-card-footer *ngIf="hasAction">
            <button mat-raised-button color="primary" (click)="clicked()">{{actionButtonText}}</button>
        </mat-card-footer>
    </mat-card>
</div>