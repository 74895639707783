<h1 mat-dialog-title>Confirm Delete</h1>
<div mat-dialog-content [class.hide-content]="deletingImage">
  <p>Are you sure you want to delete your profile picture?</p>
  <div class="delete-photo-container">
    <img [(src)]="this.imageURL" class="contact-photo" />
  </div>
  <div *ngIf="deletingImage">
    <loading-icon class="loading"></loading-icon>
  </div>
  <p *ngIf="failedDeleteImage">
    Please try again, failed to delete image with: {{ deleteErrorMessage }}.
  </p>
</div>
<mat-dialog-actions align="end" [class.hide-content]="deletingImage">
  <button
    type="button"
    mat-raised-button
    (click)="onNoClick()"
  >
    Cancel
  </button>
  <button mat-raised-button color="warn" (click)="deleteProfilePic()">
    Delete
  </button>
</mat-dialog-actions>
