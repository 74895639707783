import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAnalyticsModule, ScreenTrackingService, CONFIG, COLLECTION_ENABLED, DEBUG_MODE, APP_VERSION } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ToastrModule } from 'ngx-toastr';

import { MoreLinksDialog, NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HomeComponent, RulesSummaryDialog } from './components/home/home.component';
import { GlobalErrorHandler } from './error-handler/error-handler';
import { AdblockComponent, AdblockDialog } from './components/adblock/adblock.component';
import { TermsLinkComponent } from './components/terms-link/terms-link.component';
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDialogModule } from '@angular/material/dialog'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatDividerModule } from '@angular/material/divider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { ContactDialog } from './components/contact/contact.component';
import { ContactFormComponent } from './components/contact/contact.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AllProductsComponent } from './components/all-products/all-products.component';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { ReusableModule } from './modules/reusable/reusable.module';
import { ServiceWorkerModule } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    AdblockComponent,
    TermsLinkComponent,
    AdblockDialog,
    ContactDialog,
    RulesSummaryDialog,
    ContactFormComponent,
    MoreLinksDialog,
    ContactDialog,
    AllProductsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GooglePlaceModule,
    LazyLoadImageModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      progressBar: true,
      preventDuplicates: true,
      maxOpened: 5,
      resetTimeoutOnDuplicate: true
    }), // ToastrModule
    HammerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatToolbarModule,
    MatDividerModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatBadgeModule,
    MatCardModule,
    MatAutocompleteModule,
    ReusableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ScreenTrackingService,
    // anayltics configuration
    {
      provide: CONFIG, useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: false,
        anonymize_ip: false
      }
    },
    { provide: COLLECTION_ENABLED, useValue: true },
    { provide: DEBUG_MODE, useValue: false },
    { provide: APP_VERSION, useValue: "beta" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
