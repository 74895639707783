import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AuthError } from "../../../../../error-handler/customErrors";
import { ErrorService } from "src/app/services/error.service";
import { UserService } from "src/app/services/core/user.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: "login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginDialog {

  constructor(
    public currentUser: UserService,
    public toaster: ToastrService,
    public _error: ErrorService,
    public dialogRef: MatDialogRef<LoginDialog>,
    private utils: UtilService,
    @Inject(MAT_DIALOG_DATA) public dialogData:any, 
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isOpen: boolean; //Boolean to determine the open/close state of the modal.
  loginMessage: string;
  subs: Subscription[] = [];
  showPasswordResetInput: boolean = false;

  loginForm = new FormGroup({
    logEmail: new FormControl("", [Validators.required, Validators.email]),
    logPassword: new FormControl("", [
      Validators.minLength(6),
      Validators.required,
    ]),
  });

  resetForm = new FormGroup({
    resetEmail: new FormControl("", [Validators.required, Validators.email]),
  });

  ngOnInit() {
    this.subs.push(
      this._error.authMessage$.subscribe(
        (res) =>
          this.isOpen && res && res.length > 1 && this.toaster.error(res)
      )
    );
    this.subs.push(
      this.dialogRef.afterOpened().subscribe(() => {
        this.loginMessage = this.dialogData?.message;
        this.isOpen = true;
      })
    );
    this.subs.push(
      this.dialogRef.afterClosed().subscribe(() => this.isOpen = false)
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  toggleSignin() {
    this._error.clearAuthMessage();
    this.clearFields();
    this.dialogRef.close("register");
  }

  closeModal() {
    this.clearFields();
    this._error.clearAuthMessage();
    this.loginMessage = null;
    this.dialogRef.close();
  }

  goTo(path: string) {
    this.utils.dialogNavigate(path, this.dialogRef);
  }

  googlePress() {
    this.closeModal();
    this.currentUser.googleLogin().catch((error) => {
      error.alert = false;
      throw error;
    });
  }

  facebookPress() {
    this.closeModal();
    this.currentUser.facebookLogin().catch((error) => {
      error.alert = false;
      throw error;
    });
  }

  forgotPassword() {
    let email = this.resetForm.get("resetEmail").value.replace(/\s/g, "");
    this.currentUser
      .sendResetPasswordEmail(email)
      .then(() => {
        this.cleanUpEmailSent();
      })
      .catch((error) => {
        if (
          error.code == "auth/user-not-found" ||
          error.code == "auth/invalid-email"
        )
          this.cleanUpEmailSent();
        else throw new AuthError(error, 4000, false); //Let error handler code deal with the unknown error.
      });
  }

  submitForgotPassword() {
    this.showPasswordResetInput = !this.showPasswordResetInput;
    this.resetForm.reset();
    setTimeout(() => {
      document.getElementById("reset-form-input").focus();
    }, 1);
  }

  cleanUpEmailSent() {
    this.showPasswordResetInput = false;
    this.toaster.success(
      `Reset email sent to ${
        this.resetForm.get("resetEmail").value
      } if the account exists.`
    );
    this.resetForm.reset();
  }

  async loginEmailPassword() {
    if (this.loginForm.valid) {
      this.currentUser
        .loginEmailAndPassword(this.loginEmail.value, this.loginPassword.value)
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          error.alert = false;
          throw error;
        });
    } else this.loginForm.markAllAsTouched();
  }

  /**
   * Call this function upon changed state of the login observable.
   */
  clearFields() {
    this.loginForm.reset();
    this.resetForm.reset();
  }

  /*
    Get all the contents of the user inputs from the two forms; loginForm and registerFrom. 
  */
  get loginEmail() {
    return this.loginForm.get("logEmail");
  }
  get loginPassword() {
    return this.loginForm.get("logPassword");
  }
  get resetEmail() {
    return this.resetForm.get("resetEmail");
  }
  get errors() {
    return this.resetEmail.errors
      ? Object.keys(this.resetEmail.errors)
      : "none";
  }
}