import { Injectable } from "@angular/core";
import { Product } from "shared/interfaces/product";

@Injectable({
  providedIn: "root",
})
export class TimerService {

  aDayInSecs: number = 86400
  aDayInMillis: number = this.aDayInSecs * 1000;

  constructor() { }

  /**
   * Get the current date-time object of the user (time on the device)
   * @returns the current time as a Date object
   */
  getUsrTime() {
    return new Date();
  }

  /**
   * @param Product to get the timestamp of
   * @returns the date the given Product was uploaded
   */
  getProductUploadDate(product: Product): Date {
    return product.uploadTime.toDate();
  }

  /**
   *  Compares the upload time of the product and users device time
   *
   *  @param Product to get remaining time of
   *  @returns the difference in milliseconds
   */
  getRemainingTimeMillis(product: Product): number {
    var productUploadTime =
      this.getProductUploadDate(product).getTime();
    var localTime = this.getUsrTime().getTime();
    var diff = this.aDayInMillis - (localTime - productUploadTime);
    return Math.max(diff, 0);
  }

  /**
   *  @argument Product to get the bid ending date-time of
   *  @returns the ending time of the product put for sale as a Date-Time object
   */
  getBidEndingTime(product: Product): Date {
    var productUploadTime =
      this.getProductUploadDate(product).getTime();
    var endingTime = productUploadTime + this.aDayInMillis;
    var endingDateTime = new Date(endingTime);
    return endingDateTime;
  }
}
