<div class="row">
  <div class="col-xs-12 col-sm-6">
    <div *ngIf="biddingFinished">
      <h2>Bidding has finished</h2>
    </div>

    <div *ngIf="isOwner && !biddingFinished">
      <div>
        <strong>{{ product.bidCount }} bids placed</strong>
      </div>
      <div class="timer-float-container">
        <div class="timer-float-child-left">Time left:</div>
        <div class="timer-float-child-middle">
          <timer-block
            [product]="product"
            [initializeOnInit]="true"
            class="modal-timer"
          ></timer-block>
        </div>
      </div>
    </div>

    <div *ngIf="allowBidding()">
      <form (keyup.enter)="placeBid(bidAmount)">
        <div class="bid-input-wrap">
          <mat-form-field [hideRequiredMarker]="true" style="width: 140px; position: relative;">
            <input
              #bidInput
              (click)="$event.target.select()"
              matInput
              type="number"
              [(ngModel)]="bidAmount"
              name="starting bid"
              placeholder="Enter bid here"
              (keypress)="keyPress($event)"
              [maxLength]="MAX_LENGTH"
            />
            <span *ngIf="highlightInput" class="bring-attn-arrow"></span>
            <mat-hint
              >Minimum bid:
              <span class="min-bid" (click)="setBidToMin()"
                >${{ minimumBid() }}</span
              ></mat-hint
            >
          </mat-form-field>
          <div class="watchlist-wrap">
            <button
              [matTooltip]="
                'Click to ' +
                (this.productIsBeingWatched
                  ? 'remove this product from'
                  : 'add this product to') +
                ' your watchlist. Products in your watchlist will appear in your profile.'
              "
              mat-icon-button
              [style.color]="productIsBeingWatched ? 'green' : 'black'"
              (click)="this.toggleProductBeingWatched()"
            >
              <mat-icon>{{
                productIsBeingWatched ? "visibility" : "visibility_off"
              }}</mat-icon>
            </button>
            <div id="watchlink-wrap">
              <a (click)="goToWatchlist()" id="watchlist-link"
                >Go&nbsp;to&nbsp;watchlist</a
              >
            </div>
          </div>
        </div>
        <!-- 
    <clr-control-error
      >Minimum bid:
      <span class="min-bid" (click)="setBidToMin()">${{ minimumBid() }}</span>
    </clr-control-error> -->
        <div class="timer-float-container">
          <div class="timer-float-child-left">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              (click)="placeBid(bidAmount, bidInput)"
            >
              Place bid
            </button>
          </div>
          <div class="timer-float-child-middle">
            <timer-block
              [product]="product"
              [initializeOnInit]="true"
              class="modal-timer"
            ></timer-block>
          </div>
          <div class="info-icon-fixed">
            <mat-icon
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              class="link-like"
              matTooltip="This countdown is based off of your device's time. It may be
      inaccurate."
              >help_outline</mat-icon
            >
          </div>
        </div>
      </form>
    </div>
  </div>

  <div
    *ngIf="showMessageDropdown || product.winningBid"
    class="col-xs-12 col-sm-6 center-small" 
  >
    <winning-bid
      *ngIf="!showMessageDropdown"
      [winningBid]="product.winningBid"
      [showMessageButton]="showMessageButton(product.winningBid)"
      [messageIds]="getMessageIds(product.winningBid)"
      [finalized]="product.finalized"
    ></winning-bid>
    <div class="won-container" *ngIf="showMessageDropdown">
      <mat-expansion-panel
        style="text-align: center"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font: fantasy">
              {{
                currentUserIsWinner
                  ? "You Won!"
                  : this.product.winningBid.username + " Won!"
              }}
            </b>
          </mat-panel-title>
          <mat-panel-description>
            Message the {{ currentUserIsWinner ? "seller" : "winner" }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <textarea
          [hidden]="!showTextArea"
          #sellerTextArea
          (keydown.enter)="fixEnterIssue($event)"
          (keyup.enter)="sendMessage($event.target)"
          placeholder="Ex. Hello, I can meet at..."
          [maxLength]="MSG_MAX_LENGTH"
        ></textarea>
        <button
          *ngIf="showTextArea"
          mat-flat-button
          color="primary"
          class="sendBtn"
          [disabled]="!sellerTextArea.value"
          (click)="sendMessage(sellerTextArea)"
        >
          Send
        </button>
        <a *ngIf="!showTextArea" (click)="goToConversations()"
          >View message in conversations</a
        >
      </mat-expansion-panel>
    </div>
  </div>
</div>

<!-- For some reason the list impacts the size of the image... -->
<!-- This is here to ensure the winning bid is visible even if it is paginated away -->
<!-- <div style="margin-top: 15px;" *ngFor="let bid of [product.winningBid]">
  <ng-container
    *ngIf="bid"
    [ngTemplateOutlet]="biddingTemplate"
    [ngTemplateOutletContext]="{ bid: bid, idAppend: 'winner' }"
  >
  </ng-container>
</div> -->

<div style="margin-top: 5px">
  <div *ngFor="let bid of bids; trackBy: byBidId" style="margin-top: 5px">
    <ng-container
      [ngTemplateOutlet]="biddingTemplate"
      [ngTemplateOutletContext]="{ bid: bid, idAppend: 'norm' }"
    >
    </ng-container>
  </div>
</div>
<div style="color: rgb(44, 44, 44)" *ngIf="hasMoreBids()">
  <span [class.load-bids-link]="!loadingBids" (click)="loadMoreBids()"
    >View later bids</span
  >
</div>

<ng-template #biddingTemplate let-bid="bid" let-idAppend="idAppend">
  <div class="bid-container">
    <span style="display: flex; align-items: center">
      <user-signpost
        [name]="bid.username"
        [userId]="bid.userId"
        [openBelow]="false"
        [uid]="bid.id + idAppend"
        [size]="25"
        [includeProfilePic]="true"
        [showMessageButton]="showMessageButton(bid)"
        [messageIds]="getMessageIds(bid)"
        [userHasProfilePic]="bid.hasProfilePic"
      ></user-signpost>
      <span
        [class.late-bid]="isLateBid(bid)"
        style="margin-left: 4px"
        class="bidder-text"
      >
        ${{ bid.amount }} at
        <span
          #tooltip="matTooltip"
          (click)="tooltip.toggle()"
          *ngIf="bid.bidTime"
          [matTooltip]="bid.bidTime.toDate() | date: 'hh:mm:ss.SSS a'"
        >
          {{ bid.bidTime.toDate() | date: "dd MMM hh:mm:ss a" }}
        </span>
        <span *ngIf="isLateBid(bid)"> (late) </span>
      </span>
    </span>
  </div>
</ng-template>
