import { Component, Input, OnInit } from '@angular/core';
import { Bid } from 'shared/interfaces/bid';
import { GlobalConstants } from 'src/app/Global';

@Component({
  selector: 'winning-bid',
  templateUrl: './winning-bid.component.html',
  styleUrls: ['./winning-bid.component.scss']
})
export class WinningBidComponent implements OnInit {

  @Input() set winningBid(bid: Bid) {
    this._pastBid = this._winningBid;
    this._winningBid = bid;
  }
  @Input() showMessageButton: boolean;
  @Input() messageIds: [];
  @Input() finalized: boolean;

  _winningBid: Bid;
  _pastBid: Bid;

  defaultThumb = GlobalConstants.DEFAULT_PROFILE_PICTURE_URL_THUMB;

  constructor() { }

  ngOnInit(): void {
  }

}
