import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ReplaySubject } from "rxjs";
import { GoogleLocation } from "shared/location";
import { FirestoreError } from "src/app/error-handler/customErrors";
import { GlobalConstants } from "src/app/Global";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private _popularCities: GoogleLocation[] = [];
  private _popularCities$ = new ReplaySubject<GoogleLocation[]>();
  private _userPastCities: GoogleLocation[] = [];

  private pastCitiesKey = GlobalConstants.CACHE_PREFIX + "_past_cities_";

  constructor(private afs: AngularFirestore) {
    this.setPopularCities();
    setTimeout(() => {
      this.getPastCities();
    }, 1000);
  }

  get popularCities() {
    return this._popularCities;
  }

  get popularCities$() {
    return this._popularCities$;
  }

  get pastUserCities() {
    return this._userPastCities;
  }

  getSuggestedCities() {
    let seenIds = new Set();
    let res = [...this._userPastCities, ...this._popularCities].filter(
      // get unique objects
      (val) => {
        if (seenIds.has(val.id)) return false;
        seenIds.add(val.id);
        return true;
      }
    );
    return res;
  }

  addUserCity(city: GoogleLocation) {
    if (this._userPastCities.some((loc) => loc.id == city.id)) return;
    this._userPastCities.unshift(city);
    localStorage.setItem(
      this.pastCitiesKey,
      JSON.stringify(this._userPastCities.slice(0, 3))
    );
  }

  private async setPopularCities() {
    try {
      const cities = await this.afs
        .collection("cities", (ref) =>
          ref.orderBy("posts", "desc").where("posts", ">", 10).limit(3)
        )
        .get()
        .toPromise();

      this._popularCities = cities.docs.map(
        (doc) => new GoogleLocation(doc.id, (doc.data() as any).name)
      );
      this._popularCities$.next(this._popularCities);
    } catch (e) {
      this._popularCities$.next([]);
      throw new FirestoreError("Could not get popular cities: " + e, 2019);
    }
  }

  private getPastCities() {
    let pastCities = JSON.parse(localStorage.getItem(this.pastCitiesKey));

    if (pastCities != null) {
      try {
        this._userPastCities = pastCities.map(
          (city) => new GoogleLocation(city.id, city.name)
        );
      } catch (e) {
        throw new Error("Could not read past cities: " + e);
      }
    }
  }
}
