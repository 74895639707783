import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/Global';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'loading-icon',
  templateUrl: './loading-icon.component.html',
  styleUrls: ['./loading-icon.component.scss']
})
export class LoadingIconComponent implements OnInit {

  loadingIcon = GlobalConstants.LOADING_URL;

  @Input() size = 100;

  constructor(private spinner: LoadingSpinnerService) { }

  ngOnInit(): void {
  }

  // don't want too many little loading things
  canShow() {
    return !this.spinner.hasActiveSpinner();
  }

}
