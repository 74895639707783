import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApplicationError } from "../error-handler/customErrors";

@Injectable({
  providedIn: "root"
})
/**
 * The purpose of this service is to allow only one global spinner on the screen at a time
 */
export class LoadingSpinnerService {
  private spinnerCount: number = 0;
  private spinners: Map<number, BehaviorSubject<boolean>> = new Map();
  private active: boolean = false;

  constructor() { }

  hasActiveSpinner() {
    return this.active;
  }

  addSpinner(): any {
    let spinner = this.spinnerCount++;
    let obs = new BehaviorSubject<boolean>(!this.active);
    this.active = true;
    this.spinners.set(spinner, obs);

    return [spinner, obs];
  }

  removeSpinner(id: number) {
    try {
      const spinner = this.spinners.get(id);
      if (!spinner) return;
      spinner.next(false);
      this.spinners.delete(id);

      if (this.spinners.size > 0) {
        this.spinners.get(this.spinners.keys().next().value).next(true);
        this.active = true;
      } else {
        this.active = false;
      }
    }
    catch (e) {
      throw new ApplicationError(e, 5105);
    }
  }
}
