<div class="home-wrap">
  <div style="text-align: center">
    <logo class="logo"></logo>
    <h1>Welcome to {{ getCity() }} {{ name }}</h1>
  </div>

  <div class="how-does-it-work" *ngIf="hasLocationSet()">
    <button (click)="openRulesSummary()" mat-raised-button>
      How does it work?
    </button>
    <h2 style="margin-top: 20px">
      <a routerLink="/sell"
        ><span style="text-decoration: underline">Sell items in 24 hours</span>
        <mat-icon>arrow_forward</mat-icon></a
      >
    </h2>
  </div>

  <div class="what-is-db" *ngIf="!hasLocationSet()">
    <div>
      <h2>How does it work?</h2>
      <div>
        <p>Buy & Sell in 3 Easy Steps:</p>
        <ol style="width: 100px; margin: auto; text-align: left">
          <li>Select location</li>
          <li>Create account</li>
          <li>Bid & sell</li>
        </ol>
        <p style="margin: 4px">Sell items in 24 hours!</p>
        <p style="margin: 4px">Highest bid wins in 24 hours!</p>
        <!-- Buy & Sell FAST -->
      </div>
    </div>

    <div class="city-select">
      <h2 class="location-label">Please select your city to start bidding</h2>
      <div
        *ngIf="popularCities$ | withLoading | async as obs"
        style="display: grid; place-items: center"
      >
        <!-- // @ts-ignore -->
        <ng-template [ngIf]="obs.value">
          <div *ngIf="obs.value.length > 0">
            <h3 style="margin: 0">Popular cities:</h3>
            <div *ngFor="let city of obs.value" class="city-link">
              <div (click)="changeAddress(city.id, city.name)">
                <mat-icon color="warn">whatshot</mat-icon>
                <span>{{ city.name }}</span>
                <mat-icon color="warn">whatshot</mat-icon>
              </div>
            </div>
            <p>(or search for one)</p>
          </div>
        </ng-template>
        <ng-template [ngIf]="obs.loading"
          ><loading-icon size="75"></loading-icon></ng-template>
      </div>
      <div class="wrap">
        <div class="search">
          <input
            class="searchTerm"
            ngx-google-places-autocomplete
            name="location of bidding"
            [options]="options"
            #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)"
            id="home-loc-srch"
            type="text"
          />
          <button type="button" class="searchButton" (click)="locationSearch()">
            <mat-icon aria-hidden="false" aria-label="Example home icon"
              >location_on</mat-icon
            >
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="loading-prods" *ngIf="loading">
    <loading-icon></loading-icon> 
  </div>
  <div *ngIf="!loading">
    <display-products [initialProducts]="initialProducts"></display-products>

    <div
      style="text-align: center; margin-bottom: 50px"
      *ngIf="hasLocationSet()"
    >
      <button
        *ngIf="showSeeAllButton()"
        routerLink="/products"
        mat-raised-button
      >
        See all posts <mat-icon>arrow_forward</mat-icon>
      </button>
      <div *ngIf="!showSeeAllButton()" class="first-here">
        <p>You're one of the first ones here!</p>
      </div>
    </div>

    <mat-card class="share-db" *ngIf="hasLocationSet()">
      <mat-card-content>
        <h3>Invite your friends to join you in {{ getCity() }}.</h3>
        <div *ngIf="showShareButton()">
          <button mat-raised-button (click)="share()" color="primary">
            Invite
          </button>
          <p class="share-or">OR</p>
        </div>
        <referral-link message="Send this link to a friend:"></referral-link>
      </mat-card-content>
    </mat-card>
  </div>
</div>
