<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>
    Hello {{this.currentUser.exists()? (this.currentUser.firestore.displayName$
    | async) : '' }}, <ng-content></ng-content>
    <br>Please <b>go to your email inbox</b> to find the email we sent you and follow the instructions.
  </p>
  <p *ngIf="message">{{message}}</p>
  <p>
    Didn't get an email?
    <span class="link-like" style="color: 'blue'" (click)="resendVerifyEmail()"
      >Click here</span
    >
    to resend the verification email.
  </p>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="later()">Later</button>
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="verifyConfirm()"
  >
    I Verified
  </button>
</mat-dialog-actions>
