<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item *ngIf="showShareButton()" (click)="share()">
        <mat-icon>share</mat-icon>
        <span>Share</span>
    </button>
    <button [cdkCopyToClipboard]="shareURL" mat-menu-item *ngIf="allowShare && !showShareButton()">
        <mat-icon>content_copy</mat-icon>
        <span>Copy link</span>
    </button>
    <button mat-menu-item *ngIf="allowEdit" (click)="triggerEdit($event)">
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
    </button>
    <button mat-menu-item *ngIf="allowDelete" (click)="triggerDelete($event)">
        <mat-icon color="warn">delete</mat-icon>
        <span>Delete</span>
    </button>
    <button mat-menu-item *ngIf="allowReportProduct" (click)="openReportDialog('Product')">
        <mat-icon style="color: #FFB818">report</mat-icon>
        <span>Report product</span>
    </button>
    <button mat-menu-item *ngIf="allowReportUser" (click)="openReportDialog('User')">
        <mat-icon color="warn">report</mat-icon>
        <span>Report user</span>
    </button>
</mat-menu>