import { Inject, Injector, Injectable} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
/**
 * Service used to communicate error information between components and the error-handler class. 
 */
export class ErrorService {

  private authMessageSubject:BehaviorSubject<string> = new BehaviorSubject<string>('');
  public authMessage$:Observable<string> = this.authMessageSubject.asObservable();

  constructor(@Inject(Injector) private injector: Injector) { }

   /**
    * We need to inject the Toastr Service from injector rather than constructor due to nil error.
    * Caused because the ErrorService is injected into the Global ErrorHandler which is outsid eof NgZone (or something like that) - bryson
    */
   private get _toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  setAuthMessage(mes:string){
    this.authMessageSubject.next(mes);
  }

  clearAuthMessage(){
    this.authMessageSubject.next(''); 
  }

  /**
   * Use the toaster alert to inform user of the error. 
   */
  alertError(message:string){
    this._toastr.error(message); 
  }
} 
