<div mat-dialog-content>
  <p>
    <a [routerLink]="" (click)="goTo('/terms-and-conditions')">Terms & Conditions</a>
  </p>
  <p>
    <a [routerLink]="" (click)="goTo('/privacy-policy')">Privacy Policy</a>
  </p>
  <p>
    <a [routerLink]="" (click)="goTo('/contact-form')">Contact Daily Bids</a>
  </p>
  <p>
    <a [routerLink]="" (click)="goTo('/guidelines')">Daily Bids Guidelines</a>
  </p>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()">Close</button>
</mat-dialog-actions>
