import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormDialog {

  message: string = "";
  messageError = false;
  loading = false;
  // wait for iframe to submit
  TIMEOUT = 200;

  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ReportFormDialog>, 
    // CHANGE IN assets/report-form.html if this changes
    @Inject(MAT_DIALOG_DATA) public data: { type: string, id: string, reporterId: string, locId: string },
    private toastr: ToastrService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.message && this.message.length > 1) {
      // switch to firebase function when we have mobile app with notifications?
      this.iframe.nativeElement.contentWindow.postMessage({...this.data, message: this.message});
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.toastr.success("Report submitted")
        this.onNoClick();
      }, this.TIMEOUT)
    } else {
      this.messageError = true;
    }
  }
}
