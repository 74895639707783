import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/core/user.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  templateUrl: './premium-dialog.html',
  styleUrls: ['./premium-dialog.component.scss']
})
export class PremiumDialog {

  constructor(
    public dialogRef: MatDialogRef<PremiumDialog>,
    public currentUser:UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private utils: UtilService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  showLearnMoreLink() {
    return this.router.url !== "/premium";
  }

  copyToClip(){
    console.log("copying")
  }

  resendVerifyEmail(){
    this.currentUser.sendVerificationLink();  
  }

  goPremium() {
    this.utils.dialogNavigate("/premium", this.dialogRef)
  }
}