<mat-card (touchstart)="createTimer()" (touchend)="destroyTimer()" (mouseenter)="createTimer()" (mouseleave)="destroyTimer()">
  <div mat-card-image (click)="openProduct.emit($event)">
    <div
      class="display-image"
      role="img"
      aria-label="Image of product"
      [style.background-image]="!product.deleted ? getImageStyle(product) : ''"
      [class.deleted-prod]="product.deleted"
    >
      <div class="content-wrap" 
        [style.background-image]="productTimer?.running ? 'linear-gradient(black -2%, transparent 75%)' : ''"
      >
        <timer-block
          [id]="getTimerId()"
          [product]="product"
          class="main-page-timer"
          *ngIf="!product.finalized"
        ></timer-block>
        <div class="prod-sold" *ngIf="product.finalized">
          <div *ngIf="product.bidCount > 0; else notSold">Sold</div>
          <ng-template #notSold> Ended </ng-template>
        </div>
        <div *ngIf="product.deleted" class="prod-deleted-label">
          This product was deleted by the owner.
        </div>
      </div>
    </div>
  </div>

  <mat-card-footer>
    <div class="footer-wrap">
      <div class="footer-main">
        <div>
          <mat-icon color="warn" *ngIf="product.bidCount > 4" matTooltip="Item has at least 5 bids">whatshot</mat-icon>
          {{ product.title }} - ${{ getProductDisplayPrice(product) }}
        </div>
        <div>
          <mat-icon matTooltip="pick-up location">place</mat-icon>
          {{ product.pickUpLocation }}
        </div>
      </div>
      <div class="footer-icon" *ngIf="userSignedIn()">
        <per-item-menu
          [allowShare]="true"
          [shareId]="product.id"
          [product]="product"
          [allowReportProduct]="!currentUserIsOwner()"
          [productIdToReport]="product.id"
          [allowEdit]="currentUserIsOwner()"
          [allowDelete]="currentUserIsOwner()"
        ></per-item-menu>
      </div>
    </div>
  </mat-card-footer>
</mat-card>
