export class GoogleLocation {
    id: string;
    name: string;
    
    constructor(id:string, name:string){
        this.id = id ? id : '';
        this.name = name ? name : ''; 
    }

    /**
     * Firestore only accepts pure js objects
     * This converts this class into a pure js object. 
     */
    makeFireBaseFriendly(){
        return Object.assign({}, this); 
    }
    
  }