import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { GlobalConstants } from "src/app/Global";
import { UserService } from "src/app/services/core/user.service";
import { MainProviderService } from "src/app/services/productProviders/main-provider.service";

@Component({
  selector: "all-products",
  templateUrl: "./all-products.component.html",
  styleUrls: ["./all-products.component.scss"],
})
export class AllProductsComponent implements OnInit {
  constructor(
    private title: Title,
    private metaTags: Meta,
    private currentUser: UserService,
    public provider: MainProviderService
  ) {}

  ngOnInit(): void {
    this.title.setTitle(
      "Products in " + this.getCity() + " - " + GlobalConstants.NAME
    );
    this.metaTags.updateTag({
      name: GlobalConstants.DESCRIPTION,
      content:
        "View and bid on used items from " +
        this.getCity() +
        ". Engage in bidding wars that last for 24 hours.",
    });
  }
  
  isPremium(){
    return this.currentUser.isPremium();
  }

  getCity() {
    return this.currentUser.getCityName();
  }
}
