import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {TimerBlockComponent} from 'src/app/modules/reusable/components/products/display-products/timer/timer-block.component'
import { Product } from 'shared/interfaces/product';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ImageHelperService } from 'src/app/services/image-helper.service';
import { UserService } from 'src/app/services/core/user.service';


@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: Product;
  @ViewChild(TimerBlockComponent) productTimer: TimerBlockComponent;
  @Output() openProduct = new EventEmitter<MouseEvent>();

  constructor(private imageService: ImageHelperService, private sanitizer: DomSanitizer,
              private user: UserService) { }

  ngOnInit() {
  }

  userSignedIn(): boolean {
    return this.user.exists();
  }

  currentUserIsOwner(): boolean {
    return this.user.uid() === this.product.posterId;
  }

  getImageStyle(product: Product): SafeStyle {
    let url = this.imageService.getImageMidUrl(product.baseURL, product.images[0])
    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')')
  }

  getProductDisplayPrice(product: Product) {
    return product.winningBid ? product.winningBid.amount : product.startingBid;
  }

  createTimer() {
    if(this.productTimer) {
      this.productTimer.startTimer();
      this.setTimerVisibility("visible");
    }
  }

  destroyTimer() {
    if(this.productTimer) {
      this.productTimer.clearTimer();
      this.setTimerVisibility("hidden");
    }
  }

  getTimerId() {
    return "prod-card-timer-" + this.product.id;
  }

  setTimerVisibility(vis: string) {
    document.getElementById(this.getTimerId()).style.visibility = vis
  }
}
