import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, QuerySnapshot, AngularFirestoreDocument, AngularFirestoreCollectionGroup, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { UserService } from './core/user.service';
import { Product, UploadableProduct, PrivateProductData } from 'shared/interfaces/product';
import { Bid } from 'shared/interfaces/bid';
import { ToastrService } from 'ngx-toastr';
import { GoogleLocation } from 'shared/location';
import { environment } from 'src/environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirestoreError } from '../error-handler/customErrors';
import { catchError } from 'rxjs/operators';
import { GlobalConstants } from '../Global';
import { SharedConstants } from 'shared/constants';

@Injectable({
  providedIn: 'root'
})

// Service to interact products and firebase
export class ProductService {

  private CITIES = "cities";
  private PRODUCTS = "products";

  private PRIVATE_PRODUCT = "/privateProduct/product";
  private BID_LOAD_SIZE = 3;

  private signInProductForm;
  private signInProductId: string;


  constructor(private afs: AngularFirestore, private storage: AngularFireStorage,
    private currentUser: UserService, private toastr: ToastrService, private sanitizer: DomSanitizer,
    private fireFunctions: AngularFireFunctions) { }

  
  /**
   * Get a Firestore reference to a product
   * @param productId id of product
   * @param locationId (optional) id of City (defaults to current city)
   * @returns document refence of product
   */
  productRef(productId: string, locationId: string = this.getCity()): DocumentReference {
    return this.afs.collection(this.CITIES).doc(locationId).collection(this.PRODUCTS).doc(productId).ref;
  }

  /**
   * Add a product to firebase
   * 
   * @param product this contains the data to be uploaded as the product, must contain a unique id
   */
  async addProduct(product): Promise<any> {
    let uploadableProduct = product as UploadableProduct;
    // Fill in remaining entries
    uploadableProduct.uploadTime = firebase.default.firestore.FieldValue.serverTimestamp();
    uploadableProduct.relevantTime = firebase.default.firestore.FieldValue.serverTimestamp();
    uploadableProduct.bidCount = 0;
    uploadableProduct.finalized = false;
    uploadableProduct.bumpsUsed = 0;
    uploadableProduct.hasProfilePic = this.currentUser.firestore.hasProfilePic;
    if(this.referralConditionsMet(product)) 
      uploadableProduct.hasReferral = true;
    const privateProduct = this.makeProductPrivate(uploadableProduct);

    const prodRef = this.productRef(product.id, product.locationID);
    const batch = this.afs.firestore.batch();

    batch.set(prodRef, uploadableProduct)
      .set(this.afs.doc(prodRef.path + this.PRIVATE_PRODUCT).ref, privateProduct);
    return batch.commit()
      .then(() => {
        if(this.referralConditionsMet(product)){
          this.toastr.success(
            `When your item has sold you will be awarded one month of Premium`,
            `Referral link detected!`,
            { timeOut: 10000 } //Twice as long as usual to allow for reading. 
          );
        }})
      .catch(e => new FirestoreError(e, 2110))
  }

  /**
   * Get the private data that is associated with the  given product.
   * 
   * @param product Product that contains the firestore path info to the private document
   * @returns an observable for the private data
   */
  getPrivateProduct(product: Product): Observable<PrivateProductData> {
    return this.afs
      .doc<PrivateProductData>([this.CITIES, product.locationID, this.PRODUCTS, product.id].join("/") + this.PRIVATE_PRODUCT)
      .valueChanges()
      .pipe(catchError(e => { throw new FirestoreError(e, 2111) }));
  }

  /**
   * When a user edits their product, this will update it in Firestore
   * 
   * @param product value of product form
   * @param includeStartingBid true if the starting bid can be edited
   * @returns a promise for when the update is complete
   */
  async updateProduct(product, includeStartingBid: boolean): Promise<any> {
    const newPublic = {};
    const newPrivate = {};

    GlobalConstants.EDITABLE_PRODUCT_FIELDS.forEach(field => {
      if (product[field] != null) {
        newPublic[field] = product[field];
      }
    });

    if (!includeStartingBid) delete newPublic['startingBid'];

    newPublic['editedTime'] = firebase.default.firestore.FieldValue.serverTimestamp();

    GlobalConstants.EDITABLE_PRIVATE_FIELDS.forEach(field => {
      if (product[field] != null) {
        newPrivate[field] = product[field];
      }
    });

    const prodRef = this.productRef(product.id, product.locationID);
    const batch = this.afs.firestore.batch();

    batch.update(prodRef, newPublic)
      .update(this.afs.doc(prodRef.path + this.PRIVATE_PRODUCT).ref, newPrivate);
    return batch.commit().catch(e => new FirestoreError(e, 2120))
  }

  /**
   * Given a product form, generate the preivew of that product
   * @returns a product object that can be displayed
   */
  getPreviewOfProduct(product): Product {
    let prod = product as Product;
    prod.posterName = this.currentUser.firestore.displayName;
    prod.posterId = this.currentUser.uid();
    prod.uploadTime = firebase.default.firestore.Timestamp.fromDate(new Date());
    prod.bidCount = 0;
    prod.finalized = false;
    prod.bumpsUsed = 0;
    prod.hasProfilePic = this.currentUser.firestore.hasProfilePic;
    return prod;
  }

  /**
   * (1) Poster's referId is not empty or DEFAULT.
   * (2) Poster's referId is not their own us i Id. 
   * (3) This is poster's first product ever posted/sold.
   * @returns boolean true if above are met, false otherwise. 
   */
  private referralConditionsMet(product: Product):boolean{
    const posterReferId = this.currentUser.firestore.referId;
    const posterItemsSold = this.currentUser.firestore.itemsSold;
    return posterReferId && posterReferId.length > 1
       && posterReferId !== product.posterId 
       && posterItemsSold == 0;
  }

  /**
   * Get a product based on the current city as defined in user account service. 
   * Does not listen to changes.
   * 
   * @param id id of product to get
   * @param locId Id of location (usually city)
   * @returns Observable of this product
   */
  getProductFromCityOnce(id: string, locId = this.getCity()): Observable<DocumentData> {
    return this.getProductInCityRef(id, locId).get()
      .pipe(catchError(e => { throw new FirestoreError(e, 2112) }));;
  }

  /**
   * Get a product based on the current city as defined in user account service. 
   * Listens to changes.
   * 
   * @param id id of product to get
   * @param locId Id of location (usually city)
   * @returns Observable of this product
   */
  getProductFromCityListen(id: string, locId = this.getCity()): Observable<Product> {
    return this.getProductInCityRef(id, locId).valueChanges()
      .pipe(catchError(e => { throw new FirestoreError(e, 2113) }));;
  }

  /**
   * Get the Firestore reference to the product from the current city.
   * 
   * @param id id of product
   * @param locId Id of location (usually city)
   */
  private getProductInCityRef(id: string, locId: string): AngularFirestoreDocument<Product> {
    return this.afs.collection(this.CITIES).doc(locId).collection(this.PRODUCTS).doc(id)
  }

  /**
   * Get a product from any city. Does not listen to changes.
   * 
   * @param id id of product to get
   * @returns Observable of this product
   */
  getProductFromAnywhereOnce(id: string): Observable<QuerySnapshot<DocumentData>> {
    return this.getProductFromAnywhereRef(id).get()
      .pipe(catchError(e => { throw new FirestoreError(e, 2114) }));;
  }

  /**
   * Get a product from any city and listen to changes. The first document 
   * in the array is the product.
   * 
   * @param id id of product to get.
   * @returns Observable of this product
   */
  getProductFromAnywhereListen(id: string): Observable<Product[]> {
    return this.getProductFromAnywhereRef(id).valueChanges()
      .pipe(catchError(e => { throw new FirestoreError(e, 2115) }));;
  }

  /**
   * Get the Firestore reference to the product from any city.
   * 
   * @param id id of product
   */
  private getProductFromAnywhereRef(id: string): AngularFirestoreCollectionGroup<Product> {
    return this.afs.collectionGroup(this.PRODUCTS, ref => ref.where('id', '==', id));
  }

  /**
   * Given a download url of object in firebase storage, return a reference (useful to delete this item)
   * 
   * @param url download url to find
   * @returns Firebase Reference to this item
   */
  getRefFromUrl(url: string): firebase.default.storage.Reference {
    return this.storage.storage.refFromURL(url)
  }

  /**
   * Given a product, retrieve an observable of that product's bids.
   * Orders where most recent bids are first.
   * 
   * @param product the product of interest
   * @returns Observable of bids on this product
   */
  getRecentBids(product: Product): Observable<Bid[]> {
    return this.afs.collection(this.CITIES)
      .doc(product.locationID)
      .collection(this.PRODUCTS)
      .doc(product.id)
      .collection<Bid>('bids', ref => ref
        .limit(this.BID_LOAD_SIZE)
        .orderBy('bidTime', 'desc'))
      .valueChanges()
      .pipe(catchError(e => { throw new FirestoreError(e, 2116) }));
  }

  getNextBids(product: Product, lastBid: Bid) {
    return this.afs.collection(this.CITIES)
    .doc(product.locationID)
    .collection(this.PRODUCTS)
    .doc(product.id)
    .collection<Bid>('bids', ref => ref
      .limit(this.BID_LOAD_SIZE)
      .orderBy('bidTime', 'desc')
      .startAfter(lastBid.bidTime))
    .valueChanges()
    .pipe(catchError(e => { throw new FirestoreError(e, 2119) }));
  }

  /**
   * Places a bid in the bid collection for the product, updates that products current price, and
   * increments the bidcount for that product, resets the relevant timestamp.
   * 
   * @param amount value of bid
   * @param product product the bid is on
   * @param uid the user id who placed the bid
   * @param username the username for who placed the bid
   */
  placeBid(amount: number, product: Product, uid, username): Promise<void> {
    let timestamp = firebase.default.firestore.FieldValue.serverTimestamp();

    this.addToWatchList(product.id);

    const id = this.afs.createId();
    return this.afs.collection(this.CITIES)
      .doc(product.locationID)
      .collection(this.PRODUCTS)
      .doc(product.id)
      .collection('bids')
      .doc(id)
      .set({
        id: id,
        userId: uid,
        username: username,
        productId: product.id,
        amount: amount,
        bidTime: timestamp,
        hasProfilePic: this.currentUser.firestore.hasProfilePic
      }).catch(e => { throw new FirestoreError(e, 2117) });

    // Post bid logic has been moved to firebase function
  }

  addToWatchList(productId: string) {
    if (!this.currentUser.firestore.bids.includes(productId)) {
      this.currentUser.firestore.pushBids(productId);
    }
  }

  removeFromWatchList(productId: string) {
    if (this.currentUser.firestore.bids.includes(productId)) {
      this.currentUser.firestore.removeItemFromBids(productId)
    }
  }

  /**
   * Reset the relevant timestamp for the product so that it appears first on the main
   * page to view products.
   * 
   * @param product product to bump
   */
  bumpProduct(product: Product) {
    return this.afs.collection(this.CITIES).doc(product.locationID).collection(this.PRODUCTS).doc(product.id)
      .update({
        relevantTime: firebase.default.firestore.FieldValue.serverTimestamp(),
        bumpsUsed: firebase.default.firestore.FieldValue.increment(1)
      }).catch(e => {throw new FirestoreError(e, 2118)});
  }

  /**
   * Delete a product from the main 'products' collection in Firestore. This
   * should be used when an owner manually deletes a product.
   * 
   * @param product the product to delete
   */
  delete(product: Product) {
    if (this.currentUser.uid() == product.posterId) {
      this.toastr.info("Deleting product...")
      const callable = this.fireFunctions.httpsCallable("httpsDeleteProduct");
      callable(product).toPromise().then(() => {
        this.toastr.success(product.title + " was successfully deleted. You can refresh to verify.")
      }).catch(err => {
        throw new FirestoreError("Product could not be deleted: " + err, 2000)
      });
    }
  }

  saveIdForAfterSignin(id: string) {
    this.signInProductId = id;
  }

  hasSignInProductId() {
    return false;
  }

  getSignInProductId(): string {
    const id = this.signInProductId;
    this.signInProductId = null;
    return id;
  }

  setSignInProductForm(form) {
    this.signInProductForm = form;
  }

  hasSignInProductForm() {
    return this.signInProductForm && this.signInProductForm != null;
  }

  getSignInProductForm() {
    return this.signInProductForm;
  }

  private makeProductPrivate(uploadableProduct: UploadableProduct): PrivateProductData {
    const number = uploadableProduct['posterNumber'];
    const email = uploadableProduct['posterEmail'];
    delete uploadableProduct['posterEmail'];
    delete uploadableProduct['posterNumber'];

    return {
      posterEmail: email,
      posterNumber: number
    };
  }

  /**
   * @param product the product to find a google map url for
   * @returns a Location object with the safe url and the name of the location
   */
  getGmapUrlFromProduct(product: Product): SafeResourceUrl {

    const productCity = new GoogleLocation(product.locationID, product.location);
    const mapLoc = new GoogleLocation(product.mapLocationId, product.mapLocationName);
    return this.getGmapUrl(productCity, mapLoc);
  }

  /**
   * @param city where product is posted in
   * @param mapLoc (optional) where the map should point to
   * @returns a Location object with the safe url and the name of the location
   */
  getGmapUrl(productCity: GoogleLocation, mapLoc: GoogleLocation = new GoogleLocation("", "")): SafeResourceUrl {
    let locId = "";
    if (mapLoc.id.length > 1) {
      locId = mapLoc.id;
    } else {
      locId = productCity.id;
    }

    const url = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.google.com/maps/embed/v1/place?key=" + environment.gcpKey + "&q=place_id:" + locId);

    return url;
  }

  // Should return the users current selected city.
  // This is tempoary and should be replaced by something in user account
  private getCity(): string {
    return this.currentUser.getLocation().id;
  }
}
