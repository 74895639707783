import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { GlobalConstants } from 'src/app/Global';
import { Product } from 'shared/interfaces/product';
import { ScreenSizeService } from '../screen-size.service';
import { UserService } from '../core/user.service';
import { ProductProvider } from './productProvider';
import { catchError } from 'rxjs/operators';
import { FirestoreError } from 'src/app/error-handler/customErrors';

@Injectable({
  providedIn: 'root'
})

// Could order products by bid count or by recently posted
// Service to view all products in a given city
export class MainProviderService implements ProductProvider {

  constructor(private afs: AngularFirestore, private currentUser: UserService, private screenSize: ScreenSizeService) { }

  /**
   * Get the initial products to display to the user, orders by upload time.
   * 
   * @returns The batch of products to display
   */
  getInitialProducts(): Observable<Product[]> {
    if (!this.getCity() || this.getCity().length < 2) return null;
    const batchSize = (this.screenSize.isSmallScreen(GlobalConstants.MOBILE_SCREEN_THRESHOLD)) ? 
                      GlobalConstants.MOBILE_BATCH_SIZE : GlobalConstants.INITIAL_PROD_BATCH_SIZE_WHEN_ADS_PRESENT;
    return this.afs.collection('cities')
    .doc(this.getCity())
    .collection<Product>('products', ref => ref.limit(batchSize)
    .orderBy('relevantTime', 'desc'))
    .valueChanges()
    .pipe(catchError(e =>{ throw new FirestoreError(e, 2101)}));
  }
  
  /**
   * Get next batch of prodcuts, ordered by upload time, starting after the given timestamp.
   * 
   * @param lastTimeStamp is the timestamp of the last product display from the previous batch
   * @returns The next batch of products to display
   */
  getNextProducts(lastTimeStamp): Observable<Product[]> {
    if (!this.getCity() || this.getCity().length < 2) return null;
    const batchSize = (this.screenSize.isSmallScreen(GlobalConstants.MOBILE_SCREEN_THRESHOLD)) ? 
                      GlobalConstants.MOBILE_BATCH_SIZE : GlobalConstants.PROD_BATCH_SIZE;
    return this.afs.collection('cities')
    .doc(this.getCity())
    .collection<Product>('products', ref => ref.limit(batchSize)
    .orderBy('relevantTime', 'desc')
    .startAfter(lastTimeStamp))
    .valueChanges()
    .pipe(catchError(e =>{ throw new FirestoreError(e, 2102)}));

  }

  /**
   * Get the initial products to display to the user on profile page, orders by upload time.
   * 
   * @returns The batch of products to display
   */
  getInitialProductsByUser(id: string): Observable<Product[]> {
    const batchSize = (this.screenSize.isSmallScreen(GlobalConstants.MOBILE_SCREEN_THRESHOLD)) ? 
                      GlobalConstants.MOBILE_BATCH_SIZE : GlobalConstants.PROD_BATCH_SIZE;
    return this.afs
    .collectionGroup<Product>('products', ref => ref.limit(batchSize)
    .where('posterId', '==', id)
    .orderBy('uploadTime', 'desc'))
    .valueChanges()
    .pipe(catchError(e =>{ throw new FirestoreError(e, 2103)}));

  }
  
  /**
   * Get next batch of productss for profile page, ordered by upload time, starting after the given timestamp.
   * 
   * @param lastTimeStamp is the timestamp of the last product display from the previous batch
   * @returns The next batch of products to display
   */
  getNextProductsByUser(id: string, lastTimeStamp): Observable<Product[]> {
    const batchSize = (this.screenSize.isSmallScreen(GlobalConstants.MOBILE_SCREEN_THRESHOLD)) ? 
                      GlobalConstants.MOBILE_BATCH_SIZE : GlobalConstants.PROD_BATCH_SIZE;
    return this.afs
    .collectionGroup<Product>('products', ref => ref.limit(batchSize)
    .where('posterId', '==', id)
    .orderBy('uploadTime', 'desc')
    .startAfter(lastTimeStamp))
    .valueChanges()
    .pipe(catchError(e =>{ throw new FirestoreError(e, 2104)}));

  }

  // Should return the users current selected city
  private getCity(): string {
    return this.currentUser.getLocation().id;
  }
}
