import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "contact-form",
  templateUrl: "contact-form.component.html",
})
export class ContactFormComponent {
  public screenHeight;
  constructor() {
    this.screenHeight = Math.min(document.body.offsetHeight - 70, 850);
  }

  getWidth() {
    return document.body.offsetWidth;
  }
}

@Component({
  selector: "contact-dialog",
  templateUrl: "contact-dialog.html",
})
export class ContactDialog {
  constructor(public dialogRef: MatDialogRef<ContactDialog>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
