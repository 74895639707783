import { Injectable, isDevMode } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Subscription, BehaviorSubject, Observable, of } from "rxjs";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { GlobalConstants } from "../Global";
import { FirestoreError, NotificationError } from '../error-handler/customErrors';
import { Notification } from 'shared/interfaces/notification';
import { catchError, mergeMapTo, take } from 'rxjs/operators';
import { Router } from "@angular/router";
import { ConvoNotificationData, NotificationData } from "shared/interfaces/messagingNotification"

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  subs: Subscription[] | undefined = [];
  private NOTIFICATION_SIZE = 10;
  private PAGE_SIZE = GlobalConstants.NOTIFICATION_BATCH_SIZE; // Max number of new notifications to show on page

  notifications: Notification[] = [];
  notificationsSubject: BehaviorSubject<Notification[]> = new BehaviorSubject([]);
  subIsSet = false; // We don't want to spam the user with notifications when they first sign in
  // if they get a new notification while signed in, we should alert them. This boolean controls that.

  constructor(private afs: AngularFirestore, private router: Router, private afMessaging: AngularFireMessaging, private toastr: ToastrService) {}

  /**
   * Listen to the notifications for the signed in user, if a user
   * receives a new notification while signed in, there will be  a
   * toastr pop up of the notification.
   *
   * @param uid Unique id of the signed in user
   * @throws NotificationError
   * @throws FirestoreError
   */
  async subscribe(uid: string) {
    let sub = this.afs
    .collection("users")
    .doc(uid)
    .collection<Notification>("notifications", (ref) =>
      ref
        .limit(this.NOTIFICATION_SIZE)
        .where("new", "==", true)
        .orderBy("timestamp", "desc")
    )
    .valueChanges()
    .pipe(catchError(err => {throw new FirestoreError(err, 2017)}))
      .subscribe(
        (res) => {
          if (this.subIsSet) this.notifyNew(res as Notification[]);
          this.subIsSet = true;
          this.notifications = res as Notification[];
          this.notificationsSubject.next(this.notifications);
        },
        (err) => {throw new NotificationError(err, 1000)}
      );
      this.subs.push(sub)
      if(isDevMode()) console.log("Subscribing to fireMessaging Messages")
      let mesSub = this.afMessaging.messages.subscribe(
        message => {
          if(isDevMode()) console.log("Got a message from chrome creating notification!")
          const data = message['data'] as NotificationData;
          if (data.type === "convo" && !this.router.url.includes("messenger")) {
            const msgData = data as ConvoNotificationData;
            this.toastr.info(msgData.content, `You got a message from ${msgData.senderName}`)
            .onTap.pipe(take(1)).subscribe(() => {
              //TODO currently the messsage object sent by our function -> functions\src\https\notifications.f.ts does not contain convoId information
              // let convoId = message['conversationId']; 
              this.router.navigate(["messenger"]);
            })
          }
        }, error => {
          throw new NotificationError(`Error subscribing to afMessaging Messages: ${error}`, 1004)
        }
      );
      this.subs.push(mesSub)
  }

  /**
   * Clear the notifications in this service and unsubscribe.
   * Use when a user signs out.
   */
  clear() {
    this.subIsSet = false;
    this.subs.forEach(sub => { sub.unsubscribe() })
    this.notifications = [];
  }

  /**
   * Get the notifications marked new and ordered by time (descending).
   *
   * @param uid Unique of id of user to get notifications for
   * @returns Observable of the new notifications
   */
  getNewNotifications(uid: string): Observable<Notification[]> {
    return of(this.notifications);
  }

  /**
   * Get next set of notifications as an observable ordered by time (descending).
   * 
   * @param uid Unique of id of user to get notifications for
   * @param lastTimeStamp Notifications returned will be before this timestamp
   * @returns Observable of the next notifications
   * @throws FirestoreError
   */
  getNextBatch(
    uid: string,
    lastTimeStamp: firebase.default.firestore.Timestamp
  ): Observable<Notification[]> {
    return this.afs
      .collection("users")
      .doc(uid)
      .collection<Notification>("notifications", (ref) =>
        ref
          .limit(this.PAGE_SIZE)
          .orderBy("timestamp", "desc")
          .startAfter(lastTimeStamp)
      )
      .valueChanges()
      .pipe(catchError(err => {throw new FirestoreError(err, 2018)}))
  }

  /**
   * Mark a notification as not new
   * 
   * @param uid User id of user who has this notification
   * @param notificationId id of notification to mark as not new
   * @throws FirestoreError
   */
  markAsOld(uid, notificationId) {
    try{
      this.afs
        .collection("users")
        .doc(uid)
        .collection("notifications")
        .doc(notificationId)
        .update({ new: false });
    }catch(error){throw new FirestoreError(error, 2015)}
  }

  /**
   * Mark a notification as read
   * 
   * @param uid User id of user who has this notification
   * @param notificationId id of notification to mark as read
   * @throws FirestoreError
   */
  markAsRead(uid, notificationId) {
    try{
      this.afs
        .collection("users")
        .doc(uid)
        .collection("notifications")
        .doc(notificationId)
        .update({ read: true });
    }catch(error){throw new FirestoreError(error, 2016)}
  }

  // Weed out the new notifications and send them to toastr
  private notifyNew(nots: Notification[]) {
    const newNots = nots.filter((not) => {
      const found = this.notifications.some((oldNot) => oldNot.id == not.id);
      return !found;
    });
    if (newNots.length > 0) this.notify(newNots[0]);
  }

  /**
  * Requests permissions to send notifications to the current user
  * @param firestore: FireStoreUser, access to a method is needed
  * @param currentUser User obj of user who is asked for this permission
   * @throws NotificationError
  */
  public requestNotificationPermission(firestore) {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(token => { 
          if(!(firestore.notificationTokens && firestore.notificationTokens.includes(token))){
            firestore.pushNotificationTokens(token);
          }
        },
        (error) => {throw new NotificationError(error, 1002)},  
      );
  }

  /**
   * Get the browsers notification token as a promise
   * @throws NotificationError
   */
  public getToken():Promise<string>{
    return this.afMessaging.getToken.toPromise()
    .catch(error => {throw new NotificationError(error, 1005)})
  }

  private notify(not: Notification) {
    if (not.productRelated && location.pathname.includes(not.productId)) return;
    // for now just one at a time. It is unlikely there would be more than one here
    let active: ActiveToast<any> = null
    switch (not.style) {
      case "info":
        active = this.toastr.info(not.message, not.title);
        break;
      case "success":
        active = this.toastr.success(not.message, not.title);
        break;
      case "error":
        active = this.toastr.error(not.message, not.title);
        break;
      case "warning":
        active = this.toastr.warning(not.message, not.title);
        break;
      default:
        throw new NotificationError("Notification style undefined: " + not.style, 1001);
    }

    if (active) {
      active.onTap.pipe(take(1)).subscribe(res => {
        if (not.productRelated) {
          this.router.navigate(["/products/" + not.productId]);
        } else {
          this.router.navigate(["/notifications"])
        }
      })
    }
  }
}
