<h1 mat-dialog-title>Refer Friends & Get Premium</h1>
<div mat-dialog-content>
  <ol>
    <li>Your friend joins Daily Bids with your link</li>
    <li>They sell their first item</li>
    <li>You each get one month of Daily Bids Premium!</li>
    <a *ngIf="showLearnMoreLink()" [routerLink]="" (click)="goPremium()">Learn about Daily Bids Premium</a>
  </ol>
 <referral-link focusInitial="true"></referral-link> 

</div>
<div mat-dialog-actions align="end">
 <button mat-raised-button mat-dialog-close>Ok</button>
</div>
