<div mat-dialog-content>
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLSc56x3sqtpbUYO4Eqzw8SOlNx3AZWGzWxO6IuQplbXAyXIvCw/viewform?embedded=true"
    [width]="520"
    height="500"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    >Loading…</iframe
  >
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()">Close</button>
</mat-dialog-actions>
