import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Conversation } from '../objects/conversation';
import { Message, UploadableMessage } from '../objects/message';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ProductService } from './product.service';
import { Product } from 'shared/interfaces/product';
import { PublicFireStoreUser } from 'shared/interfaces/fireStoreUserI';
import { ConversationFirestore } from 'shared/interfaces/conversation';
import { Router } from '@angular/router';
import { FirestoreError } from '../error-handler/customErrors';
import * as firebase from 'firebase/app'
import { GlobalConstants } from '../Global';
import { UserService } from './core/user.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private CITIES_REF:AngularFirestoreCollection<any>; 
  private MESSAGE_PAGE_SIZE; 

   //Just to prevent making a typo. 
  private CONVERSATIONS = 'conversations';
  private PRODUCTS = 'products';
  private MESSAGES = 'messages';

  constructor(private afs: AngularFirestore, private _product: ProductService, private router: Router, private currentUser: UserService){
    this.CITIES_REF = this.afs.collection('cities');
    this.MESSAGE_PAGE_SIZE = GlobalConstants.MESSAGE_PAGE_SIZE;
  }

  /**
   * Store the message in both user's collections. 
   * Assumes that the user logged in is sending the message. 
   * @param content string content of the message
   * @param conversationId context of message
   */
  sendMessage(content:string, conversation:Conversation){
    let message:UploadableMessage = {
      id: this.afs.createId(),
      content: content,
      timeStamp: firebase.default.firestore.FieldValue.serverTimestamp(),
      sentBy: this.currentUser.uid(),
    }
    var convoDoc = this.refFromConversation(conversation);
    var batch = this.afs.firestore.batch(); 
    
    let key = conversation.seller ? 'bidder' : 'seller';
    var updateDocObj = {
      lastMessageSent:message.timeStamp,
    };
    updateDocObj[key+'UnReadMessages'] = firebase.default.firestore.FieldValue.increment(1); 
    batch.set(convoDoc.ref, updateDocObj, {merge:true});
    batch.set(convoDoc.collection<UploadableMessage>(this.MESSAGES).doc(message.id).ref, message);
    return batch.commit()
    .catch(e => {throw new FirestoreError(e, 2010)}); 
  }

/**
 * Mark a conversation read by setting `unReadMessages` to 0
 * @param cityId id of city conversation is in.
 * @param productId id of product conversation is under
 * @param conversationId id of conversation 
 */
  markAsRead(convo:Conversation){
    let key = convo.seller ? 'seller' : 'bidder';
    let setVal = {};
    setVal[key+'UnReadMessages'] = 0; 
    return this.refFromConversation(convo)
    .set(setVal,{merge:true})
    .catch(e => {throw new FirestoreError(e, 2009)});
  }
  
  private refFromConversation(convo:Conversation){
    return this.CITIES_REF
        .doc(convo.locationId)
        .collection(this.PRODUCTS)
        .doc(convo.productId)
        .collection<any>(this.CONVERSATIONS)
        .doc(convo.id); 
  }
  
  /**
   * Gets an actively listening  observable list of messages for a particular user's conversation.
   * Will need to subscribe and ubsubscribe in client. 
   * @param userId The user to get messages for. 
   * @param conversationID The id of the conversation where the messages exist
   */
  getInitialMessages(convo:Conversation):Observable<Message[]>{
    return this.refFromConversation(convo)
    .collection<Message>(this.MESSAGES, (ref) =>
      ref
      .orderBy("timeStamp", "asc")
      .limitToLast(this.MESSAGE_PAGE_SIZE)
    ).valueChanges().pipe(catchError(e =>{ throw new FirestoreError(e, 2008)}),take(1));
  }

  /**
   * Listen for all new messages that come after `lastTS`. 
   * @param convo conversation to listen for. 
   * @param lastTS The timestamp to listen forward from. 
   */
  listenForNewMessages(convo:Conversation, lastTS){
    return this.refFromConversation(convo).collection<Message>(this.MESSAGES, (ref) => 
      ref.orderBy("timeStamp", "asc").startAfter(lastTS)
    ).valueChanges().pipe(catchError(e => {throw new FirestoreError(e, 2007)}));
  }

  /**
   * Get next set of messages as an observable ordered by time (descending).
   * @param conversation the conversation that the messages are in. 
   * @param lastTimeStamp Messages returned will be before this timestamp
   * @returns Observable of the next messages
   */
    getNextBatch(
      conversation: Conversation,
      lastTimeStamp: any
    ): Observable<Message[]> {
      return this.refFromConversation(conversation)
        .collection<Message>(this.MESSAGES, (ref) => 
          ref.orderBy("timeStamp", "asc")
          .endBefore(lastTimeStamp)
          .limitToLast(this.MESSAGE_PAGE_SIZE)
        ).valueChanges().pipe(catchError(e => {throw new FirestoreError(e, 2006)}), take(1));
      } 

  /**
   * Sets the conversation to open. 
   * @param conversationId open up to this conversation upon opening
   */
  public open(conversationId){
    this.router.navigate(['/messenger'], { queryParams: { 'conversationId': conversationId } });
  }

  /**
   * Creates a new conversation collection in firestore if it doesn't exist.
   * @param sellerId id of user 
   * @param buyerId id of user
   * @param productId id of product context
   * @param locationId id of city the product exists in, optinal, if not passed assumed to be current location. 
   * @returns firestore id of the newly created (or already exsiting) conversation.
   */
  public async createConversation(sellerId, buyerId, productId, locationId = null):Promise<ConversationFirestore>{
    if(!locationId) locationId = this.currentUser.getLocation().id;
    let conversationId = `${sellerId}-${buyerId}-${productId}`; //This should be unique but able to query for existance in firestore. 
    let data = await this.CITIES_REF.doc(locationId)
    .collection('products')
    .doc<Product>(productId)
    .collection(this.CONVERSATIONS)
    .doc(conversationId).get()
    .pipe(catchError(e => {throw new FirestoreError(e, 2004)}))
    .toPromise()
    //Check if exists already, if not, create it. 
    if(data.exists) return data.data() as ConversationFirestore;
    //get product information. 
    let conversation = await this.createConversationDocument({conversation: conversationId, seller: sellerId, buyer: buyerId, product: productId, location: locationId});
    try{
      await this.CITIES_REF.doc(locationId).collection('products').doc<Product>(productId).collection(this.CONVERSATIONS).doc(conversationId).set(conversation)
    }catch(err){ throw new FirestoreError(err, 2005)}
    return conversation;
  }

  /**
   * Helper method for creating the data that should go be used to create Conversation docs in firestore. 
   * @param ids All the relevant ids i.e conversation, product, seller, buyer in a js object
   * @returns Promise of a Conversation, ready to be put into firestore. 
   */
  private async createConversationDocument(ids:any):Promise<ConversationFirestore>{
    let prodTitle, baseURL, imageName, buyerName, sellerName;
    let data = await this._product.getProductFromAnywhereOnce(ids.product).pipe(catchError(e => { throw new FirestoreError(e, 2003)})).toPromise()
    if(!data.empty){
      var prod = data.docs[0].data() as Product; 
      baseURL = prod.baseURL;
      imageName = prod.images[0];
      prodTitle = prod.title;
    }
    
    //Error handling is performed in the UserService, so no need to add handling here. 
    sellerName = await this.currentUser.getDisplayNameFromId(ids.seller);
    buyerName = await this.currentUser.getDisplayNameFromId(ids.buyer);
    
    let unReadObj = {}
    unReadObj[ids.seller] = 1; //Initialize at one other wise for some reason firebase collapses this map into a single 0
    unReadObj[ids.buyer] = 1;
    let convo:ConversationFirestore = {
      id: ids.conversation,
      productId: ids.product,
      bidderId: ids.buyer,
      sellerId: ids.seller,
      locationId: ids.location,
      
      productImageBaseURL: baseURL,
      productImageName: imageName,
      productTitle: prodTitle,
      sellerName: sellerName,
      bidderName: buyerName,
      participants: [ids.seller, ids.buyer], 

      sellerUnReadMessages: 0, 
      bidderUnReadMessages: 0, 
      lastMessageSent: firebase.default.firestore.FieldValue.serverTimestamp(), //Even tho this isnt technically a message sent, it works for ordering purposes. 
    }

    return convo;
  }
}
