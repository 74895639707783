<div
  class="photo-container"
  [class.photo-container-editable]="editable"
  [id]="getPhotoContainerId()"
  (click)="editProfilePic()"
>
  <img
    [defaultImage]="defaultImage"
    [lazyLoad]="imageURL"
    class="contact-photo"
    offset="50"
    alt="profile picture"
  />
  <div class="middle" *ngIf="editable">
    <div class="edit-contact-photo">Change</div>
  </div>
</div>

<!-- Hide modals if profile pic is not editable -->
<div *ngIf="editable">
  <!-- <clr-modal [(clrModalOpen)]="editingProfilePic" [clrModalClosable]="true">
    <label class="modal-title">Update Profile Picture</label>
    <div class="modal-body" [class.hide-content]="loadingNewContactPhoto">
      <div class="contact-photo-upload-body">
        <p *ngIf="!cropperIsReady && !loadingNewContactPhoto">
          Please select your next contact photo
        </p>
        <p *ngIf="imageUploadFailed">Image upload failed, please try again.</p>
        <input
          #inputImageFile
          type="file"
          name="pic"
          accept="image/*"
          (change)="fileChangeEvent($event)"
        />
        <p *ngIf="cropperIsReady" style="margin: 10px;">
          Drag to adjust image or rotate:
          <span *ngIf="rotatingImage" class="spinner spinner-inline">
            Loading...
          </span>
          <clr-icon
            *ngIf="!rotatingImage"
            shape="redo"
            (click)="rotateRight()"
            style="cursor: pointer;"
          ></clr-icon>
        </p>
        <image-cropper
          class="image-cropper"
          [imageChangedEvent]="imageChangedEvent"
          [containWithinAspectRatio]="true"
          [roundCropper]="true"
          [resizeToWidth]="resizeTo"
          format="png"
          alignImage="left"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
        >
        </image-cropper> -->
        <!-- If we want to display what the picuture will look like.. -->
        <!-- <h2 *ngIf="cropperIsReady">Result: </h2>
                <div class="cropped-image-result" id="cropped-avatar-result">
                    <img [src]="croppedImage" style="border-radius: 100%" />
                </div> -->
        <!-- <div *ngIf="loadingNewContactPhoto">
          <span class="spinner spinner-inverse loading">
            Loading...
          </span>
        </div>
      </div>
    </div>
    <div class="modal-footer" [class.hide-content]="loadingNewContactPhoto">
      <button type="button" class="btn btn-outline" (click)="closePicModal()">
        Cancel
      </button>
      <button
        *ngIf="hasProfilePic() && !imageIsLoaded"
        type="button"
        class="btn btn-warning"
        (click)="showDeleteConfirmation()"
      >
        Delete
      </button>
      <button
        *ngIf="imageIsLoaded"
        type="submit"
        class="btn btn-primary"
        [disabled]="!cropperIsReady"
        (click)="this.changePhoto()"
      >
        Update
      </button>
    </div>
  </clr-modal>

  <clr-modal [(clrModalOpen)]="confirmDelelePic" [clrModalClosable]="true">
    <label class="modal-title">Confirm Delete</label>
    <div class="modal-body" [class.hide-content]="deletingImage">
      <p>Are you sure you want to delete your profile picture?</p>
      <div class="delete-photo-container">
        <img [(src)]="this.imageURL" class="contact-photo" />
      </div>
      <div *ngIf="deletingImage">
        <span class="spinner spinner-inverse loading">
          Loading...
        </span>
      </div>
      <p *ngIf="failedDeleteImage">
        Please try again, failed to delete image with: {{ deleteErrorMessage }}.
      </p>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline"
          (click)="confirmDelelePic = false"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-warning"
          (click)="deleteProfilePic()"
        >
          Delete
        </button>
      </div>
    </div>
  </clr-modal> -->
</div>
