import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'referral-link',
  templateUrl: './referral-link.component.html',
  styleUrls: ['./referral-link.component.scss']
})
export class ReferralLinkComponent implements OnInit {

  @Input() message = "Share your personal referral link with friends"
  @Input() focusInitial = false;

  link:string;
  constructor(private currentUser:UserService, private toaster: ToastrService) {
  }

  ngOnInit(): void {
    this.link = this.createLink();
  }

  createLink(){
    return this.currentUser.createReferralLink();
  }

  linkCopied() {
    this.toaster.success("Link copied to your clipboard");
  }

}
