<div class="prod-wrap">
  <products
    *ngIf="ready"
    [pageKey]="'search-page-' + searchTerm"
    [displayTitle]="'Products in ' + getCity()"
    [productProvider]="this.provider"
    [showAds]="!isPremium()"
    [adSearchTerm]="searchTerm"
    noResultsText="No results found, please try a different search."
  ></products>
</div>
