<div class="products-wrapper" #prodWrap [style.minHeight]="getMinHeight(prodWrap)">
  <div
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="10"
    (scrolled)="onScroll()"
  >
    <div class="above">
      <h2 *ngIf="displayTitle && displayTitle.length > 0">
        {{ displayTitle }}
      </h2>

      <button
        *ngIf="showRefreshButton"
        mat-raised-button
        (click)="getProducts(true)"
      >
        Refresh <mat-icon>refresh</mat-icon>
      </button>
    </div>

    <div class="alert" *ngIf="nothingFound">
       <alert [message]="noResultsText"></alert>
    </div>

    <div class="loading-products" *ngIf="loadingFirst">
      <loading-icon></loading-icon>
    </div>
    
    <display-products
      [initialProducts]="products.slice(0, adLocation)"
      [laterProducts]="products.slice(adLocation)"
      [showAds]="showAds"
      [amznSearchTerm]="adSearchTerm"
    ></display-products>
  </div>

  <div class="loading-products" *ngIf="loadingMore">
    <loading-icon></loading-icon>
  </div>

  <div class="ending-message">
    <div *ngIf="endOfResults" style="text-align: center">End of results</div>
  </div>
</div>


