import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { GlobalConstants } from "../Global";

@Injectable({
  providedIn: "root",
})
export class AdService {
  // this is true if the user is using ad blocker
  public adsAreBlocked = new BehaviorSubject<boolean>(false);
  public userHasAcceptedCookies = new BehaviorSubject<boolean>(undefined);
  public showAdModal = new BehaviorSubject<boolean>(false);

  private COOKIE_CACHE_KEY = GlobalConstants.CACHE_PREFIX + "cookies";

  constructor() {
    const acceptedCookies = localStorage.getItem(this.COOKIE_CACHE_KEY);
    if (acceptedCookies && acceptedCookies.length > 1)
      this.userHasAcceptedCookies.next(acceptedCookies === "true");
  }

  setAdsAreBlocked() {
    this.adsAreBlocked.next(true);
  }

  setCookiesAccepted(accepted: boolean) {
    this.userHasAcceptedCookies.next(accepted);
    localStorage.setItem(this.COOKIE_CACHE_KEY, "" + accepted);
  }

  showModal() {
    this.showAdModal.next(true);
  }

  closeModal() {
    this.showAdModal.next(false);
  }

  getScript(countryCode: string, title: string, searchTerm: string) {
    switch (countryCode) {
      case "CA": {
        const builder = new CanadaBuilder(searchTerm, title);
        return this.getAdScript(builder);
      }
      default: {
        const builder = new USBuilder(searchTerm, title);
        return this.getAdScript(builder);
      }
    }
  }

  private getAdScript(builder: AdBuilder) {
    let script =
      `<script type="text/javascript">
      amzn_assoc_placement = "adunit0";
      amzn_assoc_search_bar = "true";
      amzn_assoc_tracking_id = "` +
      builder.trackingId +
      `";
      amzn_assoc_search_bar_position = "top";
      amzn_assoc_ad_mode = "search";
      amzn_assoc_ad_type = "smart";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "` +
      builder.countryCode +
      `";
      amzn_assoc_title = "` +
      "Ad - " +
      builder.title +
      `";
      amzn_assoc_default_search_phrase = "` +
      builder.searchTerm +
      `";
      amzn_assoc_default_category = "All";
      amzn_assoc_linkid = "fab16d83b6db12990346c2b94f12c379";
      </script>
      <script src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=` +
      builder.countryCode +
      `"></script>`;
    return script;
  }
}

class AdBuilder {
  constructor(
    public searchTerm: string,
    public title: string,
    public countryCode: string,
    public trackingId: string
  ) {}
}

class CanadaBuilder extends AdBuilder {
  constructor(
    public searchTerm: string,
    public title: string,
    public countryCode: string = "CA",
    public trackingId: string = "dailybids0d3-20"
  ) {
    super(searchTerm, title, countryCode, trackingId);
  }
}

class USBuilder extends AdBuilder {
  constructor(
    public searchTerm: string,
    public title: string,
    public countryCode: string = "US",
    public trackingId: string = "dailybids01-20"
  ) {
    super(searchTerm, title, countryCode, trackingId);
  }
}
