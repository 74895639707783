import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TimerBlockComponent } from 'src/app/modules/reusable/components/products/display-products/timer/timer-block.component';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/core/user.service';
import { Subscription } from 'rxjs';
import { GlobalConstants } from 'src/app/Global';
import { Product } from 'shared/interfaces/product';
import { Bid } from 'shared/interfaces/bid';
import * as firebase from 'firebase/app'
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { Conversation } from 'src/app/objects/conversation';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'bidding',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.scss']
})
export class BiddingComponent implements OnInit, OnDestroy {

  @ViewChild(TimerBlockComponent, {static: false}) productTimer: TimerBlockComponent;
  @Input() product: Product;
  @Output() suggestClose: EventEmitter<any> = new EventEmitter();

  bids: Bid[] = [];
  bidAmount: number = null;
  hasLateBid: boolean = false; // Bid has a late bid and is over
  subs: Subscription[] = [];
  loadingBids: boolean = true;
  loadingMoreBids: boolean = false;
  productIsBeingWatched: boolean = false;
  MAX_LENGTH = 15;
  MSG_MAX_LENGTH = GlobalConstants.MAX_MSG_INPUT;

  highlightInput = false;

  defaultThumb: string;

  placedVeryRecentBid = false;
  messageSentRecently = false;

  // bidBtnState;
  panelOpenState:boolean = false;
  showTextArea:boolean = true;

  constructor(private prodServ: ProductService, public currentUser:UserService,
    private toastr: ToastrService, private _message:MessageService, 
    private router: Router, public dialogs:DialogService) { }

  ngOnInit() {
    this.defaultThumb = GlobalConstants.DEFAULT_PROFILE_PICTURE_URL_THUMB;

    this.setProductIsBeingWatched();

    this.subs.push(this.prodServ.getRecentBids(this.product).subscribe(res => {
      this.loadingBids = false;
      this.bids = this.mergeList(res);
    }));
  }

  get isOwner(){
    return this.currentUser.exists() && this.currentUser.uid() == this.product.posterId;
  }

  get winnerExists(){
    return this.product.finalized && this.product.winningBid;
  }

  get biddingFinished() {
    return this.product.finalized || this.hasLateBid;
  }

  get showMessageDropdown(){
    if(!this.winnerExists) return false;
    let cUid = this.currentUser.uid();
    return cUid == this.product.posterId || cUid == this.product.winningBid.userId;
  }

  get currentUserIsWinner(){
    if(!this.winnerExists) return false;
    //If product is finalized and there is a winningBid then check the ids
    return this.currentUser.uid() == this.product.winningBid.userId;
  }

  setProductIsBeingWatched() {
    this.subs.push(this.currentUser.firestore.bids$.subscribe(res => {
      this.productIsBeingWatched = res.includes(this.product.id);
    }));
  }

  toggleProductBeingWatched() {
    if (this.productIsBeingWatched) {
      this.prodServ.removeFromWatchList(this.product.id);
    } else {
      this.prodServ.addToWatchList(this.product.id);
    }
  }

  showMessageButton(bid: Bid): boolean {
    return bid.userId != this.currentUser.uid() &&
          this.product.posterId == this.currentUser.uid()
  }

  goToWatchlist() {
    this.suggestClose.emit();
    this.currentUser.goToWatchlist();
  }

  async sendMessage(textArea:HTMLTextAreaElement){
    //Buffer the amount of messages that can be sent
    if(this.messageSentRecently) return;
    this.messageSentRecently = true;
    //After this method runs, the user will not have access to the button to send another message,
    //But if the message fails to send for some reason it is a good idea to reverse this boolean so the user can try again.
    setTimeout(() => {this.messageSentRecently = false}, 500) 

    let sellerId = this.product.posterId;
    let buyerId = this.product.winningBid.userId;
    let message = textArea.value;
    let convo = await this._message.createConversation(sellerId, buyerId, this.product.id, this.product.locationID);
    this._message.sendMessage(message, new Conversation(convo, this.currentUser.uid()))
    .then(() => {
      let name = this.currentUserIsWinner ? this.product.posterName : this.product.winningBid.username;
      this.toastr.success(`Message successfully sent to ${name}`)
    }).finally(() => {
      textArea.value = '';
      this.showTextArea = false;
    })
  }

  goToConversations(){
    this._message.open(`${this.product.posterId}-${this.product.winningBid.userId}-${this.product.id}`)
  }

  fixEnterIssue(e){
    if (e.preventDefault) {
      e.preventDefault();
    } else {
        e.returnValue = false;
    }
  }

  // Lets angular do change detection so we don't load all bids again
  byBidId(index, item) {
    return item.id;
  }

  allowBidding(): boolean {
    return !(this.isOwner || this.biddingFinished);
  }

  setBidToMin() {
    this.bidAmount = this.minimumBid();
  }

  // Used to control input of the bid for destops
  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }

    if (this.bidAmount && this.bidAmount.toString().length >= this.MAX_LENGTH) {
      // max length exceeded
      event.preventDefault();
    }
  }

  // Returns the minimum bid, plus one the current winning bid
  minimumBid(): number {
    if (this.product.winningBid) return this.product.winningBid.amount + 1;
    return this.product.startingBid;
  }

  // Returns true if the bid is valid
  bidIsValid(bid = this.bidAmount): boolean {

    if (bid == null) {
      return false;
    }

    if (this.bids.length > 0 && bid < this.minimumBid()) {
      // console.log("too low")
      return false;
    }

    if (bid < this.product.startingBid) {
      // console.log("Bid needs to exceed starting bid");
      return false;
    }

    if (this.bidNonInteger(bid)) {
      return false
    }

    return true;
  }

  // Returns true if bid contains a decimal or an e
  bidNonInteger(bid = this.bidAmount): boolean {
    if (bid == null) return false;

    const pattern = /[0-9]/;
    if (!pattern.test(bid.toString()) || bid.toString().includes('.') || bid.toString().includes('e')) {
      return true;
    }
    return false;
  }

  placeBid(amount: number, input?: HTMLElement) {

    if (!this.currentUser.exists()) {
      // For some reason the modal opens behind this product modal
      this.dialogs.openLogin();
      return;
    }

    this.highlightInput = false;

    if (amount === null) {
      if (input) input.focus();
      this.toastr.info("Please type in your bid amount");
      this.highlightInput = true;
      return;
    }

    if (this.bidIsValid(amount) && !this.placedVeryRecentBid) {
      this.placedVeryRecentBid = true;
      setTimeout(() => {this.placedVeryRecentBid = false}, 500)
      // this.bidBtnState = ClrLoadingState.LOADING;
      this.prodServ.placeBid(amount, this.product, this.currentUser.uid(), this.currentUser.firestore.displayName)
      .then(res => {
        // this.bidBtnState = ClrLoadingState.SUCCESS;
        this.toastr.success("Your bid for $" + amount + " has been placed")
      })
      // .catch(err => this.bidBtnState = ClrLoadingState.ERROR);
    } else {
      // this.bidBtnState = ClrLoadingState.ERROR;
      if (!this.bidIsValid(amount)) {
        const message = "The minimum bid is $" + this.minimumBid();
        if (!this.toastr.findDuplicate("", message, true, true))
        this.toastr.error(message)
      }
    }

  }

  getMessageIds(bid:Bid){
    return {
      sellerId:this.currentUser.uid(), 
      buyerId:bid.userId, 
      productId:this.product.id, 
      locationId:this.product.locationID
    }

  }

  isWinningBid(bid: Bid): boolean {
    return this.product.winningBid && bid.id == this.product.winningBid.id
  }

  isLateBid(bid: Bid): boolean {
    let expTime = this.product.uploadTime.toDate()
    expTime.setDate(expTime.getDate() + 1); // Add one day to get bidding end
    let expStamp = firebase.default.firestore.Timestamp.fromDate(expTime);
    const isLateBid =  bid.bidTime > expStamp;
    if (isLateBid) {
      this.hasLateBid = true;
    }
    return isLateBid;
  }

  isSmallScreen(): boolean {
    return screen.availWidth < 870;
  }

  mergeList(newList: Bid[]): Bid[] {
    // remove duplicates from old list
    const oldBids = this.bids.filter(bid1 => (newList.find(bid2 => bid2.id === bid1.id)) === undefined);
    return [...newList, ...oldBids]; 
  }

  loadMoreBids() {
    if (this.loadingMoreBids) return;
    this.loadingMoreBids = true;
    this.prodServ.getNextBids(this.product, this.bids[this.bids.length - 1]).pipe(take(1)).subscribe(res => {
      this.bids = this.bids.concat(res);
      this.loadingMoreBids = false;
    })
  }

  hasMoreBids() {
    return this.bids.length < this.product.bidCount;
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

}
