import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from './components/home/home.component';
import { LocationGuard } from './guards/location.guard';
import { SearchResultsComponent } from './modules/reusable/components/products/results/search-results/search-results.component';
import { ContactFormComponent } from "./components/contact/contact.component";
import { AllProductsComponent } from "./components/all-products/all-products.component";
import { allRoutes } from "shared/all-routes";


const routes: Routes = [
  {
    path: "",
    redirectTo: `${allRoutes.home.route}/`,
    pathMatch: 'full',
    data: allRoutes.home
  },
  {
    path: `${allRoutes.home.route}/:id`,
    component: HomeComponent,
    data: allRoutes.home
  },
  {
    path: `${allRoutes.products.route}`,
    redirectTo: `${allRoutes.products.route}/`,
    pathMatch: 'full',
    data: allRoutes.products
  },
  {
    path: `${allRoutes.products.route}/:id`,
    component: AllProductsComponent,
    canActivate: [LocationGuard],
    data: allRoutes.products
  },
  {
    path: `${allRoutes.results.route}`,
    redirectTo: `${allRoutes.results.route}/`,
    pathMatch: 'full',
    data: allRoutes.results
  },
  {
    path: `${allRoutes.results.route}/:id`,
    component: SearchResultsComponent,
    canActivate: [LocationGuard],
    data: allRoutes.results
  },
  {
    path: `${allRoutes.contact.route}`,
    component: ContactFormComponent,
    data: allRoutes.contact
  },
  { 
    path: `${allRoutes.terms.route}`, 
    loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
    data: allRoutes.terms
  },
  { 
    path: `${allRoutes.privacy.route}`, 
    loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    data: allRoutes.privacy
  },
  { 
    path: `${allRoutes.guidelines.route}`, 
    loadChildren: () => import('./modules/guidelines/guidelines.module').then(m => m.GuidelinesModule),
    data: allRoutes.guidelines
  },
  { 
    path: `${allRoutes.sell.route}`, 
    loadChildren: () => import('./modules/sell/sell.module').then(m => m.SellModule),
    data: allRoutes.sell
   },
  { 
    path: `${allRoutes.edit.route}`, 
    loadChildren: () => import('./modules/sell/sell.module').then(m => m.SellModule),
    data: allRoutes.edit
   },
  { 
    path: `${allRoutes.profile.route}`, 
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    data: allRoutes.profile
   },
  { 
    path: `${allRoutes.messenger.route}`, 
    loadChildren: () => import('./modules/messenger/messenger.module').then(m => m.MessengerModule),
    data: allRoutes.messenger
 },
  { 
    path: `${allRoutes.notifications.route}`, 
    loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
    data: allRoutes.notifications
   },
  { 
    path: `${allRoutes.premium.route}`, 
    loadChildren: () => import('./modules/premium/premium.module').then(m => m.PremiumModule),
    data: allRoutes.premium
   },
  { path: "**", redirectTo: `${allRoutes.home.route}/`, pathMatch: "full" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
