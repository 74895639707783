import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { LoginDialog } from '../modules/reusable/components/auth-dialogs/login/login-dialog.component';
import { RegisterDialog } from '../modules/reusable/components/auth-dialogs/login/register-dialog.component';
import { PremiumDialog } from '../modules/reusable/components/premium-dialog/premium-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  // premiumDialogRef: MatDialogRef<PremiumDialog>;
  registerDialogRef: MatDialogRef<RegisterDialog>;
  loginDialogRef: MatDialogRef<LoginDialog>;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openPremium(){ 
    this.dialog.open(PremiumDialog, { });
  }
  
  openLogin(message?:string){
    if(this.registerDialogRef) this.registerDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = 350 + "px";
    dialogConfig.data = { message: message, };
    this.loginDialogRef = this.dialog.open(LoginDialog, dialogConfig );
    this.subscribeAfterClose(this.loginDialogRef);
  }

  openRegister(message?:string){
    if(this.loginDialogRef) this.loginDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = 450 + "px";
    dialogConfig.data = { message: message, };
    this.registerDialogRef = this.dialog.open(RegisterDialog, dialogConfig);
    this.subscribeAfterClose(this.registerDialogRef);
  }

  private subscribeAfterClose(dialogRef:MatDialogRef<RegisterDialog|LoginDialog>){
    return dialogRef.afterClosed().pipe(take(1)).subscribe(res => { 
      if (res && res === "login") this.openLogin();
      if (res && res === "register") this.openRegister();
    });
  }
}