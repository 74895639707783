import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFirestore } from "@angular/fire/firestore";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { GlobalConstants } from "../Global";
import { StorageError } from "../error-handler/customErrors";


@Injectable({
  providedIn: "root",
})
export class ImageHelperService {
  private MID_POSTFIX = "_300x200";
  private THUMB_POSTFIX = "_64x64";
  private TYPE = ".jpeg"

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * Deletes the main image and both thumbnails from firebase
   * 
   * @param path firebase storage path to image
   * @param image file name of main image
   */
  deleteImage(path: string, image: string) {
    let postFix: string = "." + image.split(".").pop(); // Ex: .png
    const preFix =
      path +
      this.reverseString(
        this.reverseString(image).replace(this.reverseString(postFix), "")
      );
    const thumb = preFix + this.THUMB_POSTFIX + postFix;
    const main = preFix + postFix;
    const mid = preFix + this.MID_POSTFIX + postFix;

    this.storage.ref(thumb).delete().toPromise().catch(e => {throw new StorageError(e, 7000) });
    this.storage.ref(main).delete().toPromise().catch(e => {throw new StorageError(e, 7001) });
    this.storage.ref(mid).delete().toPromise().catch(e => {throw new StorageError(e, 7002) });
  }

  /**
   * Create a unique, generic id based name for the image. Tries to share same file extension
   * 
   * @param name original name of image
   */
  createImageName(name: string): string {
    return this.afs.createId() + this.TYPE;
  }

  /**
   * @returns _300x200
   */
  getMidPostFix(): string {
    return this.MID_POSTFIX;
  }

  /**
   * @returns _64x64
   */
  getThumPostFix() {
    return this.THUMB_POSTFIX;
  }

  /**
   * @returns the url made safe to bypass security checks
   */
  makeSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * @param baseUrl the base url for all images in a product (before the image name)
   * @param imageName name of the main image
   * @returns the url of the viewable image
   */
  getMainImageUrl(baseUrl: string, imageName: string): string {
    return baseUrl + imageName + "?alt=media";
  }


  /**
   * @param baseUrl the base url for all images in a product (before the image name)
   * @param imageName name of the main image
   * @returns the url of the viewable small thumbnail of image (32x32)
   */
  getImageThumbUrl(baseUrl: string, imageName: string): string {
    if (imageName && imageName.length > 1) {
      return (
        baseUrl +
        this.getImageNameNoFileType(imageName) +
        this.getThumPostFix() +
        this.getImageExtension(imageName) +
        "?alt=media"
      );
    } else {
      return GlobalConstants.CIRCLED_X_SVG;
    }
  }

  
  /**
   * @param baseUrl the base url for all images in a product (before the image name)
   * @param imageName name of the main image
   * @returns the url of the viewable mid sized thumbnail of image (128x128)
   */
  getImageMidUrl(baseUrl: string, imageName: string): string {
    if (imageName && imageName.length > 1) {
      return (
        baseUrl +
        this.getImageNameNoFileType(imageName) +
        this.getMidPostFix() +
        this.getImageExtension(imageName) +
        "?alt=media"
      );
    } else {
      return GlobalConstants.CIRCLED_X_SVG;
    }
  }

  private getImageExtension(image: string) {
    if(!image) return '';
    const splitName = image.split(".");
    return "." + splitName[splitName.length - 1];
  }

  private getImageNameNoFileType(image: string): string {
    if(!image) return '';
    const splitName = image.split(".");
    let onlyName = "";
    // Add everything back to file name except extension
    for (let i = 0; i < splitName.length - 1; i++) {
      onlyName += splitName[i] + ".";
    }
    return onlyName.substring(0, onlyName.length - 1);
  }

  private reverseString(str: string): string {
    return str.split("").reverse().join("");
  }
}
