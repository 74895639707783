import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MainProviderService } from 'src/app/services/productProviders/main-provider.service';
import { take } from 'rxjs/operators';
import { Product } from 'shared/interfaces/product';
import { GoogleLocation } from 'shared/location';
import { Title } from '@angular/platform-browser';
import { GlobalConstants } from 'src/app/Global';
import { UserService } from 'src/app/services/core/user.service';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import { LocationService } from 'src/app/services/core/location.service';
import { ProductStoreService } from 'src/app/services/productProviders/product-store.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  name = GlobalConstants.NAME;

  PAGE_KEY = "home_page";

  loading = false;

  initialProducts: Product[] = [];
  popularCities$ = this.locationServ.popularCities$;

  options = {
    fields: ['place_id', 'name'], // Do not change without changing handleAddressChange() below
    types: ['(cities)']
    }


  constructor(private db: AngularFirestore, private router: Router, private productProvider: MainProviderService,
    private currentUser: UserService, private dialog: MatDialog, private locationServ: LocationService,
    private store: ProductStoreService, private route:ActivatedRoute) {
  }

  ngOnInit() {
    if (this.hasLocationSet()) this.getProducts();
    this.options["sessiontoken"] = this.db.createId();
    // this.clearPosParam()
  }
  clearPosParam() {
    this.router.navigate(
      ['.'], 
      { relativeTo: this.route, queryParams: { } }
    );
  }

  getPopularCities() {
    this.popularCities$ = this.locationServ.popularCities$;
  }

  hasLocationSet(): boolean {
    return this.currentUser.hasSetLocation();
  }

  handleAddressChange(address: any) {
    this.changeAddress(address.place_id, address.name);
  }

  changeAddress(id: string, name: string) {
    if (this.currentUser.getLocation().id != id) {
      this.currentUser.changeLocation(new GoogleLocation(id, name), true, true, true);
    }
  }

  getCity(): string {
    return this.currentUser.getCityName();
  }

  openRulesSummary() {
    this.dialog.open(RulesSummaryDialog, {width: '500px'})
  }

  getProducts() {
    if (this.store.hasPage(this.PAGE_KEY)) {
      this.initialProducts = this.store.restorePage(this.PAGE_KEY);
      return;
    }

    this.loading = true;

    // Get only one copy of products, not listening to changes
    this.productProvider.getInitialProducts()
      .pipe(take(1)).subscribe(prods => {
        this.loading = false;
        if (prods.length == 0) {
          return;
        }
        this.initialProducts = prods;
        // Set last time stamp so we know where to start for getting next products
      }, error => {
        this.loading = false;
      })
  }

  locationSearch() {
    document.getElementById("home-loc-srch").focus();
  }

  showShareButton() {
    let nav: any;
    nav = window.navigator;
    return nav && nav.share;
  }

  share() {
    let nav: any;
    nav = window.navigator;
    if (this.showShareButton()) {
      nav.share({
        title: this.getCity() + " " + this.name,
        text: "Checkout the deals on " + this.name + " in " + this.getCity(),
        url: this.inviteLink()
      })
    }
  }

  showSeeAllButton(): boolean {
    return this.initialProducts.length >= GlobalConstants.MOBILE_BATCH_SIZE;
  }

  inviteLink(): string {
    return this.currentUser.createReferralLink("https://dailybids.ca");
  }

  ngOnDestroy() {
    this.store.savePage(this.PAGE_KEY, this.initialProducts, window.scrollY);
  }
}

@Component({
  selector: 'rules-sum-dialog',
  templateUrl: 'rules-summary-dialog.html',
})
export class RulesSummaryDialog {

  constructor(
    public dialogRef: MatDialogRef<RulesSummaryDialog>, private utils: UtilService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  goToGuidelines() {
    this.utils.dialogNavigate("/guidelines", this.dialogRef);
  }

}