<h1 mat-dialog-title>Report {{data.type}}</h1>
<div mat-dialog-content>
    <form>
        <mat-form-field style="width: 100%;">
            <mat-label>Briefly describe the problem</mat-label>
            <textarea matInput required placeholder="Ex. This is spam." [(ngModel)]="message"
                [ngModelOptions]="{standalone: true}"></textarea>
            <mat-error *ngIf="messageError">Message is required</mat-error>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button (click)="submit()" color="primary">Submit</button>
</mat-dialog-actions>

<iframe height="0" #iframe src="/assets/report-form.html" ></iframe>
<loading *ngIf="loading"></loading>