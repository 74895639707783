import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Inject,
} from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { of, Subscription } from "rxjs";
import { finalize, catchError } from "rxjs/operators";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { UserService } from "src/app/services/core/user.service";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "profile-pic",
  templateUrl: "./profile-pic.component.html",
  styleUrls: ["./profile-pic.component.scss"],
})
export class ProfilePicComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() userId: string; // userId to fetch profile pic for
  @Input() userHasProfilePic: boolean; // boolean for whether or not this user has a profile pic
  @Input() uid: string = "69"; // REQUIRED, this is the unique id of this profile pic component and must be unique over the entire DOM
  @Input() editable: boolean = false; // true if the component should allow a user to edit this profile pic
  @Input() defaultImage: string; // the default image to load if profile pic cannot be found
  @Input() size: string; // size this profile pic should be set to
  @Input() useThumbnail: boolean = false; // whether or not a thumbnail should be used (32x32)
  @Input() resizeTo: number = 128; // this number used by cropper and is only used when profile pic is editable.

  imageURL: string | SafeResourceUrl = "";

  sub: Subscription;

  constructor(private currentUser: UserService, private dialog: MatDialog) {}

  ngOnInit() {
    // if (this.editable) this.initEditVariables();
  }

  ngAfterViewInit() {
    this.setSize();
    this.setImageUrl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["defaultImage"] ||
      changes["userId"] ||
      changes["userHasProfilePic"]
    ) {
      this.setImageUrl();
    }
  }

  setSize() {
    let container = document.getElementById(this.getPhotoContainerId());
    container.style.height = this.size + "px";
    container.style.width = this.size + "px";
  }

  getPhotoContainerId() {
    return "avatar-" + this.uid;
  }

  setImageUrl() {
    if (this.userHasProfilePic) {
      this.imageURL = this.currentUser.getUserDownloadUrl(
        this.userId,
        this.useThumbnail
      );
    } else {
      this.imageURL = "";
    }
  }

  editProfilePic() {
    if (this.editable) {
      const ref = this.dialog.open(EditProfilePicDialog, {
        width: "600px",
        data: {
          userId: this.userId,
          resizeTo: this.resizeTo,
          imageURL: this.imageURL,
        },
      });

      this.sub = ref.afterClosed().subscribe((res) => {
        if (res && res.length > 2) this.imageURL = res;
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}

@Component({
  selector: "edit-prof-pic-dialog",
  templateUrl: "edit-pic-dialog.html",
  styleUrls: ["./profile-pic.component.scss"],
})
export class EditProfilePicDialog implements OnDestroy {
  @ViewChild("inputImageFile") inputImage: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<EditProfilePicDialog>,
    private cdRed: ChangeDetectorRef,
    private storage: AngularFireStorage,
    private currentUser: UserService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.initEditVariables();
    this.userId = this.data.userId;
    this.resizeTo = this.data.resizeTo;
    this.imageURL = this.data.imageURL;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  subs: Subscription[] = [];

  resizeTo: number;
  imageURL: string | SafeResourceUrl = "";
  userId: string;
  imageChangedEvent: any = "";
  imageFile: File;
  croppedImage: any = "";
  editingProfilePic = false;
  cropperIsReady = false;
  loadingNewContactPhoto = false;
  imageUploadFailed = false;
  imageIsLoaded: boolean = false;
  rotatingImage: boolean = false;
  transform: ImageTransform;
  canvasRotation: number;

  initEditVariables() {
    this.transform = {};
    this.canvasRotation = 0;
  }

  hasProfilePic(): boolean {
    return this.imageURL !== "";
  }

  rotateRight() {
    this.rotatingImage = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    }, 1); // Allow spinner to show in UI
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  async onDropImage(files: FileList) {
    if (files && files.length > 0) {
      this.imageFile = files.item(0);
      this.loadingNewContactPhoto = true;
    }
  }

  fileChangeEvent(event: any): void {
    this.cropperIsReady = false;
    if (
      event &&
      event.target &&
      event.target.value &&
      event.target.value.length > 2
    )
      this.loadingNewContactPhoto = true;
    setTimeout(() => {
      this.imageChangedEvent = event;
    }, 5);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.imageIsLoaded = true;
  }

  cropperReady() {
    this.cropperIsReady = true;
    this.loadingNewContactPhoto = false;
    this.detectChanges();
    this.rotatingImage = false;
    // Useful if we show result
    // document.getElementById("cropped-avatar-result").style.width = this.resizeTo + "px";
  }

  loadImageFailed() {
    this.imageUploadFailed = true;
    this.loadingNewContactPhoto = false;
  }

  resetCropper() {
    this.croppedImage = "";
    this.imageChangedEvent = "";
    this.inputImage.nativeElement.value = "";
    this.cropperIsReady = false;
    this.imageUploadFailed = false;
    this.imageIsLoaded = false;
  }

  closePicModal() {
    this.onNoClick();
    this.resetCropper();
  }

  showDeleteConfirmation() {
    this.subs.push(
      this.dialog
        .open(DeleteProfilePicDialog, {
          width: "500px",
          data: { imageURL: this.imageURL, userId: this.userId },
        })
        .afterClosed()
        .subscribe((res) => this.dialogRef.close())
    );
  }

  detectChanges() {
    if (!this.cdRed["destroyed"]) {
      this.cdRed.detectChanges();
    }
  }

  changePhoto() {
    if (!this.userId) {
      console.log("Cannot save image, no user id is set.");
      return;
    }

    const path = this.currentUser.getContactPhotoPath(this.userId);
    this.loadingNewContactPhoto = true;
    const ref = this.storage.ref(path);

    const task = ref.putString(this.croppedImage, "data_url");

    task
      .snapshotChanges()
      .pipe(
        catchError(() => {
          this.imageUploadFailed = true;
          return of("Image upload failed");
        }),
        finalize(async () => {
          const newImageURL = await ref.getDownloadURL().toPromise();
          this.dialogRef.close(newImageURL);
          let timeout = 500; // Gives firebase function time to run
          setTimeout(() => {
            this.resetCropper();
            this.loadingNewContactPhoto = false;
            this.editingProfilePic = false;
            this.toastr.success("Succesfully updating profile picture...");
          }, timeout);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      if (sub) sub.unsubscribe();
    });
  }
}

@Component({
  selector: "delete-prof-pic-dialog",
  templateUrl: "delete-pic-dialog.html",
  styleUrls: ["./profile-pic.component.scss"],
})
export class DeleteProfilePicDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteProfilePicDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private currentUser: UserService
  ) {
    this.imageURL = data.imageURL;
    this.userId = data.userId;
  }

  deleteErrorMessage: string = "";
  failedDeleteImage: boolean = false;
  deletingImage: boolean = false;
  imageURL: string;
  userId: string;

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteProfilePic() {
    this.deletingImage = true;
    this.currentUser.firestore.updateHasProfilePic(false);
    this.currentUser
      .deleteProfilePic(this.userId)
      .then((res) => {
        setTimeout(() => {
          this.dialogRef.close();
          this.deletingImage = false;
          // Should we trigger function to update all documents? This could easily be abused and
          // is likely rarely needed
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        this.deleteErrorMessage = err;
        this.failedDeleteImage = true;
        this.deletingImage = false;
      });
  }
}
