import { Component, Input, Output, EventEmitter, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent{

  dialogRef: MatDialogRef<VerifyEmailDialog>;

  constructor(private dialog: MatDialog) { }

  @Input() set openModal(openModal: boolean) {
    if (openModal) {
      this.dialogRef = this.dialog.open(VerifyEmailDialog, {
      data: {title: this.title}
    });

    this.dialogRef.afterClosed().subscribe(_ => this.onCancel.emit())
  } else {
      if (this.dialogRef) this.dialogRef.close();
    }
  };
  @Input() title: string;
  @Output() onCancel:EventEmitter<void> = new EventEmitter<void>(); 

}

@Component({
  selector: 'adblock-dialog',
  templateUrl: 'verify-email-dialog.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailDialog implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  message: string;

  constructor(
    public dialogRef: MatDialogRef<VerifyEmailDialog>,
    public currentUser:UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.subs.push(
      this.currentUser.verificationMessage$.subscribe(
        (message) => (this.message = message)
      )
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resendVerifyEmail(){
    this.currentUser.sendVerificationLink();  
  }

  later() {
    this.dialogRef.close("later");
  }

  verifyConfirm(){
    this.currentUser.confirmVerified().then(yes => {
      if(yes){
        this.dialogRef.close();
      }
    })
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
