import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, tap } from 'rxjs/operators';
import { Subject, Observable, throwError } from 'rxjs';
import { UserService } from 'src/app/services/core/user.service';
import { ByIdProviderService } from './by-id-provider.service';
import { Product } from 'shared/interfaces/product';
import ProductSearch from 'shared/helpers/productSearch';
import { ScreenSizeService } from '../../screen-size.service';
import { GlobalConstants } from 'src/app/Global';
import { ApplicationError, FirestoreError } from 'src/app/error-handler/customErrors';
import { ProductStoreService } from '../product-store.service';

@Injectable({
  providedIn: 'root'
})

// Service to return products based on a search result
export class SearchProviderService extends ByIdProviderService {

  private searchWords;

  constructor(protected afs: AngularFirestore, protected currentUser: UserService, 
    protected screenSize: ScreenSizeService, protected store: ProductStoreService) {
    super(afs, currentUser, screenSize, store);
  }

  setSearchWords(search: string) {
    // Search words are cleaned and trimmed words from user search request
    this.searchWords = ProductSearch.getWords(search);
  }

  /**
   * Get the first batch of products for the given search. 
   * This method does the initial ground work to also store product 
   * ids for future batches on this search.
   * 
   * @param search user inputted search
   * @returns first batch of products to display to user.
   */
  getInitialProducts(): Observable<Product[]> {

    if (!this.searchWords) {
      throw new ApplicationError("Search words have not been set. No results can be found.", 5101)
    }

    this.resetGlobals();

    let nextProducts: Subject<Product[]> = new Subject();

    // IDMap is product ID to TempData (Stores count and upload time)
    this.getIDToCountMap(this.searchWords).then(idMap => {

      // get ordered product ids and store them globally to return incrementally
      this.orderedIDs = this.getOrderedIDs(idMap, this.searchWords.length)

      this.getNextProductBatch(GlobalConstants.INITIAL_PROD_BATCH_SIZE_WHEN_ADS_PRESENT).then(prods => nextProducts.next(prods))
        .catch(e => {
          nextProducts.error(new Error(e));
          throw new ApplicationError(e, 5103);
        });
    }).catch(e => {
      nextProducts.error(new Error(e))
      throw new ApplicationError(e, 5102);
    }).finally(() => this.searchWords = null);

    return nextProducts;
  }

  /**
   * Get all the product ids store in firebase for each index from the users search word. 
   * Keep track of how many words each product appeared to order by relevance.
   * 
   * @param searchWords clean words that were searched for by user
   * @returns a map of product ids to data about upload time and search counts
   */
  private async getIDToCountMap(searchWords: string[]): Promise<Map<string, TempData>> {
    let idMap: Map<string, TempData> = new Map();

    const promises = searchWords.map(async (word, idx) => {
      // this may cause issues and result in improper counting, we should check this and refer to this if so
      // https://medium.com/dailyjs/the-pitfalls-of-async-await-in-array-loops-cf9cf713bfeb
      await this.afs.collection('cities').doc(this.getCity()).collection('indices').doc<Word>(word)
        .valueChanges().pipe(
          tap(p => {
            if (p) {
              p.products.forEach(prod => {
                let tmp = prod.split(this.idTimeStampDivider);
                const id = tmp[0];
                const uploadTime = tmp[1];
                if (idMap.has(id)) {
                  let data = idMap.get(id);
                  data.count = data.count + 1
                  idMap.set(id, data);
                } else {
                  idMap.set(id, new TempData(uploadTime, 1))
                }
              })
            }
          }),
          take(1)
        ).toPromise().catch(e => { throw new FirestoreError(e, 2109) })
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      throw new ApplicationError(e, 5104);
    }
    return idMap;
  }

  /**
   * get a list of product ids that should be returned after the user's search.
   * These ids will be ordered be relevance.
   * If a product appears in multiple words of the search, it will have a higher relevance.
   * If a product was uploaded recently it will also have a higher relevance.
   * 
   * @param idMap map of product id to tempData
   * @param highestPossibleCount number of search words represents highest possible count a product
   *                                id shows up in search words
   * @returns the product ids for this search result
   */
  private getOrderedIDs(idMap: Map<string, TempData>, highestPossibleCount: number): string[] {

    let countMap: Map<number, IndexData[]> = new Map();

    // get a map of count to IndexData, where count is how many words this product
    // appeared in the users search
    idMap.forEach((data, key) => {
      let arr = countMap.get(data.count)
      if (arr) {
        arr.push(new IndexData(key, data.uploadTime));
        countMap.set(data.count, arr);
      } else {
        let new_a = new Array(new IndexData(key, data.uploadTime))
        countMap.set(data.count, new_a)
      }
    });

    let idx;
    let orderedIds = [];
    // Iterate through count map, adding words with high counts first to ordered ids
    for (idx = highestPossibleCount; idx > 0; idx--) {
      let ids = countMap.get(idx);

      if (orderedIds.length > this.MAXSEARCHRESULTS) {
        break;
      }

      if (ids) {
        // Experimentally, ids are added to firebase array in order, first reverse to speed up sort.
        let list = ids.reverse().sort((d1, d2) => {
          if (d1.uploadTime > d2.uploadTime) return -1;
          if (d2.uploadTime > d1.uploadTime) return 1;
          return 0;
        })

        orderedIds = orderedIds.concat(list.map(data => data.id));
      }
    }

    return orderedIds.slice(0, this.MAXSEARCHRESULTS);
  }
}

// Internal data structure used to represent data stored in search word
class IndexData {

  constructor(id: string, uploadTime) {
    this.id = id;
    this.uploadTime = uploadTime;
  }

  id: string;
  uploadTime;
}

// Temp data is an internal data stucture onle used in this service
class TempData {

  constructor(uploadTime, count: number) {
    this.uploadTime = uploadTime;
    this.count = count;
  }

  uploadTime;
  count: number;
}

// This is the document stored in a firebase product index
export class Word {
  products: string[]; // products are all the product ids + upload times that should be returned with this search
}
