<!-- <div id="yllix"></div> -->
<div class="row products">
    <div *ngFor="let product of cleanProducts(initialProducts)" class="product">
        <product-card 
        [product]="product" 
        (openProduct)="detes.showDetailsWithProd(product)"></product-card>
    </div>
</div>

<div id="amzn-ad-bar" *ngIf="showAds">
  <div *ngIf="adsAreBlocked" class="ads-blocked-text">
    Please unblock ads for this site <br />
    <button (click)="showAdBlockMessage()" mat-raised-button>Why?</button>
  </div>
<button
  *ngIf="!adsAreBlocked"
  mat-raised-button 
  (click)="this.dialogs.openPremium()"
 >Go Premium</button>
</div>

<div class="row products">
    <div *ngFor="let product of cleanProducts(laterProducts)" class="product">
        <product-card 
        [product]="product" 
        (openProduct)="detes.showDetailsWithProd(product)"></product-card>
    </div>
</div>
<product-details #detes></product-details>
