import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {

  private MOBILE_THRESHOLD = 600;

  constructor() { }

  /**
   * Simple but standard method for us to check screen size.
   * For an example of proper usage, look at `nav-bar.component.ts`
   * 
   * Ex:
   *   isSmallScreen$ = new BehaviorSubject<boolean>(this.screenSizeService.isSmallScreen());
   *   @HostListener('window:resize', ['$event'])
   *   onResize(event) {
   *       this.isSmallScreen$.next(this.screenSizeService.isSmallScreen())
   *   }
   * 
   * @param threshold anything less than this pixel width is considered small
   */
  isSmallScreen(threshold=this.MOBILE_THRESHOLD) {
    return document.body.offsetWidth < threshold;
  }
}
