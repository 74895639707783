import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { ContactDialog } from '../contact/contact.component';

@Component({
  selector: 'terms-link',
  templateUrl: './terms-link.component.html',
  styleUrls: ['./terms-link.component.scss']
})
export class TermsLinkComponent implements OnInit {

  MOBILE_THRESHOLD = 1200;

  isSmallScreen$ = new BehaviorSubject<boolean>(this.screenSize.isSmallScreen(this.MOBILE_THRESHOLD));
  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.isSmallScreen$.next(this.screenSize.isSmallScreen(this.MOBILE_THRESHOLD))
  }

  constructor(private screenSize: ScreenSizeService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openContact() {
    this.dialog.open(ContactDialog, {
      width: '600px'
    });
  }

}
