import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Product } from "shared/interfaces/product";
import { UserService } from "../core/user.service";

@Injectable({
  providedIn: "root",
})
export class ProductStoreService {
  // Should not store more than this many products in the store
  private PRODUCT_CACHE_LIMIT = 500;

  // product Id to Product object
  private productMap: Map<string, Product> = new Map();

  // pagekey to Product IDs, ids should be stored in order
  private pageMap: Map<string, string[]> = new Map();

  // pagekey to scroll location to restore location
  private locationMap: Map<string, number> = new Map();

  private lruPageKeys: string[] = [];
  private clearing = false;

  constructor(private afAuth: AngularFireAuth, private user: UserService) {
    this.afAuth.user.subscribe(authUser => {
      if (authUser === null) this.clearStore();
    }); 

    this.user.setLocation$.subscribe(_ => {this.clearStore();});
  }

  /**
   * Check if the given page is saved in the cache
   * 
   * @param pageKey key to identify page of products
   * @returns true if page is cached
   */
  hasPage(pageKey: string): boolean {
    return this.pageMap.has(pageKey);
  }

  /**
   * Collect the products and return them in proper order
   * for the given page. Assumes the caches contains all necessary
   * products.
   * 
   * @param pageKey key to identify page of products
   * @returns last saved products for the given page
   */
  restorePage(pageKey: string): Product[] {
    const ids = this.pageMap.get(pageKey);
    const products = [];
    ids.forEach((id) => products.push(this.productMap.get(id)));
    this.usePage(pageKey);
    return products;
  }

  /**
   * Save the given pages products and scroll location in the cache
   * Note: async to avoid slow down when navigating away 
   * (I think it works like that)
   * 
   * @param pageKey key to identify page of products
   * @param products list of products to store in cache (order is preserved)
   * @param location last known scroll location for page
   */
  async savePage(pageKey: string, products: Product[], location: number) {
    if (!products || products.length >= this.PRODUCT_CACHE_LIMIT ||
        products.length == 0) return;
    // I believe this will lower the priority of this code, but brings in complexity on logout
    await new Promise((resolve) => setTimeout(resolve, 1));
    // this is required because saving page on logout can lead to weird behaviour
    if (this.clearing) return;
    const ids = [];
    products.forEach((prod) => {
      this.productMap.set(prod.id, prod);
      ids.push(prod.id);
    });
    this.pageMap.set(pageKey, ids);
    this.trimStore();
    this.locationMap.set(pageKey, location);
    this.usePage(pageKey);
  }

  /**
   * Get the previous scroll location for a given page
   * @param pageKey key to identify page of products
   */
  getScrollLocation(pageKey: string): number {
    if (this.locationMap.has(pageKey)) {
      return this.locationMap.get(pageKey);
    } else {
      return 0;
    }
  }

  /**
   * Get product from id
   * Assume product is in map
   * 
   * @param id id of product
   * @returns product
   */
  getProduct(id: string): Product {
    return this.productMap.get(id);
  }

  hasProduct(id: string): boolean {
    return this.productMap.has(id);
  }

  setProduct(id: string, product: Product) {
    this.productMap.set(id, product);
    this.trimStore();
  }

  /**
   * Clear all maps and data in store
   */
  clearStore() {
    this.clearing = true;
    this.productMap.clear();
    this.pageMap.clear();
    this.locationMap.clear();
    this.lruPageKeys = [];
    setTimeout(() => this.clearing = false, 50)
  }

  // move the pageKey up in the LRU cache.
  private usePage(pageKey: string) {
    if (this.lruPageKeys.includes(pageKey)) {
      const idx = this.lruPageKeys.indexOf(pageKey);
      this.lruPageKeys = this.lruPageKeys.splice(idx, 1);
    }
    this.lruPageKeys.unshift(pageKey);
  }

  // trim products to make sure the store isn't taking
  // up too much memory
  private trimStore() {
    if (this.productMap.size < this.PRODUCT_CACHE_LIMIT) return;
    this.clearStore();
    // Will test this later...
    // // remove lru page
    // const removePage = this.lruPageKeys.pop();
    // this.pageMap.delete(removePage);
    // this.locationMap.delete(removePage);

    // // get a set of products we are still using
    // const usedProducts = new Set();
    // this.pageMap.forEach((ids: string[], _) => {
    //   ids.forEach(id => usedProducts.add(id));
    // })

    // // trim products we are not using
    // this.productMap.forEach((_, key: string) => {
    //   if (!usedProducts.has(key)) {
    //     this.productMap.delete(key);
    //   }
    // })

    // // repeat
    // return this.trimStore();
  }
}
