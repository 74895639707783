import { Product } from "../interfaces/product";

export default class ProductSearch {
  
  /**
   * Get a unique set of words that can be searched for
   *
   * @param product get the searchable words from a product
   * @returns words to be stored in indices or searched for
   */
  public static getWordsFromProduct(product: Product): string[] {
    const str = product.title + " " + product.posterName;
    return this.getWords(str);
  }

  /**
   * Get a unique set of words that can be searched for
   *
   * @param str should contain the whole string to get search words for
   *        Ex: "Iphone Xr Jeremy Wiens"
   * @returns words to be stored in indices or searched for
   * NOTE: The entire search should be sent here so that it can be made a unique set
   */
  public static getWords(str: string): string[] {
    // Replace non-alphanumeric characters with space
    let cleanStr = str.replace(/[^0-9a-z]/gi, " ");
    // trim and get rid of capitals
    cleanStr = cleanStr.toLocaleLowerCase().trim();
    // Split at spaces, trim the empty words and return a unique set
    return [...new Set(cleanStr.split(" "))]
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  }
}
