import {
  Component,
  isDevMode,
  OnInit,
  HostListener,
  Input,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { GoogleLocation } from "shared/location";
import { UserService } from "src/app/services/core/user.service";
import { NotificationService } from "src/app/services/notification.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { GlobalConstants } from "src/app/Global";
import { ToastrService } from "ngx-toastr";
import { ScreenSizeService } from "src/app/services/screen-size.service";
import { map, startWith, take } from "rxjs/operators";
import { ContactDialog } from "../contact/contact.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { LocationService } from "src/app/services/core/location.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { DialogService } from "src/app/services/dialog.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  name = GlobalConstants.NAME;
  isLoggedIn: boolean;

  MAX = GlobalConstants.MAX_INPUT;

  MOBILE_THRESHOLD = 890;
  defaultThumb = GlobalConstants.DEFAULT_PROFILE_PICTURE_URL_THUMB;

  isSmallScreen$ = new BehaviorSubject<boolean>(
    this.screenSize.isSmallScreen(this.MOBILE_THRESHOLD)
  );
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isSmallScreen$.next(
      this.screenSize.isSmallScreen(this.MOBILE_THRESHOLD)
    );
  }
  @Input() smallScreen: boolean;

  hidden = false;
  prevScrollPos = window.pageYOffset;
  @HostListener("window:scroll") onScroll(): void {
    const nextScrollPos = window.pageYOffset;
    let scrolledUp: boolean = this.prevScrollPos < nextScrollPos;
    let scrolledDown: boolean = this.prevScrollPos < nextScrollPos - 20;
    let closeToTop: boolean = nextScrollPos < 60;
    if (
      ((this.hidden && scrolledUp) || (!this.hidden && scrolledDown)) &&
      !closeToTop
    ) {
      this.hidden = true;
      this.mobileMenu = false;
      document.getElementById("navbar").style.top = "-64px";
    } else {
      this.hidden = false;
      document.getElementById("navbar").style.top = "0";
    }
    this.prevScrollPos = nextScrollPos;
  }

  notifications = [];
  newNotificationCount = 0;
  newMessagesCount = 0;

  filteredAutocompleteOptions: Observable<GoogleLocation[]>;
  cityInputControl = new FormControl("");
  switchToGoogleAutocomplete = false;
  searchInput: string = "";

  locationSet = true;
  mobileMenu = false;

  subs: Subscription[] = [];

  options = {
    fields: ["place_id", "name"], // Do not change without changing handleAddressChange() below
    types: ["(cities)"],
  };

  constructor(
    public router: Router,
    private db: AngularFirestore,
    public currentUser: UserService,
    private notificationService: NotificationService,
    private screenSize: ScreenSizeService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private locationServ: LocationService,
    public dialogs: DialogService
  ) {}

  ngOnInit() {
    this.setFilteredOptions();

    this.options["sessiontoken"] = this.db.createId();
    this.getNotifications();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.mobileMenu = false;
        if (!val.url.includes("results")) {
          this.searchInput = "";
        }
      }
    });
    this.getUnreadMessages();
  }

  setFilteredOptions() {
    this.locationServ.popularCities$.subscribe((res) => {
      this.filteredAutocompleteOptions = this.cityInputControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterCustomAutoComplete(value))
      );
    });
  }

  _filterCustomAutoComplete(value: string): GoogleLocation[] {
    if (typeof value === "object") return [];
    const filterValue = value.toLowerCase();
    let newFiltered = this.locationServ
      .getSuggestedCities()
      .filter((option) => {
        if (!option) return false;
        return option.name.toLowerCase().indexOf(filterValue) === 0;
      });
    if (
      newFiltered.length == 0 ||
      this.cityInputControl.value.length >= 4 ||
      this.switchToGoogleAutocomplete
    ) {
      this.switchToGoogleAutocomplete = true;
      return [];
    } else {
      return newFiltered;
    }
  }

  searchFor(str: string) {
    if (str.length < 1) return;
    this.router.navigate(["/results"], { queryParams: { search: str } });
    var tmp = document.createElement("input");
    document.body.appendChild(tmp);
    tmp.focus();
    document.body.removeChild(tmp);
  }

  // Asks the auth service is the location has been determined
  hasLocationSet(): boolean {
    return this.currentUser.hasSetLocation();
  }

  locIDIsSet(): boolean {
    return this.locationSet && this.hasLocationSet();
  }

  getUnreadMessages() {
    this.subs.push(
      this.currentUser
        .totalUnreadConversationCount$()
        .subscribe((total) => (this.newMessagesCount = total))
    );
  }

  getConversationBadge() {
    return this.getBadge(this.newMessagesCount);
  }

  getBadge(count: number): string {
    if (count <= 0) return "";
    if (count <= 9) {
      return count.toString();
    } else {
      return "9+";
    }
  }

  openContact() {
    this.dialog.open(ContactDialog, { width: "600px" });
  }

  getNotifications() {
    this.subs.push(
      this.notificationService.notificationsSubject.subscribe((res) => {
        this.notifications = res;
        let unreadCount = 0;
        res.forEach((not) => {
          if (not.new) unreadCount++;
        });
        this.newNotificationCount = unreadCount;
      })
    );
  }

  getNotificationBadge() {
    return this.getBadge(this.newNotificationCount);
  }

  toggleMenuMobile() {
    this.mobileMenu = !this.mobileMenu;
  }

  resetLocation() {
    this.mobileMenu = false;
    this.locationSet = false;
    this.cityInputControl.setValue("");
    this.switchToGoogleAutocomplete = false;
    this.options["sessiontoken"] = this.db.createId();
    setTimeout(() => {
      this.isSmallScreen$.pipe(take(1)).subscribe((res) => {
        if (res) {
          document.getElementById("mobile-location-input").focus();
        } else {
          document.getElementById("location-input").focus();
        }
      });
    }, 1); // Allow Dom to refresh with timeout.
  }

  handleCustomSelect(event: MatAutocompleteSelectedEvent) {
    this.changeLocation(event.option.value.id, event.option.value.name);
  }

  changeLocation(id: string, name: string) {
    if (this.currentUser.getLocation().id != id) {
      this.locationSet = this.currentUser.changeLocation(new GoogleLocation(id, name), true, true, true);
    } else {
      // bugfix: need this to set location when city not changed
      this.locationSet = true;
    }
  }

  handleAddressChange(address: any) {
    this.changeLocation(address.place_id, address.name);
  }

  hasImproperLocation(): boolean {
    return true;
  }

  getDisplayCity() {
    return this.currentUser.getLocationName(true);
  }

  openLogin() {
    this.dialogs.openLogin();
  }

  mobileBid() {
    this.mobileMenu = false;
    this.router.navigate(["products"]);
  }

  goToProfile() {
    this.mobileMenu = false;
    this.router.navigate(["profile"]);
  }

  mobileSell() {
    this.mobileMenu = false;
    this.router.navigate(["sell"]);
  }

  showToastrIfAtProducts() {
    if (this.router.url.includes("products")) {
      this.toaster.info("Please click on a product to start bidding");
    }
    // this.router.navigate(["products"]);
  }

  mobileNotifications() {
    this.mobileMenu = false;
    this.router.navigate(["notifications"]);
  }

  mobilePremium() {
    this.mobileMenu = false;
    this.router.navigate(["premium"]);
  }

  mobileConversations() {
    this.mobileMenu = false;
    this.router.navigate(["messenger"]);
  }

  mobileLogout() {
    this.mobileMenu = false;
    this.currentUser.signOut();
  }

  mobileLogin() {
    this.mobileMenu = false;
    this.dialogs.openLogin();
  }

  openMoreLinks() {
    this.mobileMenu = false;
    this.dialog.open(MoreLinksDialog, { width: "400px" });
  }

  scrollToTop() {
    window.scrollTo({behavior: "smooth", top: 0})
  }
}

@Component({
  selector: "more-links-dialog",
  templateUrl: "more-links-dialog.html",
})
export class MoreLinksDialog {
  constructor(public dialogRef: MatDialogRef<MoreLinksDialog>, private util: UtilService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  goTo(path: string) {
    this.util.dialogNavigate(path, this.dialogRef)
  }
}
