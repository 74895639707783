<svg  viewBox="0 0 200 200" [attr.width]="size" [attr.height]="size" [id]="getId()">
  <filter id="innerShadow" x="-20%" y="-20%" width="140%" height="140%">
    <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
    <feOffset in="blur" dx="2.5" dy="2.5" />
  </filter>

  <g>
    <circle
      id="shadow"
      style="fill: rgba(0, 0, 0, 0.1)"
      cx="97"
      cy="100"
      r="77"
      filter="url(#innerShadow)"
    ></circle>
    <circle
      id="circle"
      style="stroke: #184395; stroke-width: 12px; fill: #fff"
      cx="100"
      cy="100"
      r="70"
    ></circle>
  </g>
  <g class="pri">
    <g transform="rotate(315 100 100)" stroke-linecap="round">
      <line x1="100" y1="30" x2="100" y2="0" class="thin"></line>
      <line x1="100" y1="13" x2="100" y2="0" class="thick"></line>
      <line
        x1="100"
        y1="13"
        x2="100"
        y2="0"
        class="thick acc"
        transform="translate(13 0)"
      ></line>
      <line
        x1="100"
        y1="13"
        x2="100"
        y2="0"
        class="thick acc"
        transform="translate(-13 0)"
      ></line>
    </g>
    <g transform="rotate(45 100 100)" stroke-linecap="round">
      <line x1="100" y1="30" x2="100" y2="0" class="thin"></line>
      <line x1="100" y1="13" x2="100" y2="0" class="thick"></line>
      <line
        x1="100"
        y1="13"
        x2="100"
        y2="0"
        class="thick acc"
        transform="translate(13 0)"
      ></line>
      <line
        x1="100"
        y1="13"
        x2="100"
        y2="0"
        class="thick acc"
        transform="translate(-13 0)"
      ></line>
    </g>

    <g stroke-linecap="round">
      <line
        x1="100"
        y1="30"
        x2="100"
        y2="5"
        transform="rotate(135 100 100)"
        class="thin"
      ></line>
      <line
        x1="100"
        y1="30"
        x2="100"
        y2="5"
        transform="rotate(225 100 100)"
        class="thin"
      ></line>
    </g>
  </g>
  <g class="hidden" [id]="'hand-wrapper-' + this.getId()">
    <line
      x1="100"
      y1="100"
      x2="100"
      y2="62"
      transform="rotate(80 100 100)"
      style="stroke-width: 3px; stroke: #184395"
      [id]="'hourhand-' + this.getId()"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="43200s"
        repeatCount="indefinite"
      />
    </line>
    <line
      x1="100"
      y1="100"
      x2="100"
      y2="45"
      style="stroke-width: 4px; stroke: #184395"
      [id]="'minutehand-' + this.getId()"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="3600s"
        repeatCount="indefinite"
      />
    </line>
    <line
      x1="100"
      y1="100"
      x2="100"
      y2="40"
      style="stroke-width: 2px; stroke: #184395"
      [id]="'secondhand-' + this.getId()"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="60s"
        repeatCount="indefinite"
      />
    </line>
  </g>
  <circle
    id="center"
    style="fill: #fff; stroke: #c1efed; stroke-width: 2px"
    cx="100"
    cy="100"
    r="3"
  ></circle>
</svg>
