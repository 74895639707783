import { SharedConstants } from "./constants";
import { AllRoutes } from "./interfaces/route";

const name = SharedConstants.NAME;

export const allRoutes: AllRoutes = {
  home: {
    title: name,
    route: "home",
  },
  products: {
    blockSetTitle: true,
    route: "products",
  },
  results: {
    blockSetTitle: true,
    route: "results",
  },
  contact: {
    title: "Contact " + name,
    route: "contact-form",
  },
  terms: {
    title: "Terms & Conditions - " + name,
    route: "terms-and-conditions",
  },
  privacy: {
    title: "Privacy Policy - " + name,
    route: "privacy-policy",
  },
  guidelines: {
    title: name + " Guidelines",
    route: "guidelines",
  },
  sell: {
    title: "Sell something on " + name,
    route: "sell",
  },
  edit: {
    title: "Edit - " + name,
    route: "edit",
  },
  profile: {
    title: "Profile - " + name,
    route: "profile",
  },
  messenger: {
    title: "Messenger - " + name,
    route: "messenger",
  },
  notifications: {
    title: "Notifications - " + name,
    route: "notifications",
  },
  premium: {
    title: "Premium - " + name,
    route: "premium",
  },
};
