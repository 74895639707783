import { ConversationFirestore } from "shared/interfaces/conversation";

export class Conversation {
    public id: string; //unique id for this conversation
    public productId:string; //Unique Id of product, what this conversation is about. 
    public partnerId:string; //Unique user id of the other person in the conversation.
    public locationId:string //id of city this conversation resides in
    
    //In NoSQL Fasion duplicate relevant data for fast lookup. 
    public productImageBaseURL:string;
    public productImageName:string; 
    public productTitle:string;
    public partnerName:string;
    public seller: boolean; //Is current user the seller or bidder?
    public participants:string[]; //list of user ids
    
    //Data about state of conversation. 
    public unReadMessages:number;
    public partnerUnReadMessages:number; 
    public lastMessageSent:any; //Time stamp of last message sent, or other field in the document got updated.

    constructor(data:ConversationFirestore, currentUserID){
      this.seller = (currentUserID == data.sellerId); 
      this.id = data.id;
      this.productId = data.productId;
      this.partnerId = this.seller ? data.bidderId : data.sellerId; 
      this.locationId = data.locationId;
    
      this.participants = data.participants; 
      this.productImageBaseURL = data.productImageBaseURL;
      this.productImageName = data.productImageName; 
      this.productTitle = data.productTitle; 
      this.partnerName = this.seller ? data.bidderName : data.sellerName;

      this.unReadMessages = this.seller ? data.sellerUnReadMessages : data.bidderUnReadMessages;  
      this.partnerUnReadMessages = this.seller ? data.bidderUnReadMessages : data.sellerUnReadMessages;  
      this.lastMessageSent = data.lastMessageSent; 
    }
}