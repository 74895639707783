import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Product } from 'shared/interfaces/product';

import * as postscribe from 'postscribe';
import { AdService } from 'src/app/services/ad.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/core/user.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'display-products',
  templateUrl: './display-products.component.html',
  styleUrls: ['./display-products.component.scss']
})

// Component to display actually input set of products
export class DisplayProductsComponent implements OnInit, OnDestroy {

  @Input() initialProducts: Product[];
  @Input() laterProducts: Product[];
  @Input() showAds: boolean = false;
  @Input() set amznSearchTerm(term: string) {
      this._amznSearchTerm = term;
      setTimeout(this.initializeAmznAdBar);
  }
  _amznSearchTerm: string;
  @Input() amznTitle: string = "Popular Amazon Products";

  adsAreBlocked = false;
  adsAreShown = false;

  subscriptions: Subscription[] = [];

  defaultSearches = ["bluetooth speaker", "earbuds", "mouse", "chair", "bottle", "apple", "toaster", "camping"]

  constructor(private currentUser: UserService, private adService: AdService, public dialogs:DialogService) { }

  ngOnInit() {
    this.subscriptions.push(this.adService.adsAreBlocked.subscribe(res => this.adsAreBlocked = res));
  }

  setAdsToVisible() {
    if (this.showAds) {
      document.getElementById('amzn-ad-bar').style.visibility = "visible";
      this.initializeAmznAdBar();
    }
  }

  // Remove products that are undefined. This ideally should not be necessary.
  // This can happen for example if a product is retreived by id but no longer 
  // exists
  cleanProducts(products: Product[]) {
    if (products) {
      return products.filter(prod => prod)
    } else {
      return []
    }
  }

  showAdBlockMessage() {
    this.adService.showModal();
  }

  initializeAmznAdBar() {
    if (this.showAds && !this.adsAreShown) {
      this.adsAreShown = true;
      const searchTerm = (this._amznSearchTerm == undefined) ? this.defaultSearches[Math.floor(Math.random() * this.defaultSearches.length)] : this._amznSearchTerm;
      const location = (this.currentUser.getLocationName(true).includes("Canada")) ? "CA" : "US"
      postscribe('#amzn-ad-bar', this.adService.getScript(location, this.amznTitle, searchTerm));
    };
  }

  ngOnDestroy() {
    const adbar = document.getElementById('amzn-ad-bar');
    if (adbar) adbar.remove();

    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
