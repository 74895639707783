<div class="container">
    <div class='explain-text'>{{message}}</div>
    <div class="link-container">
        <input id="link-holder" [value]="createLink()" [readOnly]='true'>
        <!-- Should be (copied)="linkCopied()" but this broke in angular 12 -->
        <button [cdkCopyToClipboard]="createLink()" (click)="linkCopied()" class="icon-container"
            [attr.cdkFocusInitial]="focusInitial? ' ' : null">
            <mat-icon [inline]="true"> content_copy </mat-icon>
        </button>
    </div>
</div>