import { Component, OnInit } from '@angular/core';
import { AdService } from 'src/app/services/ad.service';
import { UserService } from 'src/app/services/core/user.service';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'adblock',
  templateUrl: './adblock.component.html',
  styleUrls: ['./adblock.component.scss']
})
export class AdblockComponent implements OnInit {

  dialogRef;

  constructor(private adService: AdService, private user: UserService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.adService.showAdModal.subscribe(res => res ? this.openDialog() : this.closeDialog());
    this.adService.adsAreBlocked.subscribe(res => {
      if (res && this.user.emailVerified() && 5 == Math.floor(Math.random() * Math.floor(999))) // one in a hundred chance of asking to unblock ads
      this.openDialog();
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AdblockDialog, {
      width: '450px'
    });
    this.dialogRef = dialogRef;
  }

  closeDialog(): void {
    if (this.dialogRef) this.dialogRef.close();
  }
}

@Component({
  selector: 'adblock-dialog',
  templateUrl: 'adblock-dialog.html',
})
export class AdblockDialog {

  constructor(
    public dialogRef: MatDialogRef<AdblockDialog>, private utils: UtilService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  goPremium() {
    this.utils.dialogNavigate("/premium", this.dialogRef);
  }

}