import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from './services/core/user.service';
import { NotificationError } from 'src/app/error-handler/customErrors';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { SharedConstants } from 'shared/constants';
import { GoogleLocation } from 'shared/location';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { GlobalConstants } from './Global';
import { MatIconRegistry } from '@angular/material/icon';
import { RouteData } from 'shared/interfaces/route';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(public currentUser: UserService, private route: ActivatedRoute, private metaTags: Meta,
    private domSanitzer: DomSanitizer, private matIconRegistry: MatIconRegistry, private router: Router,
    private title: Title) { }

  openVerifyModal:boolean = false; 
  sub:Subscription;
  initialized = false;
  loadingUser = false;
  hasLocation = false;
  showVerifyAction = true; 
  loadingNextRoute: boolean = false;

  ngOnInit() {
    this.currentUser.userIsInitialized$().subscribe(res => this.initialized = res);
    this.currentUser.firestoreIsInitialized$().subscribe(res => this.loadingUser = !res);
    this.currentUser.setLocation$.subscribe(res => this.hasLocation = res);

    // Allow cities to be directly accessed with queryParams
    this.route.queryParams.subscribe(params => {
      const location: string = params['location'];
      if (location && location.length > 1) {
        const name = location.split(SharedConstants.LOCATION_ID_SPLITTER)[0];
        const id = location.split(SharedConstants.LOCATION_ID_SPLITTER)[1];

        this.metaTags.updateTag({name: GlobalConstants.DESCRIPTION, content: this.getDescription("in " + name) });
        this.currentUser.changeLocation(
          new GoogleLocation(id, name),
          true,
          true
        );
      } else {
        this.metaTags.updateTag({name: GlobalConstants.DESCRIPTION, content: this.getDescription("in your city") });
      }
      const referrer = params['refer'];
      if (referrer && referrer.length > 1)  this.currentUser.refer_id = referrer;
    });
  }

  ngAfterViewInit() {
    this.sub = this.currentUser.verificationToggle$.subscribe(open => this.openVerifyModal = open);

    this.matIconRegistry.addSvgIcon(
      "Google",
      this.domSanitzer.bypassSecurityTrustResourceUrl("assets/Google.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "Facebook",
      this.domSanitzer.bypassSecurityTrustResourceUrl("assets/Facebook.svg")
    );

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('firebase-messaging-sw.js')
      .catch(function(err) {
        throw new NotificationError("Service worker registration failed: " + err, 1003)
      });
    }

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.loadingNextRoute = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingNextRoute = false;
      } else if (event instanceof NavigationEnd) {
        this.setPageTitle();
        this.cleanUpAds();
      }
  });
  }

  // When the user navigates away before the amazon ad is loaded with postscribe
  // the ad will just be placed on the body. This is annoying
  // and this is the hacky fix for now.
  cleanUpAds() {
    Array.from(document.getElementsByTagName("BODY")[0].children)
      .filter(el => el.id.startsWith("amzn_assoc_ad"))
      .forEach(el => el.remove())
  }

  setPageTitle() {
    let title = "Daily Bids";
    let child = this.route.firstChild;
    while (child) {
      const data = child.snapshot.data as RouteData;
      if (data && data.blockSetTitle) return;
      if (data && data.title) {
        title = data.title;
      }
      if (child.firstChild) {
        child = child.firstChild;
      } else {
        child = null;
      }
    }

    this.title.setTitle(title);
  }

  getDescription(locationString: string) {
    return "Buy and sell used items " + 
    locationString + 
    " through bidding. Engage in bidding wars to buy used items at a great prices. Sell items in 24 hours and for the best price."
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  cancelVerify(){
    this.currentUser.closeVerification(); 
  }

}
