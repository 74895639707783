<h1 style="margin-bottom: 0" mat-dialog-title>Sign in to Daily Bids</h1>
<div mat-dialog-content>
  <div *ngIf="this.loginMessage; else defaultMessage">
    {{ this.loginMessage }}
  </div>
  <ng-template #defaultMessage>
    <!-- Please login or sign up to post and bid on Daily Bids -->
  </ng-template>

  <social-auth-list (googlePress)="googlePress()" [showFacebook]="false"></social-auth-list>

  <div class="social-or"><span>or</span></div>
  <div style="margin-bottom: 10px">
    New user?
    <button
      style="margin: 0 5px"
      mat-raised-button
      color="primary"
      (click)="toggleSignin()"
    >
      Register
    </button>
  </div>
  <p *ngIf="this._error.authMessage$ | async" [ngStyle]="{ color: 'red' }">
    {{ this._error.authMessage$ | async }}
  </p>
  <form [formGroup]="this.loginForm" (keyup.enter)="loginEmailPassword()">
    <p style="margin: 25px 0 4px">
      <mat-form-field class="input-width">
        <input
          formControlName="logEmail"
          matInput
          placeholder="Email"
          name="email"
          type="email"
        />
        <mat-error *ngIf="loginEmail.errors?.required"
          >Email is required</mat-error
        >
        <mat-error *ngIf="loginEmail.errors?.email">Invalid email</mat-error>
      </mat-form-field>
    </p>
    <p style="margin: 0">
      <mat-form-field class="input-width">
        <input
          formControlName="logPassword"
          placeholder="Password"
          type="password"
          matInput
          appPassword
        />
        <mat-error *ngIf="loginPassword.errors?.minlength"
          >It must be at least 6 characters!</mat-error
        >
        <mat-error *ngIf="loginPassword.errors?.required"
          >Password is required</mat-error
        >
      </mat-form-field>
    </p>
  </form>
    <div style="margin: 5px 0 20px">
      <button
        type="button"
        mat-button
        color="primary"
        (click)="submitForgotPassword()"
      >
        Forgot your password?
      </button>
      <div *ngIf="showPasswordResetInput">
        <form [formGroup]="this.resetForm" style="display: inline" (submit)="forgotPassword()">
          <mat-form-field>
            <input
              id="reset-form-input"
              matInput
              type="email"
              placeholder="Email"
              formControlName="resetEmail"
            />
            <mat-hint>Please enter your email to reset your password</mat-hint>
            <mat-error *ngIf="resetEmail.errors?.email"
              >Invalid email</mat-error
            >
          </mat-form-field>
        </form>
        <button
          [disabled]="!this.resetForm.valid"
          style="margin: 20px 10px; display: inline"
          type="button"
          (click)="forgotPassword()"
          mat-raised-button
        >
          send
          <mat-icon [inline]="true">email</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <p>
        By continuing, you agree to Daily Bid's
        <a [routerLink]="" (click)="goTo('/terms-and-conditions')">
          Terms & Conditions</a
        >
        and
        <a [routerLink]="" (click)="goTo('/privacy-policy')"
          >Privacy Policy</a
        >.
      </p>
    </div>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="closeModal()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="loginEmailPassword()"
    cdkFocusInitial
  >
    Login
  </button>
</mat-dialog-actions>
