<h1 style="margin-bottom: 0" mat-dialog-title>Register to Daily Bids</h1>
<div mat-dialog-content>
  <div *ngIf="this.loginMessage; else defaultMessage">
    {{ this.loginMessage }}
  </div>
  <ng-template #defaultMessage>
    <!-- Please login or sign up to post and bid on Daily Bids -->
  </ng-template>

  <social-auth-list (googlePress)="googlePress()" [showFacebook]="false"></social-auth-list>

  <div class="social-or"><span>or</span></div>
  <div style="margin-bottom: 10px">
    Returning user?
    <button
      style="margin: 0 5px"
      mat-raised-button
      color="primary"
      (click)="toggleSignin()"
    >
      Login
    </button>
  </div>
  <p *ngIf="this._error.authMessage$ | async" [ngStyle]="{ color: 'red' }">
    {{ this._error.authMessage$ | async }}
  </p>
  <form [formGroup]="this.registerForm" (keyup.enter)="registerEmailPassword()">
    <mat-form-field [hideRequiredMarker]="true" class="input-width">
      <input
        matInput
        formControlName="displayFirstName"
        placeholder="First Name"
        required
        type="text"
        [maxLength]="MAX_NAME"
      />
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field [hideRequiredMarker]="true" class="input-width">
      <input
        matInput
        formControlName="displayLastName"
        placeholder="Last Name"
        required
        type="text"
        [maxLength]="MAX_NAME"
      />
      <mat-error>Required</mat-error>
    </mat-form-field>
    <mat-form-field class="input-width">
      <input
        formControlName="regEmail"
        matInput
        placeholder="Email"
        name="email"
        type="email"
      />
      <mat-error *ngIf="registerEmail.errors?.required"
        >Email is required</mat-error
      >
      <mat-error *ngIf="registerEmail.errors?.email">Invalid email</mat-error>
    </mat-form-field>
    <div>
      <mat-form-field class="input-width">
        <input
          matInput
          formControlName="regPassword"
          placeholder="Password"
          name="regPassword"
          type="password"
          appPassword
        />
        <mat-hint>It must be at least 6 characters!</mat-hint>
        <mat-error *ngIf="registerPassword.errors?.required"
          >This field is required!</mat-error
        >
        <mat-error *ngIf="registerPassword.errors?.minlength"
          >It must be at least 6 characters!</mat-error
        >
      </mat-form-field>
      <mat-form-field [hideRequiredMarker]="true" class="input-width">
        <input
          matInput
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          name="confirm password"
          type="password"
          appPassword
          required
        />
        <mat-error *ngIf="confirmPassword.errors?.required"
          >This field is required!</mat-error
        >
        <mat-error *ngIf="confirmPassword.errors?.mismatch"
          >Passwords don't match!</mat-error
        >
      </mat-form-field>
    </div>
    <br/>
    <mat-form-field [hideRequiredMarker]="true" style="width:100%" *ngIf="this.currentUser.refer_id">
      <input
        matInput
        placeholder="Referral Code"
        formControlName="referralCode"
        required
        readonly=true
        type="text"
        [maxLength]="MAX_NAME"
      />
    </mat-form-field>
    <div style="margin-top: 15px;">
      <p>
        By continuing, you agree to Daily Bid's
        <a [routerLink]="" (click)="goTo('/terms-and-conditions')">
          Terms & Conditions</a
        >
        and
        <a [routerLink]="" (click)="goTo('/privacy-policy')"
          >Privacy Policy</a
        >.
      </p>
    </div>
  </form>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="closeModal()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="registerEmailPassword()"
    cdkFocusInitial
  >
    Register
  </button>
</mat-dialog-actions>
